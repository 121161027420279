import styled from 'styled-components';

import { Typography } from '../Typography';

import { useTheme } from '@/services/theme';

const Container = styled.section`
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
`;

const Value = styled(Typography)`
  color: ${({ textColor }: { textColor: string }) => textColor};
  font-size: ${({ isInfinite }: { isInfinite: boolean }) => (isInfinite ? '2.4rem' : '1.6rem')};
`;

export interface TrendValueProps {
  className?: string;
  type: 'positive' | 'negative' | 'neutral';
  value: string;
}

const TrendValue = ({ className, type, value }: TrendValueProps) => {
  const { theme } = useTheme();

  const color = (() => {
    switch (type) {
      case 'positive':
        return theme.colors.green1;
      case 'negative':
        return theme.colors.red1;
      default:
        return theme.colors.text.primary;
    }
  })();

  return (
    <Container className={className} theme={theme}>
      <Value
        textSize="md"
        weight={600}
        component="div"
        textColor={color}
        isInfinite={value === '∞'}
      >
        {value}
      </Value>
    </Container>
  );
};

export { TrendValue };
