import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';
import { useTheme, Theme } from '@/services/theme';
import { PageContainer } from '@/shared/components/PageContainer';
import { Typography } from '@/shared/components/Typography';
import { ReactComponent as Polygon } from '@/shared/assets/polygon.svg';
import { NFT_VAULTS_NETWORK_NAME } from '@/shared/utils/env';

type Props = {
  className?: string;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
  border-radius: 2.4rem;
  background: ${({ theme }: { theme: Theme }) => theme.colors.white};
  width: 100%;
  padding: 5rem 0;

  @media (min-width: ${breakpoints.tabletSM}px) {
    height: 30rem;
  }
  @media (min-width: ${breakpoints.laptopSM}px) {
    height: 63.6rem;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8;
`;

const WrongNetwork: React.VFC<Props> = ({ className = '' }) => {
  const { theme } = useTheme();

  return (
    <PageContainer>
      <StyledContainer theme={theme} className={className}>
        <Polygon width="13.2rem" height="8.5rem" />
        <Description>
          <Typography textSize="lg" weight={600} color="primary">
            Wrong network
          </Typography>
          <Typography textSize="md">
            Please change your network to {NFT_VAULTS_NETWORK_NAME}.
          </Typography>
        </Description>
      </StyledContainer>
    </PageContainer>
  );
};

export { WrongNetwork };
