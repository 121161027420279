import { format, parseISO } from 'date-fns';
import { BigNumber, constants } from 'ethers';
import { formatUnits, isAddress } from 'ethers/lib/utils';
import { repeat } from 'ramda';

export function zeroWithPrecision(precision: number): string {
  return `0.${repeat('0', precision).join('')}`;
}

export function discardFractionTo(number: string, count: number = 2): string {
  return number
    .toString()
    .replace(
      new RegExp(`(?<int>\\d+?)\\.(?<fract>\\d{${count}})\\d+?(?<degree>e.\\d+?)?$`),
      '$<int>.$<fract>$<degree>',
    );
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const toFixedNumber = (val: number, precision: number): number => {
  const factor = 10 ** precision;
  return Math.floor(val * factor) / factor;
};

export const toFixedPretty = (val: number, precision: number): string =>
  toFixedNumber(val, precision).toLocaleString('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

export const toFixed = (val: number, precision: number): string =>
  toFixedNumber(val, precision).toFixed(precision);

export const addNumberSuffix = (value: number, suffix: string, decimals: number = 1) =>
  value > 100 ? String(Math.round(value)) + suffix : value.toFixed(decimals) + suffix;

export const toExponential = (value: number) => {
  const valueString = value.toFixed(0);
  const isExpString = /^\d\.\d+/.test(valueString);
  if (isExpString) {
    return valueString.replace(/^(\d)(\.)(\d{0,3})\d+?e\+(\d+)$/, '$1.$3E+$4');
  }
  const pow = Math.floor((valueString.length - 1) / 3) * 3;
  return valueString.replace(/^(\d)(\d{3})(.+)$/, `$1.$2E+${pow}`);
};

export const formatNumber = (value: number): string => {
  if (!Number.isFinite(value)) return 'unlimited';
  if (value > 1e15) return toExponential(value);
  if (value > 1e12) return addNumberSuffix(value / 1e9, 'Q');
  if (value > 1e9) return addNumberSuffix(value / 1e9, 'B');
  if (value > 1e6) return addNumberSuffix(value / 1e6, 'M');
  if (value > 1e3) return addNumberSuffix(value / 1e3, 'K');
  return addNumberSuffix(value, '', 2);
};

export const pad = (num: number, size: number) => {
  let nums = num.toString();
  while (nums.length < size) nums = `0${nums}`;
  return nums;
};

export const localizeNumber = (
  n: number | undefined,
  replacer: string,
  maximumFractionDigits: number = 1,
) =>
  n
    ? n.toLocaleString('en-US', {
        maximumFractionDigits,
      })
    : replacer;

export const formatDate = (dateString: string) => {
  const date = parseISO(dateString);
  return format(date, "d MMM yyyy 'at' H:m 'UTC'");
};

export const shortenString = (value: string, startLength: number = 4, endLength: number = 4) =>
  `${value.slice(0, startLength)}...${value.slice(-endLength)}`;

export const shortenAddress = (address: unknown, startLength: number = 4, endLength: number = 4) =>
  typeof address === 'string' && isAddress(address)
    ? shortenString(address, startLength, endLength)
    : '';

export const formatWithLength = (value: BigNumber, decimals: number, maxLength: number = 5) => {
  const n = +formatUnits(value, decimals);

  const integralPart = Math.round(n);
  const integralLength = String(integralPart).length;

  if (integralLength >= maxLength) return integralPart.toLocaleString();

  const precision = maxLength - integralLength;

  const fractionalPart = n % 1;
  const fractionalWithPrecision = +fractionalPart.toFixed(precision);

  return (integralPart + fractionalWithPrecision).toLocaleString('en-US', {
    maximumFractionDigits: precision,
  });
};

export const clampBn = (value: BigNumber, min: BigNumber = constants.Zero, max?: BigNumber) => {
  if (value.lt(min)) {
    return min;
  }

  if (max && value.gt(max)) {
    return max;
  }

  return value;
};

export const formatToFirstNonZero = (number: number, additionalPrecission?: number) => {
  if (number === 0) return '0';
  const precission =
    number < 1
      ? Number(number.toExponential().split('').pop()) + (additionalPrecission || 0)
      : additionalPrecission || 0;
  return number
    .toFixed(precission || 0)
    .replace(/\.(0)+$/, '')
    .replace(/(?<=\..?)0+$/, '');
};
