import type { Period } from './types';

export const BASE_URL = 'https://api.mellow.finance';

export const SECONDS: { [P in Period | 'hour']: number } = {
  hour: 3600,
  day: 86400,
  week: 604800,
  month: 2592000, // 30 days
  year: 31536000,
};

export const APP_LAUNCH_DATE = 1651784400; // 2022-05-06 00:00:00

export const USD_DECIMALS = 6;
