import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Typography } from '@/shared/components/Typography';

type Wallet = {
  name: string;
  displayedName: string;
};

type Props = {
  wallet: Wallet;
};

const StyledItem = styled.button`
  align-items: center;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.naviBlue5};
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
  border-radius: 1.6rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 1.4rem 2.4rem;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.mobileMD}px) {
    flex-flow: row nowrap;
  }

  &:focus,
  &:hover {
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.violet3};
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
  }
`;

const StyledIcon = styled(SpriteIcon)`
  margin-left: auto;
`;

const WalletConnectorItem: React.VFC<Props> = ({ wallet: { name, displayedName } }) => {
  const { theme } = useTheme();

  return (
    <StyledItem theme={theme}>
      <Typography size={1.6} lineHeight="2.4rem" color="primary" component="span">
        {displayedName}
      </Typography>
      <StyledIcon icon={name} size={3.2} />
    </StyledItem>
  );
};

export { WalletConnectorItem };

export type { Wallet };
