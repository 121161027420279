import styled from 'styled-components';

import { Logo } from '../Logo';

import { useTheme, Theme } from '@/services/theme';
import { Network } from '@/shared/components/Network';
import { WalletMenu } from '@/features/walletConnect';

const StyledHeader = styled.header`
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin: 0 auto;
  padding: 2.2rem 4rem;
`;

const StyledLogoContainer = styled.div`
  flex: 1 1 0;
`;

const StyledNetwork = styled(Network)`
  flex: 0 1 0;
  margin-left: auto;
`;

const Header: React.VFC = () => {
  const { theme } = useTheme();

  return (
    <StyledHeader theme={theme}>
      <StyledContainer>
        <StyledLogoContainer>
          <Logo />
        </StyledLogoContainer>
        <StyledNetwork />
        <WalletMenu />
      </StyledContainer>
    </StyledHeader>
  );
};

export { Header };
