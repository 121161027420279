import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { wallets } from './fixtures';

import {
  Status,
  Wallet,
  WalletAgreement,
  WalletConnectorItem,
  WalletConnectorStatus,
} from '@/features/walletConnect';
import { useModal } from '@/services/modal';
import { useTheme, Theme } from '@/services/theme';
import { useEthers } from '@/services/web3';
import { useAppConfig } from '@/core/AppConfig';
import * as W3M from '@/services/wallet';
import { checkSignature } from '@/api';
import { breakpoints } from '@/shared/styles';
import { Typography } from '@/shared/components/Typography';
import { IconButton } from '@/shared/components/IconButton';

type Props = unknown;

const StyledContainer = styled.div`
  background: ${({ theme }: { theme: Theme }) => theme.colors.background.card};
  border-radius: 2.4rem;
  padding: 2rem 2.4rem 3.2rem;
  position: relative;

  width: calc(100vw - 3.2rem);
  @media (min-width: ${breakpoints.mobileLG}px) {
    width: 80vw;
  }
  @media (min-width: ${breakpoints.tabletSM}px) {
    width: 51.2rem;
  }
`;

const StyledTitleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 3.6rem;
  grid-template-rows: 3.6rem;
  grid-template-areas: 'title close';
`;

const StyledTitle = styled(Typography)`
  flex-grow: 1;
  text-align: center;
`;

const StyledButtonBack = styled(IconButton)`
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue2};
`;

const StyledButtonClose = styled(IconButton)`
  grid-area: close;
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue2};
`;

const StyledContent = styled.div`
  display: grid;
  grid-template: 'content' / 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2.8rem;
  height: auto;
  position: relative;
`;

const StyledGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem;
  top: 0;
`;

const StyledGridItem = styled.div`
  width: calc(50% - 0.8rem);
`;

const StyledFade = styled.div`
  opacity: 1;
  transition: all 250ms;

  &.enter,
  &.enter-active,
  &.exit,
  &.exit-active {
    opacity: 0;
  }
`;

const StyledSlideFade = styled(StyledFade)`
  grid-area: title;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;

  &.enter {
    margin-left: ${({ to }: { to: 'left' | 'right' }) => (to === 'left' ? '-2rem' : '2rem')};
  }
  &.exit {
    margin-left: ${({ to }: { to: 'left' | 'right' }) => (to === 'left' ? '-2rem' : '2rem')};
  }
`;

const StyledFadeContent = styled(StyledFade)`
  grid-area: content;
`;

const transitionTimeouts = { enter: 0, exit: 250 };

const WalletConnector: React.FC<Props> = () => {
  const { theme } = useTheme();
  const { setActiveModal } = useModal();
  const { updateAppConfig } = useAppConfig();
  const { isConnected, userAddress, setWallet, disconnectWallet } = useEthers();

  const [displayedWallet, setDisplayedWallet] = useState<Wallet | null>(null);
  type WindowStage = 'list' | 'wallet' | 'agreement';
  const [windowStage, setWindowStage] = useState<WindowStage>('list');
  const [connStatus, setConnStatus] = useState<Status>('not connected');

  const animation1Ref = useRef<HTMLElement>(null);
  const animation2Ref = useRef<HTMLElement>(null);
  const animation3Ref = useRef<HTMLElement>(null);
  const animation4Ref = useRef<HTMLElement>(null);
  const animation5Ref = useRef<HTMLElement>(null);
  const animation6Ref = useRef<HTMLElement>(null);

  const showAgreement = async () => {
    // const isSigned = await checkSignature(userAddress!);
    // if (!isSigned) setWindowStage('agreement');
    // else setActiveModal('');
    setActiveModal('');
  };

  const connect = async (wallet: string) => {
    setConnStatus('pending');

    try {
      const provider = await W3M.connect(wallet);
      updateAppConfig({ cachedProvider: W3M.getCachedProvider() });
      await setWallet(provider);
    } catch (error) {
      setConnStatus('error');
    }
  };

  const onWalletClick = async (wallet: Wallet) => {
    setDisplayedWallet(wallet);
    setWindowStage('wallet');

    if (isConnected) {
      setConnStatus('connected');
      showAgreement();
    } else {
      await connect(wallet.name);
    }
  };

  const onAgreementAccept = () => {
    setActiveModal('');
  };

  const onAgreementReject = () => {
    disconnectWallet();
  };

  const onCloseClick = () => {
    setActiveModal('');
  };

  const onBackClick = () => {
    setWindowStage('list');
    // Animation delay
    setTimeout(() => setDisplayedWallet(null), 250);
  };

  useEffect(() => {
    if (isConnected) {
      setConnStatus('connected');
      showAgreement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return (
    <StyledContainer theme={theme}>
      <StyledTitleWrap>
        <CSSTransition
          in={windowStage === 'list'}
          timeout={transitionTimeouts}
          nodeRef={animation1Ref}
          mountOnEnter
          unmountOnExit
        >
          <StyledSlideFade to="left" ref={animation1Ref}>
            <StyledTitle textSize="lg" weight={600} color="primary">
              Connect wallet
            </StyledTitle>
          </StyledSlideFade>
        </CSSTransition>

        <CSSTransition
          in={windowStage === 'wallet'}
          timeout={transitionTimeouts}
          nodeRef={animation2Ref}
          mountOnEnter
          unmountOnExit
        >
          <StyledSlideFade to="right" ref={animation2Ref}>
            <StyledButtonBack
              icon="arrow-left"
              aria-label="Back"
              title="Back"
              theme={theme}
              size={3.6}
              iconSize={2.4}
              onClick={onBackClick}
            />
            <StyledTitle textSize="lg" weight={600} color="primary">
              {displayedWallet?.displayedName}
            </StyledTitle>
          </StyledSlideFade>
        </CSSTransition>

        <CSSTransition
          in={windowStage === 'agreement'}
          timeout={transitionTimeouts}
          nodeRef={animation6Ref}
          mountOnEnter
          unmountOnExit
        >
          <StyledSlideFade to="right" ref={animation6Ref}>
            <StyledTitle textSize="lg" weight={600} color="primary">
              One moment...
            </StyledTitle>
          </StyledSlideFade>
        </CSSTransition>

        <StyledButtonClose
          icon="x-cross"
          title="Close"
          theme={theme}
          size={3.6}
          iconSize={2.4}
          onClick={onCloseClick}
        />
      </StyledTitleWrap>

      <StyledContent>
        <CSSTransition
          in={windowStage === 'list'}
          timeout={transitionTimeouts}
          nodeRef={animation3Ref}
          mountOnEnter
          unmountOnExit
        >
          <StyledFadeContent ref={animation3Ref}>
            <StyledGrid>
              {wallets.map(wallet => (
                <StyledGridItem
                  key={wallet.name}
                  theme={theme}
                  onClick={() => onWalletClick(wallet)}
                >
                  <WalletConnectorItem wallet={wallet} />
                </StyledGridItem>
              ))}
            </StyledGrid>
          </StyledFadeContent>
        </CSSTransition>

        <CSSTransition
          in={windowStage === 'wallet'}
          timeout={transitionTimeouts}
          nodeRef={animation4Ref}
          mountOnEnter
          unmountOnExit
        >
          <StyledFadeContent ref={animation4Ref}>
            <WalletConnectorStatus
              onRetryClick={() => connect(displayedWallet?.name || 'metamask')}
              status={connStatus}
              wallet={displayedWallet?.name || ''}
            />
          </StyledFadeContent>
        </CSSTransition>

        <CSSTransition
          in={windowStage === 'agreement'}
          timeout={transitionTimeouts}
          nodeRef={animation5Ref}
          mountOnEnter
          unmountOnExit
        >
          <StyledFadeContent ref={animation5Ref}>
            <WalletAgreement onAccept={onAgreementAccept} onReject={onAgreementReject} />
          </StyledFadeContent>
        </CSSTransition>
      </StyledContent>
    </StyledContainer>
  );
};

export { WalletConnector };
