import { BigNumber, constants, utils } from 'ethers';
import { last } from 'ramda';

import { TrendValueProps } from '../components/TrendValue';

import { DENOMINATOR } from '@/shared/utils/consts';
import {
  NftVault,
  NftVaultItem,
  ProgressCondition,
  ContractsMap,
  ProtocolParams,
} from '@/shared/types';
import { clampBn } from '@/shared/utils/format';

const MAX_DISPLAYED_HEALTH_FACTOR = 10 ** 6;

export async function fillInVault(
  contract: ContractsMap['NftVault'],
  id: string,
  nfts: Array<NftVaultItem>,
  protocolParams: ProtocolParams,
): Promise<NftVault> {
  const getVaultLastLiquidationEvent = async () => {
    const vaultLiquidatedFilter = contract.filters.VaultLiquidated(null, BigNumber.from(id));
    const collateralDepositedFilter = contract.filters.CollateralDeposited(
      null,
      BigNumber.from(id),
    );

    const liquidatedEvents = await contract.queryFilter(vaultLiquidatedFilter);
    const lastLiquidationEvent = last(liquidatedEvents);

    if (!lastLiquidationEvent) return undefined;

    const depositedEventsAfterLiquidation = await contract.queryFilter(
      collateralDepositedFilter,
      lastLiquidationEvent.blockNumber,
    );

    if (depositedEventsAfterLiquidation.length) return undefined;

    return lastLiquidationEvent;
  };

  const [minted, overallDebt, { borrowLimit, liquidationLimit }, unclaimed, liquidationEvent] =
    await Promise.all([
      contract.vaultMintedDebt(id),
      contract.getOverallDebt(id),
      contract.calculateVaultCollateral(id),
      contract.vaultOwed(id),
      getVaultLastLiquidationEvent().catch(e => {
        console.error(`Failed to query vault liquidation logs: ${e.message}`);
        return undefined;
      }),
    ]);

  const liquidationTransaction = liquidationEvent?.transactionHash;
  const hasNfts = Boolean(nfts.length);
  const healthFactor =
    (hasNfts &&
      (overallDebt.isZero()
        ? Infinity
        : liquidationLimit.mul(constants.WeiPerEther).div(overallDebt))) ||
    undefined;

  return {
    id,
    state: {
      minted,
      overallDebt,
      borrowLimit: clampBn(borrowLimit, constants.Zero, protocolParams.maxDebtPerVault),
      liquidationLimit,
      healthFactor,
      collateral: nfts.reduce((result, item) => result.add(item.collateral), BigNumber.from(0)),
    },
    pairs: nfts.map(({ pair }) => pair.map(({ symbol }) => symbol)) as Array<[string, string]>,
    wasLiquidated: Boolean(liquidationEvent),
    liquidationTransaction,
    unclaimed,
  };
}

export function calculateNewHealthFactor(nfts: Array<NftVaultItem>, debt: BigNumber) {
  if (debt.isZero()) {
    return Infinity;
  }

  return nfts
    .map(nft => nft.collateral.mul(nft.liquidationThreshold).div(DENOMINATOR))
    .reduce((result, item) => result.add(item), constants.Zero)
    .mul(constants.WeiPerEther)
    .div(debt);
}

export function getHealthFactorNumber(healthFactor: BigNumber) {
  if (healthFactor.gt(constants.WeiPerEther.mul(MAX_DISPLAYED_HEALTH_FACTOR))) {
    return Infinity;
  }

  return Number(utils.formatUnits(healthFactor)) * 100;
}

export function getHealthFactorString(healthFactor: BigNumber | number) {
  const healthFactorN =
    typeof healthFactor === 'number'
      ? healthFactor
      : Math.round(getHealthFactorNumber(healthFactor));
  return healthFactorN === Infinity ? '∞' : `${Math.round(healthFactorN)}%`;
}

export function getNewHealthFactor(
  currentBn: number | BigNumber | undefined,
  nextBn: number | BigNumber,
): { trend: TrendValueProps['type']; str: TrendValueProps['value']; valueN: number } {
  if (currentBn === undefined) {
    return {
      trend: 'neutral',
      str: '-',
      valueN: 0,
    };
  }

  const nextN = typeof nextBn === 'number' ? nextBn : Math.round(getHealthFactorNumber(nextBn));
  const currentN =
    typeof currentBn === 'number' ? currentBn : Math.round(getHealthFactorNumber(currentBn));

  const str = getHealthFactorString(nextN);
  if (currentN === nextN) {
    return {
      trend: 'neutral',
      str,
      valueN: nextN,
    };
  }

  if (currentN < nextN) {
    return {
      trend: 'positive',
      str,
      valueN: nextN,
    };
  }

  return {
    trend: 'negative',
    str,
    valueN: nextN,
  };
}

export function deriveHealthFactorProgress(healthFactor: number | BigNumber | undefined): {
  percentage: number;
  condition: ProgressCondition;
} {
  if (healthFactor === undefined) {
    return {
      percentage: 0,
      condition: 'good',
    };
  }
  if (typeof healthFactor === 'number') {
    return {
      percentage: healthFactor === Infinity ? 100 : 0,
      condition: 'good',
    };
  }

  const healthFactorN = getHealthFactorNumber(healthFactor);
  if (healthFactorN < 120) {
    return {
      percentage: (healthFactorN / 120) * 33,
      condition: 'critical',
    };
  }

  if (healthFactorN < 150) {
    return {
      percentage: 33 + ((healthFactorN - 120) / (150 - 120)) * 33,
      condition: 'warn',
    };
  }

  const max = Math.log2(MAX_DISPLAYED_HEALTH_FACTOR / 150);
  const value = Math.log2(Math.max(healthFactorN / 150, 1));

  return {
    percentage: 66 + (value / max) * 34,
    condition: 'good',
  };
}
