import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '@/shared/assets/logo.svg';

type Props = { className?: string };

const StyledLogo = styled(Link)`
  align-items: center;
  display: inline-flex;
  flex-flow: row nowrap;
  text-decoration: none;
`;

const Logo: React.FC<Props> = ({ className }) => (
  <StyledLogo className={className} to="/">
    <LogoIcon />
  </StyledLogo>
);

export { Logo };
