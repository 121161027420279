import { useCallback } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'react-simple-snackbar/dist';

import { useEthers } from '@/services/web3';
import { useTheme, Theme } from '@/services/theme';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Spinner } from '@/shared/components/Spinner';
import { Typography } from '@/shared/components/Typography';

type OpenSnackbarParams = {
  type: 'success' | 'error' | 'neutral' | 'pending';
  txHash?: string;
  text?: string;
};

function getTypeColor(type: OpenSnackbarParams['type']) {
  switch (type) {
    case 'success':
      return '#7CFDA0';
    case 'pending':
      return '#FFD15A';
    case 'neutral':
      return '#707082';
    case 'error':
      return '#FF6C6C';
    default:
      return '#707082';
  }
}

function getTypeTitle(type: OpenSnackbarParams['type']) {
  switch (type) {
    case 'success':
      return 'Success';
    case 'pending':
      return 'In progress...';
    case 'neutral':
      return 'Neutral';
    case 'error':
      return 'Transaction failed';
    default:
      return 'Transaction';
  }
}

function getTypeIcon(type: OpenSnackbarParams['type']) {
  switch (type) {
    case 'success':
      return <SpriteIcon icon="check-circle" size={1.8} />;
    case 'pending':
      return <Spinner size="18px" />;
    case 'neutral':
      return null;
    case 'error':
      return <SpriteIcon icon="x-cross-circle" size={1.8} />;
    default:
      return null;
  }
}

// TODO: move colors to theme, it pushes error without reason for now
const StyledSnack = styled.div`
  margin: -14px -16px;
  min-height: 80px;
  display: flex;
  width: 280px;
`;
const StyledSnackContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: calc(100% - 18px);
`;
const StyledSnackContainer = styled.div`
  padding: 14px 16px;
  display: flex;
  gap: 15px;
`;
const StyledLeftLine = styled.div`
  width: 4px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${({ type }: { type: OpenSnackbarParams['type'] }) => getTypeColor(type)};
`;
const StyledLink = styled.a`
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 1.4rem;
  text-decoration: none;

  svg {
    fill: ${({ theme }: { theme: Theme }) => theme.colors.text.secondary};
  }

  &:hover svg {
    fill: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  }

  span {
    border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.text.secondary};
  }

  &:hover span {
    border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  }
`;

const StyledText = styled(Typography)`
  margin-top: 0.6rem;
`;

const Snack: React.FC<OpenSnackbarParams> = ({ type, txHash, text }) => {
  const { theme } = useTheme();
  const { etherscanUrl } = useEthers();

  return (
    <StyledSnack>
      <StyledLeftLine type={type} />
      <StyledSnackContainer>
        {getTypeIcon(type)}
        <StyledSnackContent>
          <Typography color="primary" display="block" weight={400} size={1.4}>
            {getTypeTitle(type)}
          </Typography>

          {text && <StyledText variant="body2">{text}</StyledText>}

          {txHash && (
            <StyledLink
              aria-label="View on Etherscan"
              href={`${etherscanUrl}/tx/${txHash}`}
              theme={theme}
              rel="noreferrer"
              target="_blank"
            >
              <span>Tx at Etherscan</span>
              <SpriteIcon icon="external-link" size={1.4} />
            </StyledLink>
          )}
        </StyledSnackContent>
      </StyledSnackContainer>
    </StyledSnack>
  );
};

const useOpenSnackbar = () => {
  const [openSnackbar, closeSnackbar] = useSnackbar({
    position: 'bottom-right',
    style: {
      alignItems: 'flex-start',
      backgroundColor: '#fff',
      borderRadius: 8,
    },
    closeStyle: {
      marginTop: 5,
      color: '#000',
    },
  });

  const open = useCallback(
    ({ txHash, type, text }: OpenSnackbarParams) => {
      openSnackbar(<Snack txHash={txHash} type={type} text={text} />, 2 * 60 * 1000);
    },
    [openSnackbar],
  );

  const close = useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  return [open, close] as const;
};

export { useOpenSnackbar };
