import { providers, Signer, utils } from 'ethers';
import { isAddress } from 'ethers/lib/utils';
import { createContext, useEffect, useState } from 'react';

import { Network } from '@/shared/types';
import * as W3M from '@/services/wallet';
import { useAppConfig } from '@/core/AppConfig';

export type EthersContextType = {
  chainId?: number;
  isConnecting: boolean;
  isConnected: boolean;
  etherscanUrl?: string;
  network?: Network;
  defaultNetwork?: Network;
  provider?: providers.Web3Provider;
  signer?: Signer;
  userAddress?: string;
  setWallet: (provider: providers.ExternalProvider) => void;
  disconnectWallet: () => void;
};

export const MAINNET_CHAIN_ID = 5;

export const NETWORKS: { [key: number]: Network } = {
  42: 'kovan',
  1: 'mainnet',
  56: 'bsc',
  137: 'polygon',
  250: 'fantom',
  5: 'goerli',
  4: 'rinkeby',
  3: 'ropsten',
  100: 'xdai',
};

export const ETHERSCAN: { [key: number]: string } = {
  42: 'https://kovan.etherscan.io',
  1: 'https://etherscan.io',
  137: 'https://polygonscan.com',
  56: 'https://www.bscscan.com',
  250: 'https://ftmscan.com',
  5: 'https://goerli.etherscan.io',
  4: 'https://rinkeby.etherscan.io',
  3: 'https://ropsten.etherscan.io',
  100: 'https://blockscout.com/xdai/mainnet',
};

export const EthersContext = createContext<EthersContextType>({} as EthersContextType);

export const EthersProvider: React.FC = ({ children }) => {
  const { cachedProvider, updateAppConfig } = useAppConfig();
  const [provider, setProvider] = useState<providers.Web3Provider | undefined>();
  const [signer, setSigner] = useState<Signer | undefined>();
  const [userAddress, setUserAddress] = useState<string | undefined>();
  const [chainId, setChainId] = useState<number>();
  const network = chainId ? NETWORKS[chainId] : undefined;
  const etherscanUrl = chainId ? ETHERSCAN[chainId] : undefined;
  const isConnected =
    typeof provider !== 'undefined' &&
    typeof signer !== 'undefined' &&
    typeof userAddress === 'string' &&
    isAddress(userAddress);
  const isConnecting = !!cachedProvider && !isConnected;
  const defaultNetwork = provider === undefined ? NETWORKS[MAINNET_CHAIN_ID] : undefined;

  const setWallet = async (externalProvider: providers.ExternalProvider) => {
    const newProvider = new providers.Web3Provider(externalProvider, 'any');
    const newSigner = newProvider.getSigner();
    const newAddress = await newSigner.getAddress();
    const newNetwork = await newProvider.getNetwork();

    setProvider(newProvider);
    setSigner(newSigner);
    setUserAddress(utils.getAddress(newAddress));
    setChainId(newNetwork.chainId);
  };

  const disconnectWallet = async () => {
    W3M.disconnect();

    setProvider(undefined);
    setSigner(undefined);
    setUserAddress('');
    setChainId(undefined);

    updateAppConfig({ appError: '', cachedProvider: '' });
  };

  const onChainChanged = (newChainId: string) => {
    setChainId(parseInt(newChainId, 16));
  };

  const onAccountsChanged = (newAccounts: string[]) => {
    setUserAddress(newAccounts[0]);
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', onChainChanged);
      window.ethereum.on('accountsChanged', onAccountsChanged);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!cachedProvider) {
        setUserAddress('');
        return;
      }
      try {
        const newProvider = await W3M.reconnect(cachedProvider);
        if (newProvider) {
          await setWallet(newProvider);
        } else {
          setUserAddress('');
        }
      } catch {
        setUserAddress('');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EthersContext.Provider
      value={{
        chainId,
        isConnecting,
        isConnected,
        etherscanUrl,
        network,
        defaultNetwork,
        provider,
        signer,
        userAddress,
        setWallet,
        disconnectWallet,
      }}
    >
      {children}
    </EthersContext.Provider>
  );
};
