import React from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { useEthers } from '@/services/web3';
import { NFT_VAULTS_STABILITY_FEE } from '@/shared/utils/consts';
import { Typography } from '@/shared/components/Typography';
import { Button } from '@/shared/components/Button';
import { Tooltip } from '@/shared/components/Tooltip';
import { Modal, useModal } from '@/services/modal';
import { ReactComponent as MascotVault } from '@/shared/assets/mascot-vault.svg';
import { ReactComponent as Mascot } from '@/shared/assets/mascot.svg';
import { WalletConnector } from '@/features/walletConnect';
import { StyledLink } from '@/features/nft/NftList/NftListEmpty';

interface StyleProps {
  theme: Theme;
  isNarrow?: boolean;
}

const Container = styled.section`
  background-color: ${({ theme }: StyleProps) => theme.colors.white};
  border-radius: 2.4rem;
  padding: ${({ isNarrow }: StyleProps) => (isNarrow ? '2.4rem' : '6.4rem 3.2rem;')};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled(Typography)`
  margin-top: ${({ isNarrow }: StyleProps) => (isNarrow ? '2.4rem' : '6.4rem')};
  font-size: ${({ isNarrow }: StyleProps) => (isNarrow ? '2rem' : '3.6rem')};
  line-height: ${({ isNarrow }: StyleProps) => (isNarrow ? '2.8rem' : '4.8rem')};
`;

const Description = styled(Typography)`
  max-width: 73.6rem;
  margin-top: ${({ isNarrow }: StyleProps) => (isNarrow ? '0.8rem' : '1.6rem')};
`;

const CreateButton = styled(Button)`
  display: block;
  margin-top: 2.4rem;
  width: ${({ isNarrow }: StyleProps) => (isNarrow ? '100%' : 'auto')};
`;

const Info = styled.div`
  max-width: 73.6rem;
  width: 100%;
  margin-top: ${({ isNarrow }: StyleProps) => (isNarrow ? '2.4rem' : '6.4rem')};
  padding-top: ${({ isNarrow }: StyleProps) => (isNarrow ? '2.4rem' : '')};

  ${({ isNarrow, theme }: StyleProps) =>
    isNarrow
      ? `
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      border-top: 1px solid ${theme.colors.naviBlue4};
    `
      : `
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    `}
`;

const InfoItem = styled.div`
  display: flex;
  row-gap: 0.8rem;
  width: 100%;
  text-align: center;
  justify-content: ${({ isNarrow }: StyleProps) => (isNarrow ? 'space-between' : 'center')};
  flex-direction: ${({ isNarrow }: StyleProps) => (isNarrow ? 'row' : 'column')};
  padding: ${({ isNarrow }: StyleProps) => (isNarrow ? '0' : '0 2rem')};

  &:not(:first-child) {
    border-left: 1px solid
      ${({ theme, isNarrow }: StyleProps) => (isNarrow ? 'none' : theme.colors.naviBlue4)};
  }
`;

const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ isNarrow }: StyleProps) => (isNarrow ? '0.4rem' : '0.8rem')};
`;

const StyledModal = styled(Modal)`
  width: calc(100% - 1.6rem);
  max-width: 64rem;
`;

interface Props {
  className?: string;
  isCreating?: boolean;
  isNarrow?: boolean;
  onCreateClick: () => void;
}

const infoItems = [
  {
    label: 'Min collateral amount',
    value: '100$',
    tooltip: `Minimum value of the LP NFT position deposited as collateral.`,
  },
  {
    label: 'Max loan per vault',
    value: '100,000 BOB',
    tooltip: `Maximum amount available to borrow from a single vault.`,
  },
  {
    label: 'Stability fee',
    value: `${NFT_VAULTS_STABILITY_FEE}%`,
    tooltip: `The annual stability fee accrues continuously on outstanding debt.`,
  },
];

const NftVaultCreate = ({ className, onCreateClick, isNarrow, isCreating }: Props) => {
  const modalKey = 'wallet-connector';
  const { theme } = useTheme();
  const { userAddress } = useEthers();
  const { activeModal, setActiveModal } = useModal();
  const isModalOpen = activeModal === modalKey;

  const showModal = () => setActiveModal(modalKey);

  const renderInfoItem = (item: { label: string; value: string; tooltip: string }) => (
    <InfoItem key={item.label} theme={theme} isNarrow={isNarrow}>
      <InfoLabel isNarrow={isNarrow}>
        <Typography textSize="md" color="primary">
          {item.label}
        </Typography>
        <Tooltip name={item.label} innerText={item.tooltip} />
      </InfoLabel>
      <Typography textSize={isNarrow ? 'md' : 'lg'} weight={600} color="primary">
        {item.value}
      </Typography>
    </InfoItem>
  );

  return (
    <Container className={className} theme={theme} isNarrow={isNarrow}>
      {!isNarrow && <MascotVault width="32.8rem" height="12.4rem" />}
      {isNarrow && <Mascot width="8rem" height="6.4rem" />}

      <Title variant="h2" isNarrow={isNarrow}>
        Create new vault
      </Title>
      {!isNarrow && (
        <Description variant="body1" isNarrow={isNarrow}>
          Borrow BOB against your LP tokens from Uniswap&nbsp;V3. Just create a vault and put
          USDC/BOB, USDT/BOB, ETH/BOB, or WMATIC/BOB LP tokens as collateral to mint BOB! Learn more
          about parameter configurations{' '}
          <StyledLink
            href="https://bob-docs.zkbob.com/bob-cdp/system-configurations"
            target="_blank"
            rel="noreferrer"
            $theme={theme}
          >
            here
          </StyledLink>
        </Description>
      )}
      {isNarrow && (
        <Description variant="body1" isNarrow>
          Deposit your Uniswap&nbsp;V3 LP tokens as collateral to borrow BOB. Only certain LP tokens
          can be used. Learn more about available pool pairs{' '}
          <StyledLink
            href="https://bob-docs.zkbob.com/bob-cdp/system-configurations"
            target="_blank"
            rel="noreferrer"
            $theme={theme}
          >
            here
          </StyledLink>
        </Description>
      )}

      {!userAddress && (
        <CreateButton
          variant="secondary"
          disabled={isModalOpen}
          onClick={showModal}
          isNarrow={isNarrow}
        >
          Connect wallet
        </CreateButton>
      )}
      {userAddress && (
        <CreateButton
          variant="secondary"
          onClick={onCreateClick}
          disabled={isCreating}
          isNarrow={isNarrow}
        >
          Create vault
        </CreateButton>
      )}

      <Info theme={theme} isNarrow={isNarrow}>
        {infoItems.map(renderInfoItem)}
      </Info>

      <StyledModal isOpen={isModalOpen}>
        <WalletConnector />
      </StyledModal>
    </Container>
  );
};

export { NftVaultCreate };
