import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { useModal } from '@/services/modal';
import { useTheme, Theme } from '@/services/theme';

type Props = {
  className?: string;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onExited?: () => void;
};

const StyledFocusLock = styled(FocusLock)`
  margin: 56px 0;
`;

const StyledModal = styled.div`
  transition: all 250ms;
`;

const StyledOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background.modal};
  transition: background-color 250ms;

  &.exit {
    background-color: transparent;
  }

  @media (min-height: ${({ modalHeight }: { modalHeight: number }) => `${modalHeight + 112}px`}) {
    justify-content: center;
  }

  &.enter,
  &.exit {
    ${StyledModal} {
      opacity: 0;
    }
  }

  &.enter-done {
    overflow: ${({ modalHeight }: { modalHeight: number }) =>
      modalHeight + 112 > window.innerHeight ? 'hidden scroll' : 'hidden'};

    ${StyledModal} {
      opacity: 1;
    }
  }
`;

const Modal: React.FC<Props> = ({
  children,
  className = '',
  isOpen = false,
  onOpen,
  onClose,
  onExited,
}) => {
  const { pathname } = useLocation();
  const { setActiveModal } = useModal();
  const { theme } = useTheme();
  const [modalHeight, setModalHeight] = useState(0);
  const modalRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const closeModal = (event: MouseEvent) => {
    event.stopPropagation();
    if (event.target === overlayRef.current) {
      setActiveModal('');
    }
  };

  useEffect(() => {
    if (!modalRef.current) return;

    const height = Math.round(modalRef.current.getBoundingClientRect().height);
    setModalHeight(height);
  }, [isOpen]);

  useEffect(() => {
    setActiveModal('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    if (isOpen && typeof onOpen === 'function') {
      onOpen();
    }
    if (!isOpen && typeof onClose === 'function') {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleKeyDown = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') setActiveModal('');
  };

  return createPortal(
    <CSSTransition
      in={isOpen}
      timeout={{ enter: 0, exit: 250 }}
      nodeRef={overlayRef}
      mountOnEnter
      unmountOnExit
      onExited={onExited}
    >
      <StyledOverlay modalHeight={modalHeight} ref={overlayRef} theme={theme} onClick={closeModal}>
        <StyledFocusLock>
          <StyledModal className={className} ref={modalRef} onKeyDown={handleKeyDown}>
            {children}
          </StyledModal>
        </StyledFocusLock>
      </StyledOverlay>
    </CSSTransition>,
    document.getElementById('modal') as HTMLDivElement,
  );
};

export { Modal };
