import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

import { useTheme, Theme } from '@/services/theme';

type Props = {
  showButtonBack?: boolean;
  size?: number;
  className?: string;
  onBackClick?: () => void;
};

const StyledHeader = styled.header`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  order: 1;
`;

const StyledBack = styled(IconButton)`
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};

  &:hover {
    background: ${({ theme }: { theme: Theme }) => theme.colors.blue3};
  }
`;

const PageTitle: React.FC<Props> = ({ children, showButtonBack, size, className, onBackClick }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const handleBackClick = () => (onBackClick ? onBackClick() : navigate(-1));

  return (
    <StyledHeader className={className}>
      {showButtonBack && (
        <StyledBack
          theme={theme}
          icon="chevron-left"
          size={3.6}
          iconSize={2.4}
          aria-label="Back"
          title="Back"
          color="none"
          onClick={handleBackClick}
        />
      )}
      <Typography variant="h2" size={size}>
        {children}
      </Typography>
    </StyledHeader>
  );
};

export { PageTitle };
