import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import { Typography } from '../Typography';
import { SpriteIcon } from '../SpriteIcon';

import { useTheme, Theme } from '@/services/theme';

type Option = {
  label: string;
  action: () => void;
};

type Props = {
  header?: React.ReactNode;
  animateIndicator?: boolean;
  className?: string;
  indicatorBorder?: boolean;
  indicatorIcon?: string;
  indicatorIconSize?: number;
  options: Option[];
  title?: string;
  renderButton?: (onClick: () => void, isOpen: boolean) => JSX.Element;
};

const StyledRoot = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  cursor: pointer;
  display: inline-flex;
  gap: 1.2rem;
  text-transform: uppercase;
`;

const StyledIndicator = styled.span`
  height: 2.4rem;
  width: 2.4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 1px solid
    ${({ border, theme }: { border: boolean; theme: Theme }) =>
      border ? theme.colors.gray5 : 'none'};
  border-radius: 50%;
  fill: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  transition: transform 250ms;
  transform: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'rotate(-180deg)' : 'none')};

  &:hover {
    filter: brightness(150%);
  }
`;

const StyledMenu = styled.div`
  position: absolute;
  right: 0;
  width: 37rem;

  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  box-shadow: 0 2px 8px ${({ theme }: { theme: Theme }) => theme.colors.shadow4};
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.secondary};
  transition: all 250ms;
  z-index: 10;

  margin-top: 1rem;
  opacity: 1;

  &.enter,
  &.exit {
    margin-top: 2rem;
    opacity: 0;
  }
`;

const StyledMenuList = styled.ul`
  list-style-type: none;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const StyledMenuItem = styled.li`
  border-radius: 1.6rem;
  cursor: pointer;
  padding: 1.8rem 2.4rem;
  background: ${({ theme }: { theme: Theme }) => theme.colors.naviBlue5};
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};

  &:hover {
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.violet3};
  }
`;

const Menu: React.VFC<Props> = ({
  header,
  className,
  animateIndicator = false,
  indicatorBorder = false,
  indicatorIcon = 'caret-down',
  indicatorIconSize = 0.8,
  options,
  title,
  renderButton,
}) => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const rootRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLElement>(null);

  const hideMenu = () => setIsOpen(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const withMenuHide = (action: () => void) => () => {
    hideMenu();
    action();
  };

  const handleOutsideClick = (e: PointerEvent) => {
    const isInsdeClick = rootRef.current?.contains(e.target as HTMLElement);
    if (!isInsdeClick) hideMenu();
  };

  useEffect(() => {
    document.body.addEventListener('pointerdown', handleOutsideClick);

    return () => document.body.removeEventListener('pointerdown', handleOutsideClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledRoot ref={rootRef} className={className}>
      {renderButton && renderButton(toggleMenu, isOpen)}

      {!renderButton && (
        <StyledButton onClick={toggleMenu} theme={theme}>
          {title}
          <StyledIndicator
            border={indicatorBorder}
            isOpen={animateIndicator && isOpen}
            theme={theme}
          >
            <SpriteIcon icon={indicatorIcon} size={indicatorIconSize} />
          </StyledIndicator>
        </StyledButton>
      )}
      <CSSTransition
        in={isOpen}
        timeout={{ enter: 0, exit: 250 }}
        nodeRef={menuRef}
        mountOnEnter
        unmountOnExit
      >
        <StyledMenu className={className && `${className}__menu`} theme={theme} ref={menuRef}>
          {header}
          <StyledMenuList>
            {options.map(({ label, action }) => (
              <StyledMenuItem
                key={label}
                onClick={withMenuHide(action)}
                role="button"
                theme={theme}
              >
                <Typography color="primary" textSize="md">
                  {label}
                </Typography>
              </StyledMenuItem>
            ))}
          </StyledMenuList>
        </StyledMenu>
      </CSSTransition>
    </StyledRoot>
  );
};

export { Menu };

export type { Option };
