import { useRef } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';

import { useAppConfig } from '@/core/AppConfig';
import { useModal } from '@/services/modal';
import { useEthers } from '@/services/web3';
import { useTheme, Theme } from '@/services/theme';
import { NftVaultPage } from '@/pages/NftVaultPage';
import { NftVaultsPage } from '@/pages/NftVaultsPage';
import { Footer } from '@/shared/components/Footer';
import { Header } from '@/shared/components/Header';
import { WrongNetwork } from '@/features/WrongNetwork';
import { NFT_VAULTS_NETWORK } from '@/shared/utils/env';

const routes = [
  { path: '/nft-vaults', Element: NftVaultsPage },
  { path: '/nft-vaults/:id', Element: NftVaultPage },
];

const StyledError = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.pink6};
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  padding: 0.4rem 0;
  text-align: center;
`;

const StyledWrap = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background.page};
  background-image: ${({ theme }: { theme: Theme }) => theme.colors.gradients.page};
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  filter: ${({ isBlurred }: { isBlurred: boolean }) => (isBlurred ? 'blur(1rem)' : 'none')};
  transition: filter 250ms;
`;

const StyledFade = styled.div`
  &.enter {
    opacity: 0;

    &-done {
      opacity: 1;
    }
  }

  &.exit {
    opacity: 0;
  }

  transition: opacity 250ms;
`;

function App() {
  const location = useLocation();
  const { theme } = useTheme();
  const { activeModal } = useModal();
  const transitionRef = useRef();
  const { appError } = useAppConfig();
  const { network, isConnected } = useEthers();
  const isWrongNetwork = isConnected && network !== NFT_VAULTS_NETWORK;

  return (
    <StyledWrap isBlurred={!!activeModal} theme={theme}>
      {appError && <StyledError theme={theme}>{appError}</StyledError>}
      <Header />
      <SwitchTransition>
        <CSSTransition key={location.key} timeout={{ enter: 0, exit: 250 }} nodeRef={transitionRef}>
          <StyledFade ref={transitionRef}>
            {isWrongNetwork && <WrongNetwork />}
            {!isWrongNetwork && (
              <Routes key={location.key} location={location}>
                {routes.map(({ path, Element }) => (
                  <Route key={path} path={path} element={<Element />} />
                ))}
                <Route path="*" element={<Navigate replace to="/nft-vaults" />} />
              </Routes>
            )}
          </StyledFade>
        </CSSTransition>
      </SwitchTransition>
      <Footer />
    </StyledWrap>
  );
}

export { App };
