import React, { useState } from 'react';
import styled from 'styled-components';
import { BigNumber, constants } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

import { useEthers } from '@/services/web3';
import { useTheme, Theme } from '@/services/theme';
import { NftVault, NftVaultItem, ContractsMap } from '@/shared/types';
import { useContract } from '@/shared/utils/useContract';
import { calcGasLimit } from '@/shared/utils/gas';
import { getErrorMessage } from '@/shared/utils/errors';
import { formatWithLength, clampBn } from '@/shared/utils/format';
import { useOpenSnackbar } from '@/features/Snackbar';
import { calculateNewHealthFactor, getNewHealthFactor } from '@/shared/utils/nft';
import { Button } from '@/shared/components/Button';
import { Skeleton } from '@/shared/components/Skeleton';
import { TrendValue } from '@/shared/components/TrendValue';
import { Typography } from '@/shared/components/Typography';
import { Spinner } from '@/shared/components/Spinner';
import { SliderInput } from '@/shared/form';
import { MIN_MINT_BOB_AMOUNT } from '@/shared/utils/consts';

const Container = styled.section`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  padding: 2.4rem;
`;

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.4rem;
`;

const TabButton = styled.button`
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  padding: 1.2rem;
  border: none;
  border-radius: 1.6rem;
  color: ${({ isActive, theme }: { isActive: boolean; theme: Theme }) =>
    isActive ? theme.colors.text.primary : theme.colors.text.secondary};
  background: ${({ isActive, theme }: { isActive: boolean; theme: Theme }) =>
    isActive ? theme.colors.pink : 'transparent'};
  cursor: pointer;

  &:hover {
    ${({ isActive, theme }: { isActive: boolean; theme: Theme }) =>
      !isActive && `background: ${theme.colors.blue3}`}
`;

const Info = styled.div`
  margin-top: 2.4rem;
  padding: 2.4rem 0;
  border-top: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:first-child) {
    margin-top: 1.6rem;
  }
`;

const Input = styled(SliderInput)`
  margin-top: 2.4rem;
`;

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.8rem;
`;

const Balance = styled.button`
  border: none;
  background: transparent;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
  cursor: pointer;

  &:hover {
    ${({ theme }: { theme: Theme }) => theme.colors.violet1};
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

type FormType = 'mint' | 'repay';

interface Props {
  vaultId?: string;
  className?: string;
  onMint: (isMinting: boolean) => void;
  onVaultUpdate: (
    vaultContract: ContractsMap['NftVault'],
    vaultRegistryContract: ContractsMap['NftVaultRegistry'],
    id: string,
  ) => Promise<any>;
  isLoading?: boolean;
  isMinting?: boolean;
  vaultState: NftVault['state'];
  nfts: Array<NftVaultItem>;
  maxDebt: BigNumber;
  isReadOnly: boolean;
}

const MintRepayForm = ({
  vaultId,
  className,
  onMint,
  onVaultUpdate,
  isLoading,
  vaultState,
  isMinting,
  nfts,
  maxDebt,
  isReadOnly,
}: Props) => {
  const { theme } = useTheme();

  const { userAddress } = useEthers();
  const bobContract = useContract('Bob');
  const vaultContract = useContract('NftVault');
  const vaultRegistryContract = useContract('NftVaultRegistry');
  const [openSnackBar] = useOpenSnackbar();

  const [tab, setTab] = React.useState<FormType>('mint');
  const [isBalanceLoading, setBalanceLoading] = useState(true);
  const [bobBalance, setBobBalance] = React.useState(constants.Zero);
  const [value, setValue] = React.useState<BigNumber>(constants.Zero);
  const [allowance, setAllowance] = React.useState<BigNumber>(constants.Zero);
  const [isApproving, setIsApproving] = React.useState(false);

  const borrowLimit = clampBn(vaultState?.borrowLimit || constants.Zero, constants.Zero, maxDebt);
  const availableToMint = clampBn(
    borrowLimit.sub(vaultState?.overallDebt || constants.Zero),
    constants.Zero,
  );
  const currentDebt = clampBn(vaultState?.overallDebt || constants.Zero, constants.Zero);
  const debtGap = currentDebt.div(10000);
  const availableToMintSafe = clampBn(
    availableToMint.sub(!debtGap.isZero() ? debtGap : MIN_MINT_BOB_AMOUNT),
    constants.Zero,
  );
  const availableToRepaySafe = clampBn(currentDebt.mul(10001).div(10000), constants.Zero);
  const maxMintAmount = availableToMintSafe.lt(MIN_MINT_BOB_AMOUNT)
    ? constants.Zero
    : availableToMintSafe;
  const amountLimit = tab === 'mint' ? maxMintAmount : availableToRepaySafe;

  const mintLimitUsed =
    vaultState?.overallDebt && !borrowLimit.eq(constants.Zero)
      ? clampBn(
          vaultState?.overallDebt.mul(10000).div(borrowLimit),
          constants.Zero,
          BigNumber.from(10000),
        )
      : constants.Zero;

  const checkAllowance = React.useCallback(async () => {
    if (bobContract && userAddress && vaultContract) {
      const allowance = await bobContract.allowance(userAddress, vaultContract.address);
      setAllowance(allowance);
    }
  }, [bobContract, userAddress, vaultContract]);

  React.useEffect(() => {
    checkAllowance();
  }, [checkAllowance]);

  const calcHealthFactor = React.useCallback(() => {
    const diff = value.mul(tab === 'mint' ? constants.One : constants.NegativeOne);
    const newDebt = clampBn(vaultState?.overallDebt.add(diff) || constants.Zero, constants.Zero);
    const newHealthFactor = calculateNewHealthFactor(nfts, newDebt);
    return newHealthFactor;
  }, [tab, value, nfts, vaultState?.overallDebt]);

  const loadBalance = React.useCallback(async () => {
    if (bobContract && userAddress) {
      try {
        setBalanceLoading(true);
        const balance = await bobContract.balanceOf(userAddress);
        setBobBalance(balance);
      } catch (error) {
        const message = getErrorMessage(error);
        openSnackBar({ type: 'error', text: message });
      }
      setBalanceLoading(false);
    }
  }, [bobContract, userAddress, openSnackBar]);

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      if (isMinting) {
        return;
      }

      const newTab = (event.target as HTMLDivElement)?.dataset.tab as FormType;
      if (newTab && newTab !== tab) {
        setTab(newTab);
        setValue(constants.Zero);
        loadBalance();
      }
    },
    [tab, isMinting, loadBalance],
  );

  const handleInputChange = React.useCallback((value: BigNumber) => {
    setValue(value);
  }, []);

  const handleBalanceClick = React.useCallback(() => {
    if (availableToRepaySafe) {
      const value = bobBalance.lte(availableToRepaySafe) ? bobBalance : availableToRepaySafe;
      setValue(value);
    }
  }, [bobBalance, availableToRepaySafe]);

  const handleApprove = React.useCallback(async () => {
    if (bobContract && vaultContract) {
      setIsApproving(true);
      const tx = await bobContract.approve(vaultContract.address, constants.MaxUint256);
      openSnackBar({
        type: 'pending',
        text: 'Approval transaction has been sent',
        txHash: tx.hash,
      });
      await tx?.wait();
      await checkAllowance();
      openSnackBar({
        type: 'success',
        text: 'Your approval has been succeed',
        txHash: tx.hash,
      });
      setIsApproving(false);
    }
  }, [bobContract, vaultContract, openSnackBar, checkAllowance]);

  const handleMintRepayClick = React.useCallback(
    async () => {
      if (vaultContract && vaultRegistryContract && vaultId) {
        try {
          onMint(true);
          const method = tab === 'mint' ? 'mintDebt' : 'burnDebt';

          const estimatedGas = await vaultContract.estimateGas[method](
            BigNumber.from(vaultId),
            value,
          );
          const tx = await vaultContract[method](BigNumber.from(vaultId), value, {
            gasLimit: calcGasLimit(estimatedGas),
          });
          openSnackBar({
            type: 'pending',
            text: 'Your transaction has been sent',
            txHash: tx.hash,
          });
          await tx?.wait();
          await onVaultUpdate(vaultContract, vaultRegistryContract, vaultId);
          loadBalance();
          onMint(false);
          setValue(constants.Zero);
          openSnackBar({
            type: 'success',
            text: 'Your transaction has been succeed',
            txHash: tx.hash,
          });
        } catch (error) {
          const message = getErrorMessage(error);
          openSnackBar({ type: 'error', text: message });
          onMint(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vaultId, vaultContract, tab, value, onMint, onVaultUpdate],
  );

  const healthFactor = calcHealthFactor();
  const newHealthFactor = getNewHealthFactor(vaultState?.healthFactor, healthFactor);

  const hasEnoughAllowance = allowance.gte(value);

  return (
    <Container className={className} theme={theme}>
      <Header>
        <TabButton
          isActive={tab === 'mint'}
          onClick={handleTabChange}
          data-tab="mint"
          theme={theme}
        >
          Mint BOB
        </TabButton>
        <TabButton
          isActive={tab === 'repay'}
          onClick={handleTabChange}
          data-tab="repay"
          theme={theme}
        >
          Repay BOB
        </TabButton>
      </Header>
      <Input
        key={tab}
        title="Quantity"
        decimals={18}
        maxValue={amountLimit}
        value={value}
        onChange={handleInputChange}
      >
        {tab === 'repay' && (
          <BalanceWrapper>
            <Typography textSize="md">Available BOB:</Typography>
            {isBalanceLoading && <Spinner size="1.6rem" />}
            {!isBalanceLoading && (
              <Balance theme={theme} onClick={handleBalanceClick}>
                {formatWithLength(bobBalance, 18, 0)}
              </Balance>
            )}
          </BalanceWrapper>
        )}
      </Input>
      <Info theme={theme}>
        <InfoRow>
          <Typography variant="label">
            {tab === 'mint' ? 'Available to mint' : 'Current debt'}
          </Typography>
          {isLoading ? (
            <Skeleton w={63} h={12} />
          ) : (
            <Typography textSize="md" weight={600} color="primary" component="div">
              {tab === 'mint' &&
                maxMintAmount.lt(constants.WeiPerEther) &&
                !maxMintAmount.isZero() &&
                '~'}
              {tab === 'repay' &&
                currentDebt.lt(constants.WeiPerEther) &&
                !currentDebt.isZero() &&
                '~'}
              {formatWithLength(tab === 'mint' ? maxMintAmount : currentDebt, 18, 0)} BOB
            </Typography>
          )}
        </InfoRow>
        <InfoRow>
          <Typography variant="label">Mint limit used</Typography>
          {isLoading ? (
            <Skeleton w={37} h={12} />
          ) : (
            <Typography textSize="md" weight={600} color="primary" component="div">
              {Math.round(Number(formatUnits(mintLimitUsed, 0)) / 100)}%
            </Typography>
          )}
        </InfoRow>
        <InfoRow>
          <Typography variant="label">New health factor</Typography>
          {isLoading ? (
            <Skeleton w={50} h={12} />
          ) : (
            <TrendValue type={newHealthFactor.trend} value={newHealthFactor.str} />
          )}
        </InfoRow>
      </Info>
      {tab === 'repay' && !hasEnoughAllowance && (
        <SubmitButton
          variant="secondary"
          disabled={isReadOnly || isLoading || isApproving}
          loading={isApproving}
          onClick={handleApprove}
        >
          Approve BOB
        </SubmitButton>
      )}
      {(tab === 'mint' || hasEnoughAllowance) && (
        <SubmitButton
          variant="secondary"
          disabled={isReadOnly || value.isZero() || isLoading || isMinting}
          loading={isMinting}
          onClick={handleMintRepayClick}
        >
          {tab === 'mint' ? 'Mint' : 'Repay'} BOB
        </SubmitButton>
      )}
    </Container>
  );
};

export { MintRepayForm };
