import styled from 'styled-components';

import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { NFT_VAULTS_NETWORK_ICON, NFT_VAULTS_NETWORK_NAME } from '@/shared/utils/env';

type Props = {
  className?: string;
};

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.8rem;
  border-radius: 1.8rem;
  background: #fffaee;
  flex-flow: row nowrap;
  font-size: 1.6rem;
  line-height: 2.4rem;
  justify-content: flex-end;
  padding: 0.6rem 1.6rem 0.6rem 0.8rem;
`;

const Network: React.VFC<Props> = ({ className = '' }) => (
  <StyledContainer className={className}>
    <SpriteIcon icon={NFT_VAULTS_NETWORK_ICON} size={2.4} />
    {NFT_VAULTS_NETWORK_NAME}
  </StyledContainer>
);

export { Network };
