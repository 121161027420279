import type { Props as ResourcesProps } from './components/Resources/Resources';

export const resourcesList: ResourcesProps[] = [
  {
    title: 'About',
    resources: [
      {
        name: 'zkBob',
        href: 'https://www.zkbob.com',
        isExternal: true,
      },
      {
        name: 'BOB token',
        href: 'https://bob.zkbob.com',
        isExternal: true,
      },
    ],
  },
  {
    title: 'Developers',
    resources: [
      {
        name: 'Documentation',
        href: 'https://bob-docs.zkbob.com/bob-cdp',
        isExternal: true,
      },
      {
        name: 'GitHub',
        href: 'https://github.com/zkBob/cdp-nft-ui',
        isExternal: true,
      },
    ],
  },
  {
    title: 'Community',
    resources: [
      {
        name: 'Twitter',
        href: 'https://twitter.com/zkBob_',
        isExternal: true,
      },
      {
        name: 'Telegram',
        href: 'https://t.me/zkbobcommunity',
        isExternal: true,
      },
      {
        name: 'Discord',
        href: 'https://discord.gg/zkbob',
        isExternal: true,
      },
    ],
  },
];
