import React, { ReactChild } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { SpriteIcon } from '../SpriteIcon';

import { useTheme, Theme } from '@/services/theme';

type Props = {
  name: string;
  className?: string;
  iconElement?: ReactChild;
  icon?: string;
  innerText: string;
} & React.HTMLProps<HTMLSpanElement>;

const StyledTooltip = styled.span`
  display: inline-block;
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
`;

const StyledIconWrap = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.6rem;
  width: 1.6rem;

  color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
  cursor: pointer;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 36.5rem !important;
  border-radius: 0.8rem !important;
  padding: 1.2rem !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: ${({ theme }: { theme: Theme }) => theme.colors.white} !important;
  background: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1} !important;

  &.show {
    opacity: 1 !important;
  }
`;

const Tooltip: React.FC<Props> = ({
  name,
  innerText,
  className,
  iconElement,
  icon = 'i',
  ...props
}) => {
  const { theme } = useTheme();

  return (
    <>
      <StyledTooltip
        data-for={name}
        data-tip={innerText}
        className={className}
        theme={theme}
        {...props}
      >
        {iconElement !== undefined ? (
          iconElement
        ) : (
          <StyledIconWrap theme={theme}>
            <SpriteIcon icon={icon} color="none" size={1.6} />
          </StyledIconWrap>
        )}
      </StyledTooltip>
      <StyledReactTooltip effect="solid" theme={theme} id={name} />
    </>
  );
};

export { Tooltip };
