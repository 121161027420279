import styled, { css } from 'styled-components';

import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';
import { Spinner } from '@/shared/components/Spinner';

type Sizes = 'md' | 'sm';

type Variants = 'primary' | 'primary-outline' | 'secondary' | 'secondary-outline';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  size?: Sizes;
  variant?: Variants;
  loading?: boolean;
}

type StyledProps = {
  size: Sizes;
  variant: Variants;
  theme: Theme;
};

const StyledButton = styled.button`
  ${({ size, variant, theme }: StyledProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${size === 'sm' ? '0.8rem' : '1.2rem'};
    background: ${theme.colors.buttons.background.solid[variant]};
    color: ${theme.colors.buttons.text.solid[variant]};
    border-color: ${theme.colors.buttons.border.solid[variant]};
    border-width: 0.1rem;
    border-style: solid;
    cursor: pointer;
    font-family: ${theme.fonts.main};
    border-radius: ${size === 'sm' ? '1.8rem' : '4.6rem'};
    font-size: ${size === 'sm' ? '1.6rem' : '2rem'};
    font-weight: ${size === 'sm' ? '400' : '600'};
    line-height: ${size === 'sm' ? '2.4rem' : '2.8rem'};
    padding: ${size === 'sm' ? '0.5rem 1.5rem' : '1.5rem 2.3rem'};

    &:hover,
    &:focus {
      background: ${theme.colors.buttons.background.hover[variant]};
      color: ${theme.colors.buttons.text.hover[variant]};
      border-color: ${theme.colors.buttons.border.hover[variant]};
    }

    &:active {
      background: ${theme.colors.buttons.background.active[variant]};
      color: ${theme.colors.buttons.text.active[variant]};
      border-color: ${theme.colors.buttons.border.active[variant]};
    }

    &:disabled {
      background: ${theme.colors.buttons.background.disabled[variant]};
      color: ${theme.colors.buttons.text.disabled[variant]};
      border-color: ${theme.colors.buttons.border.disabled[variant]};
      cursor: default;
    }
  `}
`;

const Button = ({ children, size = 'md', variant = 'primary', loading, ...props }: Props) => {
  const { theme } = useTheme();

  return (
    <StyledButton size={size} variant={variant} theme={theme} {...props}>
      {loading && <Spinner color="currentColor" size={size === 'sm' ? '1.6rem' : '2rem'} />}
      <Translated>{children}</Translated>
    </StyledButton>
  );
};

export { Button };
