import styled, { css } from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { SpriteIcon } from '@/shared/components/SpriteIcon';

interface Props extends React.ComponentPropsWithoutRef<'a'> {
  href: string;
}

type StyledProps = {
  theme: Theme;
};

const Icon = styled(SpriteIcon)`
  color: currentColor;
`;

const StyledLink = styled.a`
  ${({ theme }: StyledProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    background: ${theme.colors.violet2};
    color: ${theme.colors.white};
    cursor: pointer;
    font-family: ${theme.fonts.main};
    text-decoration: none;
    border-radius: 4.6rem;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.8rem;
    padding: 1.6rem 2.4rem;

    &:hover,
    &:focus {
      background: ${theme.colors.violet1};
      text-decoration: underline;
    }

    &:active {
      background: ${theme.colors.violet3};
      color: ${theme.colors.navyBlue1};
    }
  `}
`;

const ExternalAddressButton = ({ children, ...props }: Props) => {
  const { theme } = useTheme();

  return (
    <StyledLink theme={theme} {...props} target="_blank">
      {children}
      <Icon icon="external-link" color="none" size={2.4} />
    </StyledLink>
  );
};

export { ExternalAddressButton };
