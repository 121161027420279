import React from 'react';
import styled from 'styled-components';

import { Typography } from '@/shared/components/Typography';
import { Button } from '@/shared/components/Button';
import { Theme, useTheme } from '@/services/theme';

const Description = styled(Typography)`
  margin-top: 2.4rem;
`;

const DepositButton = styled(Button)`
  margin-top: 2.4rem;
`;

const StyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet2};
  font-family: ${({ $theme }: { $theme: Theme }) => $theme.fonts.main};
  line-height: 2rem;
  text-decoration: none;
  text-underline-offset: 0.35em;

  &:focus,
  &:hover {
    color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
    text-decoration: underline solid ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
    text-decoration-color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
  }
`;

interface Props {
  maxNfts: number;
  onDepositClick: () => void;
  disabledDeposits: boolean;
}

const NftListEmptyComponent = ({ maxNfts, onDepositClick, disabledDeposits }: Props) => {
  const { theme } = useTheme();
  return (
    <>
      <Description variant="body1">
        To borrow BOB from locked collateral, you need to deposit an Uniswap&nbsp;V3 LP token first.
        You can only deposit {maxNfts}&nbsp;LP&nbsp;tokens per vault, and not all LP tokens can be
        used as collateral. Learn more about parameter configurations{' '}
        <StyledLink
          href="https://bob-docs.zkbob.com/bob-cdp/system-configurations"
          target="_blank"
          rel="noreferrer"
          $theme={theme}
        >
          here
        </StyledLink>
      </Description>
      <Description variant="body1">
        Uniswap&nbsp;V3 LP tokens that can be used as collateral: USDC/BOB, USDT/BOB, ETH/BOB, or
        WMATIC/BOB LP
      </Description>
      <DepositButton variant="secondary" onClick={onDepositClick} disabled={disabledDeposits}>
        Deposit NFT
      </DepositButton>
    </>
  );
};

const NftListEmpty = NftListEmptyComponent;

export { NftListEmpty, StyledLink };
