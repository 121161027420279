import React from 'react';
import styled from 'styled-components';
import { BigNumber, constants } from 'ethers';

import { NftListItem } from './NftListItem';
import { NftListEmpty } from './NftListEmpty';
import { NftDepositModal } from './NftDepositModal';
import { NftWithdrawModal } from './NftWithdrawModal';

import { useTheme, Theme } from '@/services/theme';
import { useModal } from '@/services/modal';
import { NftVault, NftVaultItem } from '@/shared/types';
import { Button } from '@/shared/components/Button';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Typography } from '@/shared/components/Typography';

const Container = styled.section`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  padding: 2.4rem;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PlusIcon = styled(SpriteIcon)`
  color: currentColor;
`;

const List = styled.ul`
  list-style-type: none;
  margin-top: 2.4rem;
`;

const Item = styled(NftListItem)`
  &:not(:first-child) {
    padding-top: 1.6rem;
    margin-top: 1.6rem;
    border-top: 1px dashed ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
  }
`;

interface Props {
  className?: string;
  items: Array<NftVaultItem>;
  maxNfts: number;
  onDeposit: (nfts: Array<NftVaultItem>) => void;
  onWithdraw: (nft: BigNumber) => void;
  isLoading?: boolean;
  vaultState: NftVault['state'];
  isReadOnly: boolean;
}

enum MODALS {
  DEPOSIT = 'nft_deposit',
  WITHDRAW = 'nft_withdraw',
}

const emptyItem: NftVaultItem = {
  pair: [
    { symbol: '', decimals: 18, value: constants.Zero, price: 0 },
    { symbol: '', decimals: 18, value: constants.Zero, price: 0 },
  ],
  usdValue: BigNumber.from('0'),
  collateral: BigNumber.from('0'),
  id: BigNumber.from('0'),
  status: 'idle',
  liquidationThreshold: constants.Zero,
  nftBorrowLimit: constants.Zero,
  isSupported: true,
};

const NftList = ({
  className,
  items,
  maxNfts,
  onDeposit,
  onWithdraw,
  isLoading,
  vaultState,
  isReadOnly,
}: Props) => {
  const { theme } = useTheme();
  const { activeModal, setActiveModal } = useModal();
  const [itemIdToWithdraw, setItemToWithdraw] = React.useState<BigNumber | undefined>();

  const handleDepositButtonClick = React.useCallback(() => {
    setActiveModal(MODALS.DEPOSIT);
  }, [setActiveModal]);

  const handleDeposit = React.useCallback(
    (nfts: Array<NftVaultItem>) => {
      setActiveModal('');
      onDeposit(nfts);
    },
    [onDeposit, setActiveModal],
  );

  const handleModalClose = React.useCallback(() => {
    setActiveModal('');
  }, [setActiveModal]);

  const handleWithdrawClick = React.useCallback(
    (nftId: BigNumber) => {
      setItemToWithdraw(nftId);
      setActiveModal(MODALS.WITHDRAW);
    },
    [setItemToWithdraw, setActiveModal],
  );

  const handleWithdraw = React.useCallback(
    (id: BigNumber) => {
      setActiveModal('');
      onWithdraw(id);
    },
    [onWithdraw, setActiveModal],
  );

  const content = (() => {
    if (isLoading) {
      return (
        <>
          <Header>
            <Typography variant="h1" textSize="lg">
              LPs
            </Typography>
          </Header>
          <List>
            <Item key="loading_0" theme={theme} {...emptyItem} isLoading />
            <Item key="loading_1" theme={theme} {...emptyItem} isLoading />
          </List>
        </>
      );
    }

    if (items.length === 0) {
      return (
        <>
          <Header>
            <Typography variant="h1" textSize="lg">
              Deposit LP
            </Typography>
          </Header>
          <NftListEmpty
            maxNfts={maxNfts}
            onDepositClick={handleDepositButtonClick}
            disabledDeposits={isReadOnly}
          />
        </>
      );
    }

    return (
      <>
        <Header>
          <Typography variant="h1" textSize="lg">
            LPs
          </Typography>
          <Button
            size="sm"
            onClick={handleDepositButtonClick}
            disabled={isReadOnly || items.length >= maxNfts}
          >
            <PlusIcon color="none" icon="plus-thin" size={1.6} />
            Deposit
          </Button>
        </Header>
        <List>
          {items.map(item => (
            <Item
              {...item}
              theme={theme}
              key={item.id.toString()}
              onWithdraw={handleWithdrawClick}
              isReadOnly={isReadOnly}
            />
          ))}
        </List>
      </>
    );
  })();

  const itemToWithdraw = items.find(({ id }) => itemIdToWithdraw && id.eq(itemIdToWithdraw));

  return (
    <Container className={className} theme={theme}>
      {content}
      <NftDepositModal
        maxNfts={maxNfts}
        isOpen={activeModal === MODALS.DEPOSIT}
        onClose={handleModalClose}
        onSubmit={handleDeposit}
      />
      <NftWithdrawModal
        isOpen={activeModal === MODALS.WITHDRAW}
        onClose={handleModalClose}
        item={itemToWithdraw}
        nfts={items}
        onSubmit={handleWithdraw}
        vaultState={vaultState}
      />
    </Container>
  );
};

export { NftList };
