import React from 'react';
import styled from 'styled-components';
import { BigNumber } from 'ethers';

import { HealthFactorModal } from './HealthFactorModal';

import { IconButton } from '@/shared/components/IconButton';
import { useTheme, Theme } from '@/services/theme';
import { useModal } from '@/services/modal';

const MODAL_NAME = 'health-factor';

const StyledIconButton = styled(IconButton)`
  color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
`;

interface Props {
  id: string;
  borrowLimit?: BigNumber;
  liquidationLimit?: BigNumber;
}

const HealthFactorInfo: React.FC<Props> = ({ id, borrowLimit, liquidationLimit }) => {
  const { theme } = useTheme();
  const { activeModal, setActiveModal } = useModal();
  const modalName = `${MODAL_NAME}-${id}`;

  const handleClick = React.useCallback(
    event => {
      event.stopPropagation();
      setActiveModal(modalName);
    },
    [modalName, setActiveModal],
  );

  return (
    <>
      <StyledIconButton
        theme={theme}
        icon="i"
        color="none"
        size={1.6}
        iconSize={1.6}
        onClick={handleClick}
      />
      <HealthFactorModal
        isOpen={activeModal === modalName}
        borrowLimit={borrowLimit}
        liquidationLimit={liquidationLimit}
        onClose={() => setActiveModal('')}
      />
    </>
  );
};

export { HealthFactorInfo };
