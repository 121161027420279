import styled, { css } from 'styled-components';

import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';
import { addRem } from '@/shared/styles';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'p',
  body2: 'p',
  label: 'span',
  nav: 'span',
  span: 'span',
};

type TextSize = 'xl' | 'lg' | 'md' | 'sm';

const textSizeMapping: Record<TextSize, string> = {
  xl: 'font-size: 3.6rem; line-height: 4.8rem;',
  lg: 'font-size: 2rem; line-height: 2.8rem;',
  md: 'font-size: 1.6rem; line-height: 2.4rem;',
  sm: 'font-size: 1.4rem; line-height: 2rem;',
};

export interface TypographyProps extends React.HTMLProps<HTMLElement> {
  align?: 'left' | 'right' | 'center';
  className?: string;
  color?: 'primary' | 'secondary' | 'tertiary' | 'beta' | 'error' | 'success';
  component?: React.ElementType;
  display?: 'block' | 'inline-block' | 'inline';
  float?: 'left' | 'right';
  letterSpacing?: number | string;
  lineHeight?: number | string;
  margin?: string;
  mx?: string | number;
  my?: string | number;
  mt?: string | number;
  mr?: string | number;
  mb?: string | number;
  ml?: string | number;
  shadow?: boolean;
  textSize?: TextSize;
  smallCaps?: boolean;
  transform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase';
  variant?: keyof typeof variantMapping;
  weight?: 400 | 600;
}

type StyledProps = TypographyProps & { theme: Theme };

const StyledTypographyRoot = styled.span`
  ${({
    align,
    color,
    display,
    float,
    letterSpacing,
    lineHeight,
    margin,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    shadow,
    textSize,
    smallCaps,
    theme,
    transform,
    variant = 'span',
    weight,
  }: StyledProps): CSSRuleList => {
    const colors = {
      primary: theme.colors.text.primary,
      secondary: theme.colors.text.secondary,
      tertiary: theme.colors.text.tertiary,
      beta: theme.colors.gray12,
      error: theme.colors.error,
      success: theme.colors.success,
    };

    const styleOverrides = css`
      ${align !== undefined && `text-align: ${align};`}
      ${color !== undefined && `color: ${colors[color]};`}
      ${display !== undefined && `display: ${display};`}
      ${float !== undefined && `float: ${float};`}
      ${textSize !== undefined && textSizeMapping[textSize]}
      ${letterSpacing !== undefined && `letter-spacing: ${addRem(letterSpacing)};`}
      ${lineHeight !== undefined && `line-height: ${lineHeight};`}
      ${margin !== undefined && `margin: ${margin};`}
      ${mx && `margin-left: ${addRem(mx)}; margin-right: ${addRem(mx)};`}
      ${my && `margin-top: ${addRem(my)}; margin-bottom: ${addRem(my)};`}
      ${mt && `margin-top: ${addRem(mt)};`}
      ${mr && `margin-right: ${addRem(mr)};`}
      ${mb && `margin-bottom: ${addRem(mb)};`}
      ${ml && `margin-left: ${addRem(ml)};`}
      ${shadow && `text-shadow: 0 0 4px ${theme.colors.text.primary}80;`}
      ${smallCaps && `font-variant: small-caps; text-transform: lowercase;`}
      ${transform && `text-transform: ${transform};`}
      ${weight !== undefined && `font-weight: ${weight};`}
    `;

    const headingStyles = css`
      color: ${theme.colors.text.primary};
      font-weight: 600;
    `;

    const variantStyles = {
      h1: css`
        ${headingStyles}
        font-size: 4.8rem;
        line-height: 6rem;
      `,
      h2: css`
        ${headingStyles}
        font-size: 3.6rem;
        line-height: 4.8rem;
      `,
      h3: css`
        ${headingStyles}
        font-size: 2.4rem;
        line-height: 3.2rem;
      `,
      h4: css`
        ${headingStyles}
        font-size: 1.8rem;
        line-height: 2.4rem;
      `,
      body1: css`
        color: ${theme.colors.text.secondary};
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
      `,
      body2: css`
        color: ${theme.colors.text.primary};
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
      `,
      label: css`
        display: block;
        color: ${theme.colors.text.secondary};
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
      `,
      nav: css`
        color: ${theme.colors.text.secondary};
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.3;
      `,
      span: css`
        color: ${theme.colors.text.secondary};
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
      `,
    };

    return css`
      ${variantStyles[variant]};
      ${styleOverrides};
    `;
  }}
`;

const Typography: React.FC<TypographyProps> = ({
  children,
  component,
  variant = 'span',
  ...props
}) => {
  const { theme } = useTheme();

  const Component = component || variantMapping[variant];

  return (
    <StyledTypographyRoot as={Component} theme={theme} variant={variant} {...props}>
      <Translated>{children}</Translated>
    </StyledTypographyRoot>
  );
};

export { Typography };
