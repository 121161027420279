import styled from 'styled-components';

import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';

type Props = {
  checked?: boolean;
  onChange: (checked: boolean) => void;
};

const StyledSwitch = styled.label`
  cursor: pointer;
  display: inline-flex;
  position: relative;
`;

const StyledIndicator = styled.span`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 1.1rem;
  height: 2.2rem;
  left: 0;
  margin: 0.3rem;
  position: absolute;
  transition: left 250ms;
  width: 2.2rem;
  z-index: 2;
`;

const StyledBackground = styled.span`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.gray6};
  border-radius: 1.4rem;
  height: 2.8rem;
  transition: all 250ms;
  width: 4.4rem;
  z-index: 1;
`;

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;

  &:active,
  &:focus {
    ~ ${StyledBackground} {
      outline: 2px solid ${({ theme }: { theme: Theme }) => theme.colors.gray20};
    }
  }

  &:checked {
    + ${StyledIndicator} {
      left: 1.6rem;
    }

    ~ ${StyledBackground} {
      background-color: ${({ theme }: { theme: Theme }) => theme.colors.purple9};
      box-shadow: 0 1px 2px ${({ theme }: { theme: Theme }) => theme.colors.shadow4};
    }
  }
`;

const StyledLabelText = styled.span`
  align-self: center;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  font-size: 1.2rem;
  margin-left: 1rem;
`;

const Switch: React.FC<Props> = ({ checked = false, children, onChange }) => {
  const { theme } = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);

  return (
    <StyledSwitch theme={theme}>
      <StyledCheckbox
        type="checkbox"
        defaultChecked={checked}
        onChange={handleChange}
        theme={theme}
      />
      <StyledIndicator theme={theme} />
      <StyledBackground theme={theme} />
      {children && (
        <StyledLabelText theme={theme}>
          <Translated>{children}</Translated>
        </StyledLabelText>
      )}
    </StyledSwitch>
  );
};

export { Switch };
