import { useState } from 'react';
import ReactSelect, { components, ControlProps, IndicatorProps, OptionProps } from 'react-select';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { useAppConfig } from '@/core/AppConfig';
import { translate } from '@/services/locale';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Typography } from '@/shared/components/Typography';

type Option = {
  value: string;
  label: string;
};

type Props<T> = {
  className?: string;
  defaultValue?: T;
  error?: boolean;
  extraComponents?: { [K in keyof typeof components]?: React.FC<any> };
  helperText?: string;
  isDisabled?: boolean;
  label?: string;
  listAlign?: 'left' | 'right';
  onChange?: (selectedOption: T) => void;
  options?: T[];
  placeholder?: string;
  selectRef?: React.RefObject<HTMLSelectElement>;
};

const prepareStyles = (theme: Theme) => ({
  container: (provided: CSSRuleList) => ({
    ...provided,
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    minWidth: '10.4rem',
  }),
  control: (provided: CSSRuleList, { isDisabled, isFocused }: ControlProps<Option, false>) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isDisabled
      ? theme.colors.gray9
      : isFocused
      ? theme.colors.gray6
      : theme.colors.gray7,
    border: isDisabled ? `2px solid ${theme.colors.gray8}` : '2px solid transparent',
    borderRadius: '0.8rem',
    boxShadow: `0 1px 3px ${isDisabled ? 'transparent' : theme.colors.shadow4}`,
    color: isDisabled ? theme.colors.text.secondary : theme.colors.text.primary,
    cursor: 'pointer',
    flexFlow: 'row nowrap',
    padding: '0.8rem',
    '&:hover': {
      backgroundColor: theme.colors.gray6,
    },
  }),
  dropdownIndicator: (provided: CSSRuleList, { isFocused }: IndicatorProps<Option, false>) => ({
    ...provided,
    height: '2.4rem',
    width: '2.4rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid`,
    borderColor: isFocused ? theme.colors.gray4 : theme.colors.gray5,
    borderRadius: '1.2rem',
    padding: 0,
    transition: 'transform 0.25s',
  }),
  menu: (provided: CSSRuleList) => ({
    ...provided,
    backgroundColor: theme.colors.gray7,
    borderRadius: '0.8rem',
    boxShadow: `0 3px 8px ${theme.colors.shadow4}`,
    color: theme.colors.text.primary,
    minWidth: '100%',
    padding: '0.8rem',
    transition: 'all 0.25s',
    width: 'auto',
  }),
  menuList: (provided: CSSRuleList) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: CSSRuleList, { isFocused, isSelected }: OptionProps<Option, false>) => ({
    ...provided,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isSelected
      ? theme.colors.gray5
      : isFocused
      ? theme.colors.gray6
      : 'transparent',
    borderRadius: '0.6rem',
    cursor: 'pointer',
    margin: '0',
    padding: '0.6rem 0.8rem',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: isSelected ? theme.colors.gray5 : theme.colors.gray6,
    },
  }),
  valueContainer: (provided: CSSRuleList) => ({
    ...provided,
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '2.4rem',
    padding: '0 0.8rem 0 0',
    width: '100%',
  }),
});

const StyledSelect = styled(ReactSelect)`
  &.transition {
    &--open {
      .select__indicator {
        transform: rotate(-180deg);
      }
      .select__menu {
        opacity: 1;
        margin-top: 0.4rem;
      }
    }

    &--close {
      .select__menu {
        opacity: 0;
        margin-top: 1.4rem;
      }
    }
  }
`;

const DropdownIndicator: React.FC<IndicatorProps<Option, boolean>> = props => {
  const { theme } = useTheme();

  return (
    <components.DropdownIndicator {...props}>
      <SpriteIcon color={theme.colors.text.primary} icon="caret-down" size={0.8} />
    </components.DropdownIndicator>
  );
};

const Select = <T extends Option>({
  className,
  error,
  extraComponents = {},
  helperText = 'The filed must not be empty',
  isDisabled = false,
  label,
  listAlign = 'left',
  options = [],
  placeholder = '',
  selectRef,
  ...props
}: Props<T>) => {
  const { theme } = useTheme();
  const { locale } = useAppConfig();
  const selectStyles = prepareStyles(theme);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectClassName, setSelectClassName] = useState(className);

  const translatedOptions = options.map(({ label: optionLabel, value, ...rest }) => ({
    label: translate(optionLabel, locale),
    value,
    ...rest,
  }));

  const onMenuOpen = async () => {
    await setIsMenuOpen(true);
    setSelectClassName('transition--open');
  };

  const onMenuClose = () => {
    setSelectClassName('transition--close');
    setTimeout(() => setIsMenuOpen(false), 250);
  };

  return (
    <label className={className}>
      {label !== undefined && (
        <Typography variant="label" mb={1}>
          {label}
        </Typography>
      )}
      <StyledSelect
        className={selectClassName}
        classNamePrefix="select"
        components={{ DropdownIndicator, ...extraComponents }}
        isDisabled={isDisabled}
        isSearchable={false}
        listAlign={listAlign}
        menuIsOpen={isMenuOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        options={translatedOptions}
        placeholder={translate(placeholder, locale)}
        ref={selectRef}
        styles={selectStyles}
        theme={theme}
        {...props}
      />
      {error && (
        <Typography variant="body2" color="error" mt={0.8} ml={1.2}>
          {helperText}
        </Typography>
      )}
    </label>
  );
};

export { Select };

export type { Option as SelectOption, Props as SelectProps };
