import { ContractInterface } from 'ethers';

import { ContractName, Network } from '@/shared/types';
import { contracts as goerliContracts } from '@/configs/deploy.goerli.json';
import { contracts as polygonContracts } from '@/configs/deploy.mainnet.json';

function getContract(network: Network) {
  switch (network) {
    case 'goerli':
      return goerliContracts;
    case 'polygon':
      return polygonContracts;
    default:
      return null;
  }
}

export function getContractsConfig(network: Network, contract: ContractName) {
  const config = getContract(network);
  if (!config) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const contractConfig = config[contract];
  if (!contractConfig) {
    return null;
  }

  return contractConfig as { address: string; abi: ContractInterface };
}
