import React from 'react';
import styled from 'styled-components';

import { NftModalItem } from './NftModalItem';
import { useFetchUniNfts } from './hooks/useFetchUniNfts';

import { Modal } from '@/services/modal';
import { useTheme, Theme } from '@/services/theme';
import { Checkbox } from '@/shared/form/Checkbox';
import { NftVaultItem } from '@/shared/types';
import { useEthers } from '@/services/web3';
import { useOpenSnackbar } from '@/features/Snackbar';
import { getErrorMessage } from '@/shared/utils/errors';
import { Typography } from '@/shared/components/Typography';
import { IconButton } from '@/shared/components/IconButton';
import { Button } from '@/shared/components/Button';
import { Spinner } from '@/shared/components/Spinner';
import { CheckboxGroup } from '@/shared/form';
import { ReactComponent as Mascot } from '@/shared/assets/mascot.svg';
import { ExternalAddressButton } from '@/shared/components/ExternalAddress';
import { NFT_VAULTS_NETWORK } from '@/shared/utils/env';

const Container = styled(Modal)`
  padding: 2rem 2.4rem 2.4rem;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  width: 656px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 3.6rem;
  margin-bottom: 2.4rem;
`;

const Closer = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue2};

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};
  }
`;

const LoadingOrEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingStateMessage = styled.div`
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
`;

const EmptyDescription = styled(Typography)`
  white-space: pre-line;
`;

const ButtonLink = styled(ExternalAddressButton)`
  margin-top: 2.4rem;
  width: 100%;
`;

const List = styled(CheckboxGroup)`
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
  border-radius: 1.6rem;
  display: block;
  padding: 2.4rem;
  max-height: 280px;
  overflow-y: scroll;
`;

const Item = styled(Checkbox)`
  display: flex;

  &:not(:first-child) {
    margin-top: 1.6rem;
    padding-top: 1.6rem;
    border-top: 1px dashed ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
  }
`;

const StyledNftItem = styled(NftModalItem)`
  margin-left: 1.6rem;
`;

const StyledButton = styled(Button)`
  margin-top: 2.4rem;
  width: 100%;
`;

interface Props {
  maxNfts: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (nfts: Array<NftVaultItem>) => void;
}

const NftDepositModalComponent = ({ maxNfts, isOpen, onClose, onSubmit }: Props) => {
  const { theme } = useTheme();
  const { isConnected } = useEthers();
  const fetchUniNfts = useFetchUniNfts();
  const [openSnackBar] = useOpenSnackbar();

  const [selectedNftIds, selectNftIds] = React.useState<Array<string>>([]);
  const [isLoading, setLoadingState] = React.useState(true);
  const [nfts, setNfts] = React.useState<Array<NftVaultItem>>([]);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (isConnected) {
      (async () => {
        try {
          const filledNfts = await fetchUniNfts();
          setLoadingState(false);
          setNfts(
            filledNfts.sort(
              (a, b) =>
                (b.isSupported ? 1e9 : 0) -
                (a.isSupported ? 1e9 : 0) +
                b.id.toNumber() -
                a.id.toNumber(),
            ),
          );
        } catch (error) {
          const message = getErrorMessage(error);
          openSnackBar({ type: 'error', text: message });
          setLoadingState(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUniNfts, isConnected, isOpen]);

  const handleItemSelect = React.useCallback((value: Array<string>) => {
    selectNftIds(value);
  }, []);

  const handleDepositClick = React.useCallback(async () => {
    const selectedNfts = nfts.filter(nft => selectedNftIds.includes(nft.id.toString()));

    onSubmit(selectedNfts);
  }, [nfts, onSubmit, selectedNftIds]);

  const handleModalClose = React.useCallback(() => {
    setNfts([]);
    selectNftIds([]);
    setLoadingState(true);
  }, []);

  const content = (() => {
    if (isLoading) {
      return (
        <LoadingOrEmptyState>
          <Mascot width="10rem" height="8rem" />
          <LoadingStateMessage>
            <Spinner />
            <Typography textSize="md" color="primary">
              Please, wait...
            </Typography>
          </LoadingStateMessage>
        </LoadingOrEmptyState>
      );
    }

    if (nfts.length === 0) {
      return (
        <LoadingOrEmptyState>
          <Mascot width="10rem" height="8rem" />
          <EmptyDescription variant="body1" align="center" mt={2.4}>
            To create a position, visit Uniswap. Note that only USDC/BOB, USDT/BOB, ETH/BOB, or
            WMATIC/BOB LP pairs can be used as collateral.
          </EmptyDescription>
          <ButtonLink href={`https://app.uniswap.org/#/pool?chain=${NFT_VAULTS_NETWORK}`}>
            Create position
          </ButtonLink>
        </LoadingOrEmptyState>
      );
    }

    const isButtonDisabled = selectedNftIds.length === 0;

    return (
      <>
        <List theme={theme} maxAmount={maxNfts} onChange={handleItemSelect}>
          {nfts.map(nft => (
            <Item
              key={nft.id.toString()}
              value={nft.id.toString()}
              theme={theme}
              disabled={!nft.isSupported}
            >
              <StyledNftItem {...nft} />
            </Item>
          ))}
        </List>
        <StyledButton variant="secondary" disabled={isButtonDisabled} onClick={handleDepositClick}>
          {`Deposit ${selectedNftIds.length || ''} LP`}
        </StyledButton>
      </>
    );
  })();

  return (
    <Container isOpen={isOpen} theme={theme} onExited={handleModalClose}>
      <Header>
        <Typography variant="h1" textSize="lg">
          Deposit LP
        </Typography>
        <Closer
          theme={theme}
          icon="x-cross"
          size={3.6}
          iconSize={2.4}
          aria-label="Close"
          title="Close"
          color="none"
          onClick={onClose}
        />
      </Header>
      {content}
    </Container>
  );
};

const NftDepositModal = NftDepositModalComponent;

export { NftDepositModal };
