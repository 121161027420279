const palette = {
  navyBlue1: '#2A1B5B',
  navyBlue2: '#6D6489',
  navyBlue3: '#A7A2B8',
  naviBlue4: '#DCD8EA',
  naviBlue5: '#F4F3F8',
  white: '#FFFFFF',
  black: '#000000',
  violet1: '#6437C1',
  violet2: 'rgba(100, 55, 193, 0.9)',
  violet3: 'rgba(100, 55, 193, 0.1)',
  orange1: '#EF8726',
  orange2: 'rgba(239, 135, 38, 0.1)',
  red1: '#EF102A',
  red2: 'rgba(239, 16, 42, 0.1)',
  green1: '#3CB47A',
  green2: 'rgba(60, 180, 122, 0.1)',
  blue1: '#1643CE',
  blue2: 'rgba(22, 67, 206, 0.9)',
  blue3: 'rgba(22, 67, 206, 0.1)',
  yellow: '#FBEED0',
  pink: '#FAE4DA',
};

const light = {
  name: 'light',
  fonts: {
    main: ['Gilroy', 'Roboto', "'Open Sans'", 'Helvetica', 'Arial', 'sans-serif'].join(', '),
  },
  colors: {
    ...palette,

    pink6: '#ff63ae',
    slateBlue: '#7b4ceb',
    purple: '#a175fd',
    purpleAccent: '#9143f3',
    purpleChecked: '#A158FC',
    purple2: '#a238ec80',
    purple3: '#a238ecb3',
    purple4: '#b84ceb',
    purple5: '#ab31ff',
    purple6: '#8043f4',
    purple7: '#7043f4',
    purple8: '#5c43f3',
    purple9: '#8256ff',
    purple10: '#9e50ff',
    lime: '#7cfda0',

    text: {
      primary: palette.navyBlue1,
      secondary: palette.navyBlue2,
      tertiary: '#707082',
      underline: '#4d4d5c',
    },

    shadow1: 'rgba(255, 255, 255, 0.1)',
    shadow2: 'rgba(0, 0, 0, 0.15)',
    shadow3: '#ffffff1a',
    shadow4: '#00000033',
    lightShadow: 'rgba(255, 255, 255, 0.3)',
    purpleShadow1: '#ed38d680',
    purpleShadow2: '#ed38d699',

    success: '#5dde81',
    lighterSuccess: '#7eedab',
    error: palette.red1,
    warn: '#FFD15A',

    gray1: '#B7B7CB',
    gray2: '#9B9BAF',
    gray3: '#848498',
    gray4: '#707082',
    gray5: '#5A5A6A',
    gray6: '#484856',
    gray7: '#3E3E4A',
    gray8: '#34343F',
    gray9: '#2B2B34',
    gray10: '#25252D',
    gray11: '#202027',
    gray12: '#6d6d7a',
    gray13: '#a4a4b0',
    gray14: '#555565',
    gray15: '#636375',
    gray16: '#464654',
    gray17: '#31313c',
    gray18: '#4b4b59',
    gray19: '#c6c6db',
    gray20: '#d3d3e1',
    gray21: '#5f5f71',
    gray22: '#373742',
    gray23: '#757588',
    gray24: '#33333e',
    gray25: '#9393a0',
    gray26: '#4f4f61',
    gray27: '#515161',
    gray28: '#A6A6B2',

    background: {
      card: '#fff',
      dark: '#1e1e24',
      link: '#30303a',
      badge: '#282830',
      footer: '#19191F',
      blur: 'rgba(49, 49, 60, 0.8)',
      tooltip: '#15151ae6',
      icon: '#383842',
      input: '#444450',
      modal: '#1E2D5FE5',
      disabled: 'rgba(90, 90, 106, 0.3)',
      page: '#FFF5E3',
    },

    border: {
      primary: '#32323c',
      secondary: '#454552',
      ternary: '#35353f',
      translucentDark1: 'rgba(255, 255, 255, 0.15)',
      translucentDark2: 'rgba(255, 255, 255, 0.04)',
      translucentDisabled: 'rgba(255, 255, 255, 0.07)',
      graphPillar: '#3A3A47',
      lightDark: '#ffffff33',
      dark: '#ffffff0a',
      gray1: '#393943',
      gray2: '#494955',
      gray3: '#3a3a44',
      gray4: '#474756',
      stepper: '#535362',
      divider: '#3d3d49',
    },

    gradients: {
      page: 'linear-gradient(180deg, #FBEDCE 13.28%, #FAFAF9 78.71%)',
      button: 'linear-gradient(96.98deg, #8C5BFF 0%, #F67DE9 100%)',
      shadow: 'linear-gradient(180deg, rgba(30, 30, 36, 0) 0%, #1E1E24 100%)',
      gray1: 'linear-gradient(180deg, #5d5d6d 0%, rgba(93, 93, 109, 0) 100%)',
      gray2: 'linear-gradient(#ffffff 0%, #c6c6db 100%)',
      pillarAccent: 'linear-gradient(#f788f3 0%, #7B4CEB 100%)',
      linkBg: 'linear-gradient(277.88deg, #F993FC 0%, #824FFA 100%)',
      accent1: 'linear-gradient(#a55cff, #9243f4)',
      gray3: 'linear-gradient(#b7b7cb, #848498)',
      accentBg1: 'linear-gradient(90deg, #b76bf9, #a16cf4)',
      accentBg2: 'linear-gradient(90deg, #ad41f8, #9143f3)',
      indicator: 'linear-gradient(90deg, #ed38d6, #9d38ed)',
      hover: 'linear-gradient(180deg, #ed38d6 0%, #8458ff 100%)',
    },

    buttons: {
      text: {
        solid: {
          primary: palette.white,
          'primary-outline': palette.blue2,
          secondary: palette.white,
          'secondary-outline': palette.violet2,
        },
        hover: {
          primary: palette.white,
          'primary-outline': palette.blue1,
          secondary: palette.white,
          'secondary-outline': palette.violet1,
        },
        disabled: {
          primary: palette.white,
          'primary-outline': palette.blue3,
          secondary: palette.white,
          'secondary-outline': palette.violet3,
        },
        active: {
          primary: palette.navyBlue1,
          'primary-outline': palette.navyBlue1,
          secondary: palette.navyBlue1,
          'secondary-outline': palette.navyBlue1,
        },
      },
      background: {
        solid: {
          primary: palette.blue2,
          'primary-outline': 'transparent',
          secondary: palette.violet2,
          'secondary-outline': 'transparent',
        },
        hover: {
          primary: palette.blue1,
          'primary-outline': palette.blue3,
          secondary: palette.violet1,
          'secondary-outline': palette.violet3,
        },
        disabled: {
          primary: palette.blue3,
          'primary-outline': 'transparent',
          secondary: palette.violet3,
          'secondary-outline': 'transparent',
        },
        active: {
          primary: palette.blue3,
          'primary-outline': 'transparent',
          secondary: palette.violet3,
          'secondary-outline': 'transparent',
        },
      },
      border: {
        solid: {
          primary: 'transparent',
          'primary-outline': palette.blue2,
          secondary: 'transparent',
          'secondary-outline': palette.violet2,
        },
        hover: {
          primary: 'transparent',
          'primary-outline': palette.blue1,
          secondary: 'transparent',
          'secondary-outline': palette.violet1,
        },
        disabled: {
          primary: 'transparent',
          'primary-outline': palette.blue3,
          secondary: 'transparent',
          'secondary-outline': palette.violet3,
        },
        active: {
          primary: 'transparent',
          'primary-outline': palette.blue3,
          secondary: 'transparent',
          'secondary-outline': palette.violet3,
        },
      },
    },
  },
};

const themes = {
  light,
};

export type Theme = typeof light;

export { themes };
