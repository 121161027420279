import React from 'react';
import styled from 'styled-components';
import { BigNumber } from 'ethers';

import { breakpoints } from '@/shared/styles';
import { Typography } from '@/shared/components/Typography';
import { NftVault, ProtocolParams } from '@/shared/types';
import { NftVaultCreate, useFillNftInfo, NftVaultCard } from '@/features/nft';
import { useContract } from '@/shared/utils/useContract';
import { calcGasLimit } from '@/shared/utils/gas';
import { useEthers } from '@/services/web3';
import { fillInVault } from '@/shared/utils/nft';
import { useTheme, Theme } from '@/services/theme';
import { isNotEmpty } from '@/shared/utils/guards';
import { useOpenSnackbar } from '@/features/Snackbar';
import { getErrorMessage } from '@/shared/utils/errors';
import { PageContainer } from '@/shared/components/PageContainer';
import { PageTitle } from '@/shared/components/PageTitle';
import { Spinner } from '@/shared/components/Spinner';
import { ReactComponent as MascotHello } from '@/shared/assets/mascot-hello.svg';
import { PageContent } from '@/shared/components/PageContent';
import { PageAside } from '@/shared/components/PageAside';

const Container = styled(PageContainer)`
  flex-direction: column;
`;

const Title = styled(PageTitle)`
  order: 0;
`;

const EmptyState = styled(NftVaultCreate)`
  margin-top: 4.6rem;
`;

const LoadingState = styled.div`
  margin-top: 4.6rem;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 490px;
  gap: 2.4rem;
`;

const LoadingStateMessage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3.2rem;

  @media (min-width: ${breakpoints.tabletXL}px) {
    flex-direction: row;
  }
`;

const NftVaultsPage = () => {
  const [vaults, changeVaults] = React.useState<Array<NftVault>>([]);
  const [isLoading, setLoadingState] = React.useState(true);
  const [isVaultsLoading, setVaultsLoadingState] = React.useState(false);
  const [isCreating, setCreatingFlag] = React.useState(false);
  const [protocolParams, setProtocolParams] = React.useState<ProtocolParams | null>(null);

  const { userAddress } = useEthers();
  const { theme } = useTheme();
  const vaultContract = useContract('NftVault');
  const vaultRegistryContact = useContract('NftVaultRegistry');
  const fillNftInfo = useFillNftInfo();
  const [openSnackBar] = useOpenSnackbar();

  React.useEffect(() => {
    if (!vaultContract) return;

    vaultContract.protocolParams().then(setProtocolParams);
  }, [vaultContract]);

  React.useEffect(() => {
    (async () => {
      changeVaults([]);
      if (vaultRegistryContact && vaultContract && userAddress && protocolParams) {
        setVaultsLoadingState(true);
        try {
          const balance = (await vaultRegistryContact.balanceOf(userAddress)) as BigNumber;
          const vaultsIdsPromises: Array<Promise<BigNumber>> = [];
          for (let i = 0; i < balance.toNumber(); i++) {
            vaultsIdsPromises.push(
              vaultRegistryContact.tokenOfOwnerByIndex(userAddress, i) as Promise<BigNumber>,
            );
          }
          const vaultsIds = await Promise.all(vaultsIdsPromises);

          const vaultsInfo = await Promise.all(
            vaultsIds
              .slice()
              .sort((a, b) => (a.gt(b) ? -1 : 1))
              .map(id => id.toString())
              .map(async id => {
                const vaultNftIds: Array<BigNumber> = await vaultContract.vaultNftsById(id);
                const vaultNfts = (await Promise.all(vaultNftIds.map(fillNftInfo))).filter(
                  isNotEmpty,
                );

                return fillInVault(vaultContract, id, vaultNfts, protocolParams);
              }),
          );

          changeVaults(vaultsInfo);
        } catch (error) {
          const message = getErrorMessage(error);
          openSnackBar({ type: 'error', text: message });
        }
        setVaultsLoadingState(false);
      }
      setLoadingState(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress, fillNftInfo, vaultContract, vaultRegistryContact, protocolParams]);

  const handleVaultCreate = React.useCallback(() => {
    (async () => {
      try {
        setCreatingFlag(true);
        const estimatedGas = await vaultContract?.estimateGas.openVault();
        const result = await vaultContract?.openVault({
          gasLimit: estimatedGas && calcGasLimit(estimatedGas),
        });
        changeVaults([{ id: 'new' }, ...vaults]);

        const waitResult = await result?.wait();
        const newVaultId = waitResult?.events?.find(({ event }) => event === 'VaultOpened')?.args
          ?.vaultId;

        changeVaults([{ id: newVaultId.toString() }, ...vaults]);
        setCreatingFlag(false);
      } catch (error) {
        const message = getErrorMessage(error);
        openSnackBar({ type: 'error', text: message });
        setCreatingFlag(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultContract, vaults]);

  const content = (() => {
    if (isLoading || isVaultsLoading) {
      return (
        <LoadingState theme={theme}>
          <MascotHello />
          <LoadingStateMessage>
            <Spinner />
            <Typography variant="body1" color="primary">
              Please wait...
            </Typography>
          </LoadingStateMessage>
        </LoadingState>
      );
    }

    if (vaults.length === 0) {
      return <EmptyState onCreateClick={handleVaultCreate} isCreating={isCreating} />;
    }

    return (
      <ContentWrapper>
        <PageContent>
          {vaults.map(vault => {
            const isLoading = isCreating && vault.id === 'new';
            const title = isLoading ? 'Please wait...' : `Vault ${vault.id}`;
            return <NftVaultCard key={vault.id} title={title} isLoading={isLoading} {...vault} />;
          })}
        </PageContent>
        <PageAside>
          <NftVaultCreate onCreateClick={handleVaultCreate} isCreating={isCreating} isNarrow />
        </PageAside>
      </ContentWrapper>
    );
  })();

  return (
    <Container>
      {!isLoading && Boolean(vaults.length) && <Title variant="h2">Vaults</Title>}
      {content}
    </Container>
  );
};

export { NftVaultsPage };
