import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { utils } from 'ethers';

import { NftVaultState } from '../NftVaultState';

import { NftVault } from '@/shared/types';
import { shortenString } from '@/shared/utils';
import { localizeNumber } from '@/shared/utils/format';
import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';
import { Spinner } from '@/shared/components/Spinner';
import { Skeleton } from '@/shared/components/Skeleton';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Button } from '@/shared/components/Button';
import { useBlockExplorer } from '@/shared/utils/useBlockExplorer';

interface ContainerStyle {
  theme: Theme;
  disableHover: boolean;
}

const Container = styled.section`
  border-radius: 2.4rem;
  cursor: ${({ disableHover }: ContainerStyle) => (disableHover ? 'default' : 'pointer')};
  position: relative;
  text-decoration: none;
  border: 1px solid transparent;

  &:hover {
    border-color: ${({ theme, disableHover }: ContainerStyle) =>
      disableHover ? 'transparent' : theme.colors.navyBlue3};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
`;

const LiquidatedInfo = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ isSection }: { isSection: true }) =>
    isSection ? '1.5rem 2.3rem' : '0.7rem 1.1rem'};
  background: ${({ theme }: { theme: Theme }) => theme.colors.red2};
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.red1};
  color: ${({ theme }: { theme: Theme }) => theme.colors.red1};
  border-radius: 1rem;
  margin-left: auto;
`;

const TransactionLink = styled.a`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.red1};
`;

const WarningIcon = styled(SpriteIcon)`
  color: currentColor;
  margin-right: 0.4rem;
`;

const Content = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  padding: 2.4rem;
  position: relative;
  border-radius: 2.4rem;
`;

const StateBlock = styled(NftVaultState)`
  margin-top: 2.4rem;
`;

const InfoBlock = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NftName = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
`;

const Pair = styled.div`
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
  border-radius: 1rem;
  padding: 0.6rem 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
`;

const LiquidateBlock = styled.div`
  margin-top: 2.4rem;
`;

const UnclaimedTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
`;

const Claim = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  margin-left: auto;
`;

const ClaimIcon = styled(SpriteIcon)`
  color: currentColor;
`;

interface Props extends NftVault {
  className?: string;
  title: string;
  isLoading?: boolean;
  isMinting?: boolean;
  isVaultPage?: boolean;
  isReadOnly?: boolean;
  onClaim?: () => void;
}

const NftVaultCard = ({
  className,
  title,
  state,
  pairs,
  id,
  isLoading,
  isMinting,
  wasLiquidated,
  unclaimed,
  isVaultPage,
  liquidationTransaction,
  onClaim,
  isReadOnly,
}: Props) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const unclimedBob = unclaimed && Number(utils.formatUnits(unclaimed, 18));
  const { scannerUrl } = useBlockExplorer();

  const nftName = (() => {
    if (isLoading) {
      return (
        <>
          <Skeleton w={64} h={16} />
          <Skeleton w={64} h={16} />
        </>
      );
    }

    if (!pairs || pairs.length === 0) {
      return (
        <Typography textSize="md" weight={600} color="primary">
          None
        </Typography>
      );
    }

    return (
      <>
        <Pair theme={theme}>{pairs[0].join('-')}</Pair>
        {Boolean(pairs[1]) && <Pair theme={theme}>{pairs[1].join('-')}</Pair>}
        {Boolean(pairs[2]) && <Pair theme={theme}>{pairs[2].join('-')}</Pair>}
        {pairs.length > 3 && (
          <Typography textSize="md" color="primary">
            + {pairs.length - 3} other
          </Typography>
        )}
      </>
    );
  })();

  const handleClick = React.useCallback(() => {
    if (id && id !== 'new') {
      navigate(`/nft-vaults/${id}`);
    }
  }, [id, navigate]);

  return (
    <Container
      className={className}
      theme={theme}
      disableHover={isLoading || isVaultPage}
      onClick={handleClick}
    >
      <Content theme={theme}>
        <Header>
          {id === 'new' && <Spinner />}
          <Typography variant="h4" textSize="lg">
            {title}
          </Typography>
          {wasLiquidated && !isVaultPage && (
            <LiquidatedInfo theme={theme}>
              <WarningIcon icon="warning" size={1.6} color="none" />
              <Typography textSize="sm" color="error">
                Vault has been liquidated
              </Typography>
            </LiquidatedInfo>
          )}
        </Header>
        <StateBlock {...state} id={id} isLoading={isLoading} isMinting={isMinting} />
        <InfoBlock>
          <Typography variant="label">UniV3LP</Typography>

          <NftName>{nftName}</NftName>
        </InfoBlock>
        {isVaultPage && (wasLiquidated || unclaimed) && (
          <LiquidateBlock>
            {wasLiquidated && (
              <LiquidatedInfo theme={theme} isSection>
                <Typography textSize="sm" align="center" color="error">
                  Deposited LP positions have been withdrawn by the protocol due to the liquidation
                  of vault. You can reuse current vault and place new LP positions.
                  <br />
                  Transaction of liquidation:{' '}
                  <TransactionLink
                    theme={theme}
                    href={`${scannerUrl}/tx/${liquidationTransaction}`}
                    target="_blank"
                  >
                    {liquidationTransaction && shortenString(liquidationTransaction)}
                  </TransactionLink>
                </Typography>
              </LiquidatedInfo>
            )}
            {Boolean(unclimedBob) && (
              <InfoBlock>
                <UnclaimedTitle>
                  <Typography variant="label">Unclaimed BOB</Typography>
                  <SpriteIcon icon="i" color="none" size={1.6} />
                </UnclaimedTitle>
                <Claim>
                  <Typography textSize="md" color="primary">
                    {`${localizeNumber(unclimedBob, '0', 0)} BOB`}
                  </Typography>
                  <Button
                    size="sm"
                    variant="primary-outline"
                    onClick={onClaim}
                    disabled={isReadOnly}
                  >
                    <ClaimIcon color="none" icon="arrow-down" size={1.6} />
                    Claim
                  </Button>
                </Claim>
              </InfoBlock>
            )}
          </LiquidateBlock>
        )}
      </Content>
    </Container>
  );
};

export { NftVaultCard };
