import React from 'react';
import styled from 'styled-components';
import { formatUnits } from 'ethers/lib/utils';
import { BigNumber } from 'ethers';

import { localizeNumber } from '@/shared/utils/format';
import { NftVaultItem } from '@/shared/types';
import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';
import { Spinner } from '@/shared/components/Spinner';
import { SpriteIcon } from '@/shared/components/SpriteIcon';

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  opacity: ${({ isSupported }: { isSupported: boolean }) => (isSupported ? '1' : '0.5')};
`;

const NotSupportedOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -3.6rem;
  right: 0;
  opacity: 0;
  background: #fff;
  padding-left: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NotSupportedIcon = styled(SpriteIcon)`
  position: absolute;
  left: 0;
  color: ${({ theme }: { theme: Theme }) => theme.colors.error};
`;

const NotSupportedLint = styled.a`
  color: ${({ theme }: { theme: Theme }) => theme.colors.error};
`;

const Container = styled.div`
  position: relative;
  flex-grow: 1;
  ${({ isSupported }: { isSupported: boolean }) =>
    !isSupported &&
    `
    &:hover ${Content} {
      opacity: 0;
    }
    &:hover ${NotSupportedOverlay} {
      opacity: 1;
    }
  `}
`;

const Coins = styled.div`
  flex-shrink: 0;

  & > svg {
    position: relative;
    z-index: 1;

    &:last-child {
      z-index: 0;
      margin-left: -0.8rem;
    }
  }
`;

const RedStyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.red1};
  font-family: ${({ $theme }: { $theme: Theme }) => $theme.fonts.main};
  line-height: 2rem;
  text-decoration: none;
  text-underline-offset: 0.35em;

  &:focus,
  &:hover {
    color: ${({ $theme }: { $theme: Theme }) => $theme.colors.red1};
    text-decoration: underline solid ${({ $theme }: { $theme: Theme }) => $theme.colors.red1};
    text-decoration-color: ${({ $theme }: { $theme: Theme }) => $theme.colors.red1};
  }
`;

const Pair = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  margin-left: 0.8rem;
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  margin-left: ${({ withApproval }: { withApproval: boolean }) => (withApproval ? '3rem' : 'auto')};
  text-align: ${({ withApproval }: { withApproval: boolean }) => (withApproval ? 'left' : 'right')};
`;

const Approval = styled.div`
  height: 100%;
  margin-left: auto;
`;

const ApprovalText = styled(Typography)`
  color: ${({ isApproved, theme }: { isApproved: boolean; theme: Theme }) =>
    isApproved ? theme.colors.text.secondary : theme.colors.text.primary};

  border-bottom: ${({ isApproved, theme }: { isApproved: boolean; theme: Theme }) =>
    isApproved ? 'none' : `1px dashed ${theme.colors.text.secondary}`};
`;

interface Props extends NftVaultItem {
  className?: string;
  showApproval?: boolean;
  onApproveClick?: (id: BigNumber) => void;
}

const COIN_ICONS: Record<string, string> = {
  WETH: 'weth',
  ETH: 'weth',
  USDC: 'usdc',
  USDT: 'tether',
  WBTC: 'wbtc',
  BOB: 'bob',
  MATIC: 'matic',
  WMATIC: 'matic',
};

const NftModalItemComponent = ({
  className,
  pair,
  id,
  collateral,
  showApproval,
  status,
  isSupported,
  onApproveClick,
}: Props) => {
  const { theme } = useTheme();

  const value = pair
    .map(
      ({ value, symbol, decimals }) =>
        `${localizeNumber(Number(formatUnits(value, decimals)), '0', 10)}&nbsp;${symbol}`,
    )
    .join(' + ');

  const handleApproveClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (status !== 'approved') {
        event.preventDefault();
        onApproveClick?.(id);
      }
    },
    [status, onApproveClick, id],
  );

  const approval = (() => {
    if (status === 'approve_pending') {
      return (
        <Approval>
          <Spinner size="2rem" />
        </Approval>
      );
    }

    return (
      <Approval onClick={handleApproveClick}>
        <ApprovalText textSize="sm" isApproved={status === 'approved'} theme={theme}>
          {status === 'approved' ? 'Approved' : 'Approve'}
        </ApprovalText>
      </Approval>
    );
  })();

  return (
    <Container className={className} isSupported={isSupported}>
      <Content isSupported={isSupported}>
        <Coins>
          {COIN_ICONS[pair[0].symbol] && (
            <SpriteIcon icon={COIN_ICONS[pair[0].symbol]} size={2.4} />
          )}
          {COIN_ICONS[pair[1].symbol] && (
            <SpriteIcon icon={COIN_ICONS[pair[1].symbol]} size={2.4} />
          )}
        </Coins>
        <Pair>
          <Typography textSize="sm" weight={600} color="primary">
            {pair[0].symbol}&mdash;{pair[1].symbol}
          </Typography>
          <Typography textSize="sm">{id.toString()}</Typography>
        </Pair>
        <Value withApproval={showApproval}>
          <Typography textSize="sm" weight={600} color="primary">
            {localizeNumber(Number(formatUnits(collateral, 18)), '0', 0)}&nbsp;USD
          </Typography>
          <Typography textSize="sm">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: value }} />
          </Typography>
        </Value>
        {showApproval && approval}
      </Content>

      <NotSupportedOverlay>
        <NotSupportedIcon theme={theme} icon="warning" size={2.4} color="none" />
        <Typography variant="body1" textSize="sm" weight={600} color="error" mb={0.4}>
          Position cannot be selected
        </Typography>
        <Typography variant="body1" textSize="sm" color="error">
          This position cannot be selected due to provider inconsistency.{' '}
          <NotSupportedLint theme={theme}>
            Learn more{' '}
            <RedStyledLink
              href="https://bob-docs.zkbob.com/bob-cdp/system-configurations#collaterals"
              target="_blank"
              rel="noreferrer"
              $theme={theme}
            >
              here
            </RedStyledLink>
          </NotSupportedLint>
        </Typography>
      </NotSupportedOverlay>
    </Container>
  );
};

const NftModalItem = NftModalItemComponent;

export { NftModalItem };
