import { useState } from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { useEthers } from '@/services/web3';
import { sendSignature } from '@/api';
import { Button } from '@/shared/components/Button';
import { Spinner } from '@/shared/components/Spinner';
import { Typography } from '@/shared/components/Typography';
import { ReactComponent as Mascot } from '@/shared/assets/mascot.svg';

type Props = {
  onAccept: () => void;
  onReject: () => void;
};

const StyledAgreement = styled.div`
  position: relative;
  opacity: ${({ isSigning }: { isSigning: boolean }) => (isSigning ? 0.2 : 1)};
  display: flex;
  flex-direction: column;
`;

const StyledMascot = styled(Mascot)`
  margin: 0 auto 2.4rem;
`;

const StyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet2};
  text-decoration: none;

  &:focus,
  &:hover {
    color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
    text-decoration: underline solid ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
    text-underline-offset: 0.25em;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2.4rem;
  width: 100%;
`;

const StyledSpinnerGroup = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  font-size: 1.4rem;
  z-index: 2;
`;

const WalletAgreement: React.FC<Props> = ({ onAccept, onReject }) => {
  const { theme } = useTheme();
  const { signer, userAddress } = useEthers();
  type SignStatus = 'not signed' | 'pending' | 'signed';
  const [signStatus, setSignStatus] = useState<SignStatus>('not signed');

  const onContinueClick = async () => {
    const agreement =
      'Please sign a message with your digital wallet to confirm that you agree to be bound by our Terms of Service.';

    if (!signer || !userAddress) return;

    setSignStatus('pending');

    try {
      const signature = await signer.signMessage(agreement);
      const isSent = await sendSignature(userAddress, signature);

      if (isSent) {
        setSignStatus('signed');
        onAccept();
      } else {
        setSignStatus('not signed');
        onReject();
      }
    } catch (err) {
      setSignStatus('not signed');
    }
  };

  return (
    <>
      {signStatus !== 'signed' && (
        <>
          <StyledAgreement isSigning={signStatus === 'pending'}>
            <StyledMascot width="10rem" height="8rem" />
            <Typography variant="body1" align="center">
              By connecting the digital wallet, you agree to the{' '}
              <StyledLink href="" $theme={theme}>
                Terms of Service
              </StyledLink>{' '}
              and confirm that you have read and understood the{' '}
              <StyledLink href="" $theme={theme}>
                Privacy Notice
              </StyledLink>{' '}
              and{' '}
              <StyledLink href="" $theme={theme}>
                Risk Disclosure
              </StyledLink>
              .
            </Typography>
            <StyledButton variant="secondary" onClick={onContinueClick}>
              Continue
            </StyledButton>
          </StyledAgreement>
        </>
      )}

      {signStatus === 'signed' && (
        <Typography variant="body1" align="center">
          Signed!
        </Typography>
      )}

      {signStatus === 'pending' && (
        <StyledSpinnerGroup>
          <Spinner /> Signing
        </StyledSpinnerGroup>
      )}
    </>
  );
};

export { WalletAgreement };
