import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

type Props = {
  className?: string;
  main?: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 3.2rem;
  max-width: 1120px;
  margin: 0 auto;
  padding: 3.2rem 1.6rem 4.8rem;
  width: 100%;

  @media (min-width: ${breakpoints.tabletXL}px) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.laptop}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const PageContainer: React.FC<Props> = ({ children, className, main }) => (
  <StyledContainer as={main ? 'main' : 'div'} className={className}>
    {children}
  </StyledContainer>
);

export { PageContainer };
