import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { Button } from '@/shared/components/Button';
import { Spinner } from '@/shared/components/Spinner';
import { Typography } from '@/shared/components/Typography';
import { ReactComponent as Mascot } from '@/shared/assets/mascot.svg';

type Status = 'connected' | 'not connected' | 'error' | 'pending';

type Props = {
  onRetryClick: () => void;
  status: Status;
  wallet: string;
};

const StyledContainer = styled.div`
  align-items: center;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
`;

const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2.4rem;
`;

const LoadingStateMessage = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1.6rem;
`;

const WalletConnectorStatus: React.FC<Props> = ({ status, onRetryClick }) => {
  const { theme } = useTheme();

  return (
    <StyledContainer theme={theme}>
      {status === 'error' && (
        <>
          <Typography variant="h3" size={2} mb={1}>
            Failed to connect to wallet
          </Typography>
          <Typography variant="body1" mb={1.8}>
            Failed to connect to wallet. Try again or choose another method.
          </Typography>
          <Button onClick={onRetryClick}>Try again</Button>
        </>
      )}
      {(status === 'pending' || status === 'connected') && (
        <LoadingState>
          <Mascot width="10rem" height="8rem" />
          <LoadingStateMessage>
            <Spinner />
            <Typography variant="body1">Initialization...</Typography>
          </LoadingStateMessage>
        </LoadingState>
      )}
    </StyledContainer>
  );
};

export { WalletConnectorStatus };

export type { Status };
