import styled from 'styled-components';

import { shortenAddress } from '@/shared/utils';
import { Typography } from '@/shared/components/Typography';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { CopyButton } from '@/shared/components/CopyButton';

interface Props {
  address: string;
  icon?: string;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  gap: 0.8rem;
`;

const StyledCopy = styled(CopyButton)`
  margin-left: auto;
`;

const WalletTitle = ({ address, icon }: Props) => {
  const title = shortenAddress(address, 11, 9);

  return (
    <StyledContainer>
      {icon && <SpriteIcon icon={icon} size={1.8} />}
      <Typography textSize="lg" color="primary">
        {title}
      </Typography>
      <StyledCopy content={address} />
    </StyledContainer>
  );
};

export { WalletTitle };
