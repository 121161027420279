import { useEffect, useState } from 'react';
import { Contract, ContractInterface, providers, Signer } from 'ethers';

import { getContractsConfig } from './getContractsConfig';
import { ContractName, ContractsMap } from '../types';

import { useEthers } from '@/services/web3';

export function createContract<N extends ContractName>(
  name: N,
  address: string,
  abi: ContractInterface,
  signer: Signer | providers.Web3Provider | undefined,
) {
  return new Contract(address, abi, signer) as ContractsMap[N];
}

export function useContract<N extends ContractName>(name: N): ContractsMap[N] | undefined {
  const [contract, setContract] = useState<ContractsMap[ContractName]>();
  const { isConnected, network, signer } = useEthers();

  useEffect(() => {
    if (!isConnected || !network) return;

    const config = getContractsConfig(network, name);
    if (!config) {
      return;
    }

    const { address, abi } = config;
    setContract(createContract(name, address, abi, signer));
  }, [isConnected, name, network, signer]);

  return contract as ContractsMap[N];
}
