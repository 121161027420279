type Env = {
  NFT_VAULTS_NETWORK: string;
  NFT_VAULTS_NETWORK_NAME: string;
  NFT_VAULTS_NETWORK_ICON: string;
};

const polygon: Env = {
  NFT_VAULTS_NETWORK: 'polygon',
  NFT_VAULTS_NETWORK_NAME: 'Polygon',
  NFT_VAULTS_NETWORK_ICON: 'polygon',
};

const goerli: Env = {
  NFT_VAULTS_NETWORK: 'goerli',
  NFT_VAULTS_NETWORK_NAME: 'Goerli',
  NFT_VAULTS_NETWORK_ICON: 'ethereum',
};

const envs: { [key: string]: Env } = { polygon, goerli };
export const { NFT_VAULTS_NETWORK, NFT_VAULTS_NETWORK_NAME, NFT_VAULTS_NETWORK_ICON } =
  envs[process.env.REACT_APP_ENV || 'goerli'];
