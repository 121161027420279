import ContentLoader from 'react-content-loader';

interface Props {
  className?: string;
  bg?: string;
  fg?: string;
  w: number;
  h: number;
}

const SkeletonComponent = ({ className, w, h, bg, fg }: Props) => (
  <ContentLoader
    className={className}
    speed={2}
    width={w}
    height={h}
    backgroundColor={bg || '#DCD8EA'}
    foregroundColor={fg || '#F3F0FF'}
  >
    <rect x="0" y="0" rx={h / 2} ry={h / 2} width={w} height={h} />
  </ContentLoader>
);

const Skeleton = SkeletonComponent;

export { Skeleton };
