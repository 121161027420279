import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { addRem } from '@/shared/styles';
import { Typography } from '@/shared/components/Typography';

export type RadioOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

type Props = {
  className?: string;
  styles?: {
    label?: { [property: string]: string };
    indicator?: { [property: string]: string };
    option?: { [property: string]: string };
  };
  name: string;
  options: RadioOption[];
  selectedItem?: string;
  onSelect: (value: any) => void;
};

type StyledProps = {
  theme: Theme;
};

const StyledGroup = styled.div`
  display: inline-flex;
  position: relative;

  /* Outer border */
  &::after {
    border: 1px solid ${({ theme }: StyledProps) => theme.colors.border.gray1};
    border-radius: 0.6rem;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`;

const StyledIndicator = styled.span`
  background-color: ${({ theme }: StyledProps) => theme.colors.background.input};
  border-radius: 0.6rem;
  box-shadow: 0 1px 2px #00000033;
  height: 100%;
  left: ${({ left }: { left: number }) => `${left}px`};
  position: absolute;
  transition: left 250ms;
  width: ${({ itemWidth }: { itemWidth: number }) => `calc(${itemWidth}% + 1px)`};
  z-index: 2;
`;

const StyledRadio = styled.label`
  cursor: pointer;
  flex: 1 1 0;
  position: relative;
  min-width: ${({ basis }: { basis: number | string }) => addRem(basis)};
  z-index: 3;

  /* Divider line */
  & + &::before {
    border-left: 1px solid ${({ theme }: StyledProps) => theme.colors.background.input};
    bottom: 0.6rem;
    content: '';
    position: absolute;
    top: 0.6rem;
  }
`;

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledLabelText = styled(Typography)`
  border-radius: 0.6rem;
  padding: 0.8rem;
  transition: background-color 500ms;
  z-index: 1;
  color: ${({ theme, disabled }: StyledProps & { disabled: boolean }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
`;

const FlatRadioGroup: React.VFC<Props> = ({
  className,
  name,
  options,
  selectedItem = '',
  styles,
  onSelect,
}) => {
  const { theme } = useTheme();
  const rootRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] = useState<string>(selectedItem || options[0]?.value);
  const itemWidth = 100 / options.length;

  const getIndicatorPosition = () => {
    const groupWidth = rootRef.current?.getBoundingClientRect().width || 0;
    const index = options.findIndex(option => option?.value === selectedValue);
    return (groupWidth / options.length) * index;
  };

  const onChange = (val: string) => {
    setSelectedValue(val);
    onSelect(val);
  };

  useEffect(() => {
    const isCorrectValue = selectedValue !== '' && !!options.find(i => i?.value === selectedValue);
    if (!isCorrectValue) {
      setSelectedValue(options[0]?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setSelectedValue(selectedItem || options[0]?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  return (
    <StyledGroup className={className} ref={rootRef} theme={theme}>
      {options.map(({ value, label, disabled }) => (
        <StyledRadio style={styles?.option} key={value} theme={theme} disabled={disabled}>
          <StyledCheckbox
            type="radio"
            name={name}
            checked={value === selectedValue}
            onChange={() => onChange(value)}
            disabled={disabled}
          />
          <StyledLabelText
            align="center"
            color="primary"
            display="block"
            lineHeight={1}
            styles={styles?.label}
            theme={theme}
            disabled={disabled}
          >
            {label}
          </StyledLabelText>
        </StyledRadio>
      ))}
      <StyledIndicator
        itemWidth={itemWidth}
        left={getIndicatorPosition()}
        styles={styles?.indicator}
        theme={theme}
      />
    </StyledGroup>
  );
};

export { FlatRadioGroup };
