import React from 'react';
import styled from 'styled-components';
import { BigNumber, FixedNumber, constants } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { Range, getTrackBackground } from 'react-range';
import { IThumbProps, ITrackProps } from 'react-range/lib/types';

import { TextInput } from '../TextInput';

import { useTheme, Theme } from '@/services/theme';
import { formatToFirstNonZero } from '@/shared/utils';

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  margin-bottom: 0.8rem;
  position: relative;
  width: 100%;
`;

const Track = styled.div`
  border-bottom-left-radius: 1.6rem;
  cursor: ${({ disabled }: { disabled: boolean }) => (disabled ? 'default' : 'pointer')};
  height: 0.4rem;
  position: absolute;
  bottom: 0;
  left: 0.8rem;
  right: 0.8rem;
`;

const Thumb = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.blue2};
  border-radius: 50%;
  height: 1.6rem;
  width: 1.6rem;
`;

const TextInputLabel = styled.div`
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  gap: 0.8rem;
`;

const Button = styled.button`
  color: ${({ current, theme }: { current: boolean; theme: Theme }) =>
    current ? theme.colors.text.primary : theme.colors.text.secondary};
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  padding: 0.8rem;
  border: none;
  border-radius: 1rem;
  background: ${({ current, theme }: { current: boolean; theme: Theme }) =>
    current ? theme.colors.naviBlue5 : 'transparent'};
  cursor: pointer;

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  }

  &:disabled {
    cursor: default;
  }
`;

interface Props {
  className?: string;
  maxValue: BigNumber;
  value: BigNumber;
  decimals: number;
  disabled?: boolean;
  title: React.ReactNode;
  children?: React.ReactNode;
  onChange: (value: BigNumber) => void;
}

const SliderInputComponent = ({
  className,
  value,
  maxValue,
  decimals,
  disabled,
  title,
  children,
  onChange,
}: Props) => {
  const { theme } = useTheme();

  const [inputValue, setInputValue] = React.useState('');
  const [inputBn, setInputBn] = React.useState(constants.Zero);

  if (!value.eq(inputBn)) {
    const nextValue = formatUnits(value, decimals);
    const formattedValue = formatToFirstNonZero(Number(nextValue), 3);

    setInputValue(formattedValue);
    setInputBn(value);
  }

  const percentage = (() => {
    if (maxValue.lte(constants.Zero)) {
      return 0;
    }

    if (inputBn.gte(maxValue)) {
      return 100;
    }

    return inputBn.mul(100).div(maxValue).toNumber();
  })();

  const renderTrack = React.useCallback(
    ({ props, children }: { props: ITrackProps; children: React.ReactNode }) => (
      <Track
        disabled={disabled}
        {...props}
        style={{
          background: getTrackBackground({
            values: [percentage],
            colors: [theme.colors.blue3, 'transparent'],
            min: 0,
            max: 100,
          }),
        }}
        theme={theme}
      >
        {children}
      </Track>
    ),
    [disabled, percentage, theme],
  );

  const renderThumb = React.useCallback(
    ({ props }: { props: IThumbProps }) => (
      <Thumb {...props} style={{ ...props.style }} theme={theme} />
    ),
    [theme],
  );

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const trimmedValue = event.target.value.replace(/[^.\d]/, '');
      if (Number.isNaN(+trimmedValue) || trimmedValue.search(/^\d{0,18}\.?\d{0,18}$/)) return;

      const valueFn = FixedNumber.fromString(trimmedValue || '0.0');
      const valueBn = BigNumber.from(valueFn);

      setInputBn(valueBn);
      setInputValue(trimmedValue);
      onChange(valueBn);
    },
    [onChange],
  );

  const handleInputBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valueFn = FixedNumber.fromString(value || '0.0');
      const valueBn = BigNumber.from(valueFn);
      const formattedValue = valueFn.toString();

      if (valueBn.gt(maxValue)) {
        setInputBn(maxValue);
        setInputValue(formatUnits(maxValue, decimals));
        onChange(maxValue);
      } else {
        setInputBn(valueBn);
        setInputValue(!value ? value : formattedValue);
        onChange(valueBn);
      }
    },
    [maxValue, decimals, onChange],
  );

  const handleRangeChange = React.useCallback(
    (value: number | number[]) => {
      const percent = Array.isArray(value) ? value[0] : value;
      const nextValueBn = maxValue.mul(percent).div(100);
      const nextValue = formatUnits(nextValueBn, decimals);

      const formattedValue = formatToFirstNonZero(Number(nextValue), 3);
      setInputBn(nextValueBn);
      setInputValue(formattedValue);
      onChange(nextValueBn);
    },
    [maxValue, decimals, onChange],
  );

  return (
    <div className={className}>
      <TitleWrapper>
        {title && <TextInputLabel theme={theme}>{title}</TextInputLabel>}
        {children}
      </TitleWrapper>
      <InputWrapper>
        <TextInput
          disabled={disabled}
          placeholder="0"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
        />
        <Range
          values={[percentage]}
          onChange={handleRangeChange}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
          disabled={disabled}
        />
      </InputWrapper>
      <Buttons theme={theme}>
        {[25, 50, 75, 100].map(value => (
          <Button
            current={value === percentage}
            disabled={disabled}
            key={value}
            left={value}
            onClick={() => handleRangeChange(value)}
            theme={theme}
          >
            {value}%
          </Button>
        ))}
      </Buttons>
    </div>
  );
};

const SliderInput = React.memo<Props>(SliderInputComponent);

export { SliderInput };
