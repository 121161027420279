import React from 'react';

import { Props as CheckboxProps } from '@/shared/form/Checkbox';

interface Props {
  className?: string;
  children: Array<React.ReactElement<CheckboxProps>>;
  disabled?: boolean;
  maxAmount?: number;
  onChange: (value: Array<string>) => void;
}

const CheckboxGroupComponent = ({ className, children, disabled, maxAmount, onChange }: Props) => {
  const [selected, setSelected] = React.useState<Array<string>>([]);
  const isMaxAmount = maxAmount !== undefined && selected.length >= maxAmount;

  const handleChildChange = React.useCallback(
    (_: boolean, props: { value: string }) => {
      const isSelected = selected.includes(props.value);

      if (!isSelected && !isMaxAmount) {
        const nextSelected = [...selected, props.value];
        setSelected(nextSelected);
        onChange?.(nextSelected);
      }

      if (isSelected) {
        const nextSelected = selected.filter(item => item !== props.value);
        setSelected(nextSelected);
        onChange?.(nextSelected);
      }
    },
    [selected, isMaxAmount, onChange],
  );

  const checkboxes = React.useMemo(
    () =>
      React.Children.map(children, child =>
        React.cloneElement(child, {
          ...child.props,
          disabled:
            disabled ||
            (isMaxAmount && !selected.includes(child.props.value)) ||
            child.props.disabled,
          onChange: handleChildChange,
        }),
      ),
    [children, selected, disabled, isMaxAmount, handleChildChange],
  );

  return <span className={className}>{checkboxes}</span>;
};

const CheckboxGroup = CheckboxGroupComponent;

export { CheckboxGroup };
