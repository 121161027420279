import { FormattedMessage } from 'react-intl';

type Props = {
  values?: { [key: string]: string | number };
};

const Translated: React.FC<Props> = ({ children, values = {} }) =>
  typeof children === 'string' ? (
    <FormattedMessage id={children} values={values} />
  ) : (
    <>{children}</>
  );

export { Translated };
