import { useEthers } from '@/services/web3';
import { Network } from '@/shared/types';

const MAINNET_SCANNER = 'https://etherscan.io';

const GOERLI_SCANNER = 'https://goerli.etherscan.io';

const getScanner = (network?: Network): string => {
  if (network === 'mainnet') return MAINNET_SCANNER;

  if (network === 'goerli') return GOERLI_SCANNER;

  return GOERLI_SCANNER;
};

export const useBlockExplorer = () => {
  const { network } = useEthers();

  return { scannerUrl: getScanner(network) };
};
