import React from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';

const Wrapper = styled.div`
  padding: 3rem 0;
  width: 100%;
`;

const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 0.8rem;
  border-radius: 0.4rem;
  background: linear-gradient(90deg, #f20e37 0%, #ffcf26 35.42%, #afec00 69.79%, #00cb39 100%);
`;

const StageInfo = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};
    border-style: solid;
    border-left-color: transparent;
    border-right-color: transparent;
    border-width: 0.7rem;
  }
`;

const Stage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ position }: { position: number }) => position}%;
  width: 1px;
  background: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};

  &:nth-child(odd) ${StageInfo} {
    top: 100%;
    padding-top: 0.9rem;

    &:before {
      border-top-width: 0;
      top: 0;
    }
  }
  &:nth-child(even) ${StageInfo} {
    bottom: 100%;
    padding-bottom: 0.9rem;

    &:before {
      border-bottom-width: 0;
      bottom: 0;
    }
  }
`;

interface Stage {
  position: number; // move from left in percentage
  value: string;
}

interface Props {
  stages: Stage[];
}

const HealthFactorGraph: React.FC<Props> = ({ stages }) => {
  const { theme } = useTheme();

  return (
    <Wrapper>
      <Bar>
        {stages.map(stage => (
          <Stage key={stage.value} position={stage.position} theme={theme}>
            <StageInfo theme={theme}>{stage.value}</StageInfo>
          </Stage>
        ))}
      </Bar>
    </Wrapper>
  );
};

export { HealthFactorGraph };
