import styled from 'styled-components';

import { SpriteIcon } from '../SpriteIcon';

import { useTheme, Theme } from '@/services/theme';

type Props = React.HTMLProps<HTMLButtonElement> & {
  border?: boolean;
  circle?: boolean;
  color?: string;
  icon: string;
  size?: number;
  iconSize?: number;
};

type StyledProps = {
  border: boolean;
  circle: boolean;
  size: number;
  theme: Theme;
};

const StyledButton = styled.button`
  height: ${({ size }: StyledProps) => `${size}rem`};
  width: ${({ size }: StyledProps) => `${size}rem`};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: ${({ theme, border }: StyledProps) =>
    border ? `1px solid ${theme.colors.gray7}` : 'none'};
  border-radius: ${({ circle }: StyledProps) => (circle ? '50%' : '0.8rem')};
  cursor: pointer;

  &:hover {
    filter: brightness(150%);
  }
`;

const IconButton: React.VFC<Props> = ({
  border,
  circle,
  color,
  icon,
  size = 4,
  iconSize = 1.6,
  ...htmlProps
}) => {
  const { theme } = useTheme();

  const iconColor = color || theme.colors.gray2;

  return (
    <StyledButton
      border={border}
      circle={circle}
      size={size}
      type={htmlProps.type || 'button'}
      theme={theme}
      {...htmlProps}
    >
      <SpriteIcon color={iconColor} icon={icon} size={iconSize} />
    </StyledButton>
  );
};

export { IconButton };
