import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';

type Props = {
  type?: 'error';
};

const StyledMessage = styled(Typography)`
  background-color: ${({ theme, isError }: { theme: Theme; isError: boolean }) =>
    isError && theme.colors.error};
  border-radius: 0.6rem;
  padding: 1rem 0;
`;

const FormMessage: React.FC<Props> = ({ children, type = 'error' }) => {
  const { theme } = useTheme();

  return (
    <StyledMessage variant="body1" align="center" isError={type === 'error'} theme={theme}>
      {children}
    </StyledMessage>
  );
};

export { FormMessage };
