import styled from 'styled-components';

import { Typography } from '@/shared/components/Typography';
import { Translated } from '@/services/locale';
import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

type Resource = {
  name: string;
  href: string;
  isExternal?: boolean;
};

export type Props = {
  title: string;
  resources: Resource[];
};

const StyledResourcesList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  gap: 1.6rem 3rem;
  list-style-type: none;

  @media (min-width: ${breakpoints.mobileXL}px) {
    flex-flow: column nowrap;
  }
`;

const StyledLink = styled.a`
  color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet2};
  font-family: ${({ $theme }: { $theme: Theme }) => $theme.fonts.main};
  font-size: 1.4rem;
  line-height: 2rem;
  text-decoration: none;
  text-underline-offset: 0.35em;

  &:focus,
  &:hover {
    color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
    text-decoration: underline solid ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
    text-decoration-color: ${({ $theme }: { $theme: Theme }) => $theme.colors.violet1};
  }
`;

const Resources: React.FC<Props> = ({ title, resources }) => {
  const { theme } = useTheme();

  return (
    <section>
      <Typography variant="body2" weight={600} mb={2.4}>
        {title}
      </Typography>

      <StyledResourcesList>
        {resources.map(({ name, href, isExternal }) => (
          <li key={name}>
            <StyledLink
              href={href}
              target={isExternal ? '_blank' : undefined}
              rel={isExternal ? 'noreferrer' : undefined}
              $theme={theme}
            >
              <Translated>{name}</Translated>
            </StyledLink>
          </li>
        ))}
      </StyledResourcesList>
    </section>
  );
};

export { Resources };
