import React from 'react';
import { IntlProvider } from 'react-intl';

import { appTranslations } from '@/shared/translations/app';
import { useLocale } from '@/services/locale';

export const TranslationProvider: React.FC = ({ children }) => {
  const { locale } = useLocale();
  const onError = () => {};

  return (
    <IntlProvider
      locale={locale}
      messages={appTranslations[locale]}
      onError={onError}
      textComponent={React.Fragment}
    >
      {children}
    </IntlProvider>
  );
};
