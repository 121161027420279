import React from 'react';
import styled from 'styled-components';
import { BigNumber, constants } from 'ethers';

import { NftModalItem } from './NftModalItem';

import { Modal } from '@/services/modal';
import { useTheme, Theme } from '@/services/theme';
import { NftVault, NftVaultItem } from '@/shared/types';
import { calculateNewHealthFactor, getNewHealthFactor } from '@/shared/utils/nft';
import { IconButton } from '@/shared/components/IconButton';
import { Typography } from '@/shared/components/Typography';
import { Button } from '@/shared/components/Button';
import { TrendValue } from '@/shared/components/TrendValue';
import { clampBn } from '@/shared/utils';

const Container = styled(Modal)`
  padding: 2rem 2.4rem 2.4rem;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  width: 620px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 3.6rem;
  margin-bottom: 2.4rem;
`;

const Closer = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue2};

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};
  }
`;

const Warning = styled.div`
  padding: 1.6rem 2.4rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.red1};
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.red2};
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.red1};
  border-radius: 1.6rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const Item = styled.div`
  margin-top: 2.4rem;
  display: flex;
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
  border-radius: 1.6rem;
  padding: 2.4rem;
`;

const HealthFactor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.4rem;
`;

const SubmitButton = styled(Button)`
  margin-top: 2.4rem;
  width: 100%;
`;

interface Props {
  isOpen: boolean;
  item?: NftVaultItem;
  nfts: Array<NftVaultItem>;
  vaultState: NftVault['state'];
  onClose: () => void;
  onSubmit: (nft: BigNumber) => void;
}

const NftWithdrawModalComponent = ({
  isOpen,
  onClose,
  item,
  onSubmit,
  nfts,
  vaultState,
}: Props) => {
  const { theme } = useTheme();

  const overallDebt = vaultState?.overallDebt || constants.Zero;
  const borrowLimit = vaultState?.borrowLimit || constants.Zero;
  const borrowLimitWithoutNft = clampBn(
    borrowLimit.sub(item?.nftBorrowLimit || constants.Zero),
    constants.Zero,
  );
  const nextNfts = item ? nfts.filter(({ id }) => !id.eq(item?.id)) : [];
  const isHealthy = overallDebt.lte(borrowLimitWithoutNft);

  const newHealthFactorBn = calculateNewHealthFactor(nextNfts, overallDebt);
  const newHealthFactor = getNewHealthFactor(vaultState?.healthFactor, newHealthFactorBn);

  const handleSubmit = React.useCallback(() => {
    if (item) {
      onSubmit(item.id);
    }
  }, [item, onSubmit]);

  return (
    <Container isOpen={isOpen} theme={theme}>
      <Header>
        <Typography variant="h1" textSize="lg">
          Withdraw LP
        </Typography>
        <Closer
          theme={theme}
          icon="x-cross"
          size={3.6}
          iconSize={2.4}
          aria-label="Close"
          title="Close"
          color="none"
          onClick={onClose}
        />
      </Header>
      {!isHealthy && (
        <Warning theme={theme}>
          The withdrawal of this NFT collateral is not permitted as it would trigger the liquidation
          of&nbsp;your vault. To proceed, either deposit another&nbsp;NFT as collateral or repay
          your loan.
        </Warning>
      )}
      {item && (
        <Item value={item.id} theme={theme}>
          <NftModalItem {...item} />
        </Item>
      )}
      {isHealthy && nfts.length > 1 && (
        <HealthFactor>
          <Typography textSize="md">New health factor</Typography>
          <TrendValue value={newHealthFactor.str} type={newHealthFactor.trend} />
        </HealthFactor>
      )}
      <SubmitButton variant="secondary" onClick={handleSubmit} disabled={!isHealthy}>
        Withdraw NFT
      </SubmitButton>
    </Container>
  );
};

const NftWithdrawModal = React.memo(NftWithdrawModalComponent);

export { NftWithdrawModal };
