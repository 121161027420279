import React from 'react';
import { BigNumber } from 'ethers';

import { useFillNftInfo } from './useFillNftInfo';

import { useContract } from '@/shared/utils/useContract';
import { useEthers } from '@/services/web3';
import { NftVaultItem } from '@/shared/types';
import { isNotEmpty } from '@/shared/utils/guards';

export function useFetchUniNfts(): () => Promise<Array<NftVaultItem>> {
  const { signer } = useEthers();
  const fillNftInfo = useFillNftInfo();
  const uniContract = useContract('UniV3NonfungiblePositionManager');
  const vaultContract = useContract('NftVault');

  return React.useCallback(async () => {
    const address = await signer?.getAddress();

    if (!uniContract || !vaultContract || !address) {
      return [];
    }

    const tokenNum: number = ((await uniContract.balanceOf(address)) as BigNumber).toNumber();

    const nfts: Array<NftVaultItem | undefined> = await Promise.all(
      Array.from(Array(tokenNum)).map(async (_, index) => {
        const id: BigNumber = await uniContract.tokenOfOwnerByIndex(address, index);
        return fillNftInfo(id);
      }),
    );

    return nfts.filter(isNotEmpty);
  }, [fillNftInfo, signer, uniContract, vaultContract]);
}
