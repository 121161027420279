import React, { forwardRef, useRef, useState } from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { addRem } from '@/shared/styles';
import { Typography } from '@/shared/components/Typography';
import { IconButton } from '@/shared/components/IconButton';

type Props = {
  label?: string;
  error?: boolean;
  helperText?: string;
  horizontal?: boolean;
  horizontalWidth?: number | string;
  before?: React.ReactChild;
  after?: React.ReactChild;
  clearable?: boolean;
  disabled?: boolean;
};

type StyledProps = {
  disabled?: boolean;
  error?: boolean;
  horizontal?: boolean;
  horizontalWidth?: number | string;
  theme: Theme;
};

const StyledLabel = styled.label`
  display: flex;
  flex-direction: ${({ horizontal }: StyledProps) => (horizontal ? 'row' : 'column')};
  align-items: ${({ horizontal }: StyledProps) => (horizontal ? 'center' : 'start')};
  gap: 0.8rem;
`;

const StyledInputGroup = styled.span`
  align-self: stretch;
  flex-basis: ${({ horizontal, horizontalWidth = 15.6 }: StyledProps) =>
    horizontal ? addRem(horizontalWidth) : 'auto'};
  margin-left: ${({ horizontal }: StyledProps) => (horizontal ? 'auto' : '0')};
  min-width: 0;
  z-index: 0;
`;

const StyledInputBox = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  height: 6rem;
  width: 100%;
  padding: 0 2.4rem;

  background-color: ${({ theme }: StyledProps) => theme.colors.white};
  border: 1px solid
    ${({ error, theme }: StyledProps) => (error ? theme.colors.red1 : theme.colors.naviBlue4)};
  border-radius: 1.6rem;
  cursor: text;
`;

const StyledInput = styled.input`
  flex: 1 1 0;
  background-color: transparent;
  border: none;
  color: ${({ theme }: StyledProps) => theme.colors.text.primary};
  font-size: 1.6rem;
  min-width: 0;
  outline: none;

  &:disabled {
    color: ${({ theme }: StyledProps) => theme.colors.text.secondary};
  }
`;

const StyledDivider = styled.span`
  display: inline-block;
  height: 1.8rem;
  border-left: 1px solid ${({ theme }: { theme: Theme }) => `${theme.colors.gray1}40`};
`;

const TextInput = forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement> & Props>(
  (
    {
      label,
      error,
      helperText,
      horizontal,
      horizontalWidth,
      before,
      after,
      clearable,
      className,
      disabled,
      onChange,
      ...htmlProps
    },
    ref,
  ) => {
    const { theme } = useTheme();

    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || localRef;

    const { current: inputElement } = inputRef as React.MutableRefObject<HTMLButtonElement>;

    const [showClear, setShowClear] = useState(false);

    const onClearClick = () => {
      inputElement.value = '';
      setShowClear(false);
    };

    const onInputChange = clearable
      ? (e: React.ChangeEvent<HTMLInputElement>) => {
          setShowClear(!!inputElement.value);
          if (onChange) onChange(e);
        }
      : onChange;

    return (
      <StyledLabel className={className} horizontal={horizontal}>
        {label !== undefined && (
          <Typography variant="label" color="primary" mb={horizontal && error ? 2.4 : 0}>
            {label}
          </Typography>
        )}
        <StyledInputGroup horizontal={horizontal} horizontalWidth={horizontalWidth}>
          <StyledInputBox error={error} theme={theme}>
            {before}
            <StyledInput
              ref={inputRef}
              theme={theme}
              onChange={onInputChange}
              disabled={disabled}
              {...htmlProps}
            />
            {showClear && !disabled && (
              <>
                <StyledDivider theme={theme} />
                <IconButton
                  icon="trash-can"
                  aria-label="Clear input"
                  color={theme.colors.gray1}
                  size={2.4}
                  iconSize={2.4}
                  onClick={onClearClick}
                />
              </>
            )}
            {after}
          </StyledInputBox>
          {error && helperText && (
            <Typography variant="body2" color="error" mt={0.8}>
              {helperText}
            </Typography>
          )}
        </StyledInputGroup>
      </StyledLabel>
    );
  },
);

export { TextInput };
