import styled from 'styled-components';

import { WalletConnector } from '../WalletConnector';
import { WalletTitle } from './WalletTitle';

import { Modal, useModal } from '@/services/modal';
import { useEthers } from '@/services/web3';
import { shortenAddress } from '@/shared/utils';
import { Typography } from '@/shared/components/Typography';
import { checkSignature } from '@/api';
import { useTheme, Theme } from '@/services/theme';
import { Button } from '@/shared/components/Button';
import { Menu } from '@/shared/components/Menu';
import { SpriteIcon } from '@/shared/components/SpriteIcon';

type Props = {
  className?: string;
};

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.6rem;
  justify-content: flex-end;
  min-width: 13rem;
`;

const StyledModal = styled(Modal)`
  width: calc(100% - 1.6rem);
  max-width: 64rem;
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  border-radius: 1.8rem;
  cursor: pointer;
  gap: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 0.5rem 1rem;

  &:hover {
    border-color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
    color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};
  }
`;

const StyledIndicator = styled(SpriteIcon)`
  transition: transform 250ms;
  transform: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'rotate(-180deg)' : 'none')};
`;

const StyledMenuTitle = styled.header`
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
`;

const WalletMenu: React.VFC<Props> = ({ className = '' }) => {
  const { theme } = useTheme();
  const modalKey = 'wallet-connector';
  const { activeModal, setActiveModal } = useModal();
  const isModalOpen = activeModal === modalKey;

  const { userAddress, etherscanUrl, provider, disconnectWallet } = useEthers();
  const isMetaMask = provider?.provider.isMetaMask;
  const title = shortenAddress(userAddress);

  const accountURL = `${etherscanUrl}/address/${userAddress}`;

  const viewOnEtherscan = () => window.open(accountURL, '_blank');

  const showModal = () => setActiveModal(modalKey);

  // const handleModalClose = async () => {
  //   if (!userAddress) return;

  //   const isSigned = await checkSignature(userAddress);
  //   if (!isSigned) disconnectWallet();
  // };

  return (
    <>
      <StyledContainer className={className}>
        {(!userAddress || isModalOpen) && (
          <Button size="sm" onClick={showModal}>
            Connect wallet
          </Button>
        )}
        {userAddress && !isModalOpen && (
          <Menu
            header={
              <StyledMenuTitle theme={theme}>
                <Typography textSize="sm" color="secondary" mb={2.4}>
                  Wallet
                </Typography>
                <WalletTitle address={userAddress} icon={isMetaMask ? 'metamask' : undefined} />
              </StyledMenuTitle>
            }
            title="Wallet"
            indicatorBorder
            animateIndicator
            options={[
              { label: 'View on explorer', action: viewOnEtherscan },
              { label: 'Disconnect wallet', action: disconnectWallet },
            ]}
            renderButton={(onClick, isOpen) => (
              <StyledButton onClick={onClick} theme={theme}>
                {isMetaMask && <SpriteIcon icon="metamask" size={1.8} />}
                {title}
                <StyledIndicator
                  icon="chevron-down"
                  size={1.6}
                  color="none"
                  isOpen={isOpen}
                  theme={theme}
                />
              </StyledButton>
            )}
          />
        )}
      </StyledContainer>

      <StyledModal isOpen={isModalOpen} /* onClose={handleModalClose} */>
        <WalletConnector />
      </StyledModal>
    </>
  );
};

export { WalletMenu };
