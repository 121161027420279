import { forwardRef, useState } from 'react';
import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';

type Props = {
  error?: boolean;
  helperText?: string;
};

const StyledLabel = styled.label`
  display: block;
`;

const StyledTextArea = styled.textarea`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background.input};
  border: 2px solid
    ${({ error, theme }: { error: boolean; theme: Theme }) =>
      error ? theme.colors.error : 'transparent'};
  border-radius: 0.4rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  font-family: ${({ theme }: { theme: Theme }) => theme.fonts.main};
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 1.2rem;
  resize: none;
  width: 100%;
  height: ${({ rowCount }: { rowCount: number }) => rowCount * 1.4 * 1.4 + 2.4}rem;
  min-height: 6.4rem;
`;

const TextArea = forwardRef<HTMLTextAreaElement, React.HTMLProps<HTMLTextAreaElement> & Props>(
  ({ error, helperText, label, placeholder, onChange, ...props }, ref) => {
    const { theme } = useTheme();
    const [rowCount, setRowCount] = useState(1);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setRowCount(e.currentTarget.value.split(/\r\n|\r|\n/).length);
      if (typeof onChange === 'function') onChange(e);
    };

    return (
      <StyledLabel>
        <Typography variant="label" mb={1}>
          {label}
        </Typography>
        <StyledTextArea
          error={error}
          rowCount={rowCount}
          onChange={handleChange}
          placeholder={placeholder}
          ref={ref}
          theme={theme}
          {...props}
        />
        {error && (
          <Typography variant="body2" color="error" mt={0.8} ml={1.2}>
            {helperText}
          </Typography>
        )}
      </StyledLabel>
    );
  },
);

export { TextArea };
