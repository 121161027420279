import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

type Props = React.HTMLProps<HTMLElement | HTMLFormElement> & {
  className?: string;
  component?: 'div' | 'form' | 'section' | 'article';
};

const StyledContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 0;
  gap: 3.2rem;
  max-width: calc(100vw - 3.2rem);
  order: 3;

  @media (min-width: ${breakpoints.tabletXL}px) {
    max-width: 736px;
  }
`;

const PageContent: React.FC<Props> = ({ children, className, component = 'div' }) => (
  <StyledContent as={component} className={className}>
    {children}
  </StyledContent>
);

export { PageContent };
