import styled from 'styled-components';

import { Resources } from './components/Resources';
import { resourcesList } from './fixtures';
import { Typography } from '../Typography';
import { PageContainer } from '../PageContainer';

import { useTheme, Theme } from '@/services/theme';
import { breakpoints } from '@/shared/styles';

const StyledFooter = styled.footer`
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.primary};
  font-size: 1.4rem;
  margin-top: auto;
`;

const StyledPageContainer = styled(PageContainer)`
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

const StyledContent = styled.div`
  display: grid;
  grid-template: repeat(4, auto) / 100%;
  gap: 5.6rem 3.2rem;

  @media (min-width: ${breakpoints.mobileXL}px) {
    grid-template: auto auto / repeat(3, 1fr);
    row-gap: 6.4rem;
  }

  @media (min-width: ${breakpoints.tabletXL}px) {
    grid-template: 1fr / repeat(5, 1fr);
  }
`;

const StyledInfo = styled.div`
  grid-area: 4/1/5/2;

  @media (min-width: ${breakpoints.mobileXL}px) {
    grid-area: 2/1/3/4;
  }

  @media (min-width: ${breakpoints.tabletXL}px) {
    grid-area: 1/1/2/3;
  }
`;

const Footer = () => {
  const { theme } = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter theme={theme}>
      <StyledPageContainer>
        <StyledContent>
          <StyledInfo>
            <Typography variant="body2" weight={600} mb={2.4}>
              BOB CDP
            </Typography>
            <Typography variant="body2">© {currentYear} zkBob</Typography>
          </StyledInfo>

          {resourcesList.map(item => (
            <Resources key={item.title} {...item} />
          ))}
        </StyledContent>
      </StyledPageContainer>
    </StyledFooter>
  );
};

export { Footer };
