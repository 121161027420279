import React from 'react';
import styled from 'styled-components';
import { isAddress } from 'ethers/lib/utils';

import { SpriteIcon } from '../SpriteIcon';

import { useTheme, Theme } from '@/services/theme';
import { shortenAddress } from '@/shared/utils/format';

const Link = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.violet2};

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colors.violet1};
    text-decoration: underline;
  }
`;

const Icon = styled(SpriteIcon)`
  color: currentColor;
`;

interface Props {
  className?: string;
  url: string;
  text: string;
}

const ExternalAddressComponent = ({ className, url, text }: Props) => {
  const { theme } = useTheme();
  const handleClick = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  return (
    <Link className={className} href={url} target="_blank" theme={theme} onClick={handleClick}>
      {isAddress(text) ? shortenAddress(text) : text}
      <Icon icon="external-link" color="none" size={1.8} theme={theme} />
    </Link>
  );
};

const ExternalAddress = ExternalAddressComponent;

export { ExternalAddress };
