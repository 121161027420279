export const wallets = [
  {
    displayedName: 'Metamask',
    name: 'metamask',
  },
  {
    displayedName: 'Fortmatic',
    name: 'fortmatic',
  },
  {
    displayedName: 'WalletConnect',
    name: 'walletconnect',
  },
  // {
  //   displayedName: 'Portis',
  //   name: 'portis',
  // },
  {
    displayedName: 'Coinbase Wallet',
    name: 'walletlink',
  },
  // {
  //   displayedName: 'Ledger',
  //   name: 'ledger',
  // },
];
