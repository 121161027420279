import styled from 'styled-components';
import { BigNumber, utils, constants } from 'ethers';

import { NftVaultStateItem } from '../NftVaultStateItem';

import { clampBn, localizeNumber } from '@/shared/utils/format';
import { MIN_MINT_BOB_AMOUNT } from '@/shared/utils/consts';
import { getHealthFactorString, deriveHealthFactorProgress } from '@/shared/utils/nft';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 1.6rem;
`;

interface Props {
  id: string;
  className?: string;
  healthFactor?: number | BigNumber;
  healthFactorProgress?: number;
  collateral?: BigNumber;
  borrowLimit?: BigNumber;
  liquidationLimit?: BigNumber;
  overallDebt?: BigNumber;
  minted?: BigNumber;
  isLoading?: boolean;
  isMinting?: boolean;
}

const NftVaultState = ({
  id,
  className,
  healthFactor,
  borrowLimit,
  liquidationLimit,
  minted,
  overallDebt,
  isLoading,
  isMinting,
}: Props) => {
  let displayedBorrowLimit = borrowLimit;

  if (borrowLimit !== undefined && !borrowLimit.isZero()) {
    const currentDebt = clampBn(overallDebt || constants.Zero, constants.Zero);
    const debtGap = currentDebt.div(10000);
    displayedBorrowLimit = clampBn(
      borrowLimit.sub(!debtGap.isZero() ? debtGap : MIN_MINT_BOB_AMOUNT),
      constants.Zero,
    );
  }

  const items = [
    {
      title: 'Health factor',
      value: (() => {
        if (!healthFactor) {
          return '-';
        }

        return getHealthFactorString(healthFactor);
      })(),
      progress: deriveHealthFactorProgress(healthFactor),
      healthFactor: true,
    },
    {
      title: 'Borrow limit',
      value:
        displayedBorrowLimit !== undefined && !displayedBorrowLimit.isZero()
          ? `${localizeNumber(Number(utils.formatUnits(displayedBorrowLimit, 18)), '0', 0)} USD`
          : '-',
      hint: `Maximum amount of BOB (shown in USD) you can borrow based on your locked collateral.`,
    },
    {
      title: 'Minted BOB',
      value:
        minted !== undefined && !minted.isZero()
          ? `${localizeNumber(Number(utils.formatUnits(minted, 18)), '0', 0)} BOB`
          : '-',
      isUpdating: isMinting,
      hint: `The number of BOB tokens you’ve minted from locked collateral`,
    },
  ];

  return (
    <Container className={className}>
      {items.map(item => (
        <NftVaultStateItem
          {...item}
          id={id}
          key={item.title}
          isLoading={isLoading}
          borrowLimit={borrowLimit}
          liquidationLimit={liquidationLimit}
        />
      ))}
    </Container>
  );
};

export { NftVaultState };
