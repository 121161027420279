import ky from 'ky';

import { BASE_URL } from './constants';

export const sendSignature = async (addr: string, signature: string) => {
  const url = `${BASE_URL}/signatures/${addr.slice(2)}`;

  try {
    const response = await ky.post(url, { json: { signature } });
    return response.ok;
  } catch {
    return false;
  }
};
