import React from 'react';
import { BigNumber, constants, ethers } from 'ethers';

import { DENOMINATOR, Q48, Q96, Q128 } from '@/shared/utils/consts';
import { createContract, useContract } from '@/shared/utils/useContract';
import { useEthers } from '@/services/web3';
import { NftVaultItem } from '@/shared/types';
import erc20abi from '@/api/abi/erc20-abi.json';
import uniPoolAbi from '@/api/abi/uni-v3-pool-abi.json';

const univ3prices = require('@thanpolas/univ3prices');

const ONE = ethers.BigNumber.from(1);
const TWO = ethers.BigNumber.from(2);

function sqrt(x: BigNumber) {
  let z = x.add(ONE).div(TWO);
  let y = x;
  while (z.sub(y).isNegative()) {
    y = z;
    z = x.div(z).add(z).div(TWO);
  }
  return y;
}

async function getFeeGrowthInside(
  poolContract: ethers.Contract,
  tickLower: number,
  tickUpper: number,
  tickCurrent: number,
  feeGrowthGlobal0X128: BigNumber,
  feeGrowthGlobal1X128: BigNumber,
) {
  const [, , lowerFeeGrowthOutside0X128, lowerFeeGrowthOutside1X128] = await poolContract?.ticks(
    tickLower,
  );
  const [, , upperFeeGrowthOutside0X128, upperFeeGrowthOutside1X128] = await poolContract?.ticks(
    tickUpper,
  );

  // calculate fee growth below
  let feeGrowthBelow0X128: BigNumber = constants.Zero;
  let feeGrowthBelow1X128: BigNumber = constants.Zero;
  if (tickCurrent >= tickLower) {
    feeGrowthBelow0X128 = lowerFeeGrowthOutside0X128;
    feeGrowthBelow1X128 = lowerFeeGrowthOutside1X128;
  } else {
    feeGrowthBelow0X128 = feeGrowthGlobal0X128.sub(lowerFeeGrowthOutside0X128);
    feeGrowthBelow1X128 = feeGrowthGlobal1X128.sub(lowerFeeGrowthOutside1X128);
  }

  // calculate fee growth above
  let feeGrowthAbove0X128: BigNumber = constants.Zero;
  let feeGrowthAbove1X128: BigNumber = constants.Zero;
  if (tickCurrent < tickUpper) {
    feeGrowthAbove0X128 = upperFeeGrowthOutside0X128;
    feeGrowthAbove1X128 = upperFeeGrowthOutside1X128;
  } else {
    feeGrowthAbove0X128 = feeGrowthGlobal0X128.sub(upperFeeGrowthOutside0X128);
    feeGrowthAbove1X128 = feeGrowthGlobal1X128.sub(upperFeeGrowthOutside1X128);
  }

  return [
    feeGrowthGlobal0X128.sub(feeGrowthBelow0X128).sub(feeGrowthAbove0X128),
    feeGrowthGlobal1X128.sub(feeGrowthBelow1X128).sub(feeGrowthAbove1X128),
  ];
}

async function calculateAccumulatedFees(position: any, poolContract: ethers.Contract) {
  const { liquidity, tickLower, tickUpper, feeGrowthInside0LastX128, feeGrowthInside1LastX128 } =
    position;
  if (liquidity.isZero()) {
    return [position.tokensOwed0, position.tokensOwed1];
  }

  const feeGrowthGlobal0X128 = await poolContract.feeGrowthGlobal0X128();
  const feeGrowthGlobal1X128 = await poolContract.feeGrowthGlobal1X128();
  const { tick } = await poolContract?.slot0();
  let [feeGrowthInside0X128, feeGrowthInside1X128] = await getFeeGrowthInside(
    poolContract,
    tickLower,
    tickUpper,
    tick,
    feeGrowthGlobal0X128,
    feeGrowthGlobal1X128,
  );
  if (feeGrowthInside0X128.lt(0)) {
    feeGrowthInside0X128 = feeGrowthInside1X128.add(constants.MaxUint256).add(1);
  }
  if (feeGrowthInside1X128.lt(0)) {
    feeGrowthInside1X128 = feeGrowthInside1X128.add(constants.MaxUint256).add(1);
  }

  const feeGrowthInside0DeltaX128 = feeGrowthInside0X128.sub(feeGrowthInside0LastX128);
  const feeGrowthInside1DeltaX128 = feeGrowthInside1X128.sub(feeGrowthInside1LastX128);
  const tokensOwed0 = feeGrowthInside0DeltaX128.mul(liquidity).div(Q128);
  const tokensOwed1 = feeGrowthInside1DeltaX128.mul(liquidity).div(Q128);

  return [tokensOwed0, tokensOwed1];
}

async function getTokensAmount(position: any, sqrtRatioX96: BigNumber) {
  const tickLower = univ3prices.tickMath.getSqrtRatioAtTick(position.tickLower);
  const tickUpper = univ3prices.tickMath.getSqrtRatioAtTick(position.tickUpper);

  const [amount0, amount1] = await univ3prices.getAmountsForLiquidityRange(
    sqrtRatioX96.toString(),
    tickLower,
    tickUpper,
    position.liquidity.toString(),
  );

  return [BigNumber.from(amount0.toString()), BigNumber.from(amount1.toString())];
}

async function fetchPrices(
  oracleContract: ethers.Contract,
  token0: string,
  token1: string,
  token0Decimals: number,
  token1Decimals: number,
) {
  const denominator0: BigNumber = BigNumber.from(10).pow(18 - token0Decimals);
  const denominator1: BigNumber = BigNumber.from(10).pow(18 - token1Decimals);
  const price0x96: BigNumber = (await oracleContract?.price(token0))[1] || constants.Zero;
  const price1x96: BigNumber = (await oracleContract?.price(token1))[1] || constants.Zero;

  const price0 = price0x96.div(denominator0).mul(10000).div(Q96).toNumber() / 10000;
  const price1 = price1x96.div(denominator1).mul(10000).div(Q96).toNumber() / 10000;

  return { price0, price1, price0x96, price1x96 };
}

export function useFillNftInfo(): (id: BigNumber) => Promise<NftVaultItem | undefined> {
  const { provider } = useEthers();
  const uniPositionMangerContract = useContract('UniV3NonfungiblePositionManager');
  const uniFactoryContract = useContract('UniV3Factory');
  const oracleContract = useContract('ChainlinkOracle');
  const vaultContract = useContract('NftVault');
  const oracleUniContact = useContract('UniV3Oracle');

  return React.useCallback(
    async (id: BigNumber) => {
      if (!uniPositionMangerContract || !oracleContract || !vaultContract || !uniFactoryContract) {
        return;
      }

      const position = await uniPositionMangerContract.positions(id);

      const token0Contract = createContract('Erc20Abi', position.token0, erc20abi, provider);
      const token1Contract = createContract('Erc20Abi', position.token1, erc20abi, provider);
      const token0Symbol: string = await token0Contract.symbol();
      const token1Symbol: string = await token1Contract.symbol();
      const token0Decimals: number = await token0Contract.decimals();
      const token1Decimals: number = await token1Contract.decimals();

      const poolAddress = await uniFactoryContract.getPool(
        position.token0,
        position.token1,
        position.fee,
      );
      const poolContract = new ethers.Contract(poolAddress, uniPoolAbi, provider);

      const [token0Owed, token1Owed] = await calculateAccumulatedFees(position, poolContract);
      const { price0, price1, price0x96, price1x96 } = await fetchPrices(
        oracleContract,
        position.token0,
        position.token1,
        token0Decimals,
        token1Decimals,
      );

      const ratioX96 = price0x96.mul(Q96).div(price1x96.isZero() ? constants.One : price1x96);
      const sqrtRatioX96 = sqrt(ratioX96).mul(Q48);

      const [token0Amount, token1Amount] = await getTokensAmount(position, sqrtRatioX96);

      const usdValue = token0Amount
        .add(token0Owed)
        .mul(price0x96)
        .add(token1Amount.add(token1Owed).mul(price1x96))
        .div(Q96);

      const poolParams = await vaultContract?.poolParams(poolAddress).then(r => ({
        liquidationThreshold: BigNumber.from(r.liquidationThreshold),
        borrowThreshold: BigNumber.from(r.borrowThreshold),
        minWidth: BigNumber.from(r.minWidth),
      }));

      const approvedAddress: string = await uniPositionMangerContract.getApproved(id);
      const status =
        approvedAddress.toLowerCase() === vaultContract?.address.toLowerCase()
          ? 'approved'
          : 'idle';

      let tokenPrice;
      try {
        if (oracleUniContact) {
          const tx = await oracleUniContact.price(id);
          tokenPrice = tx.positionAmount;
        }
      } catch {}

      const nftBorrowLimit = tokenPrice?.mul(poolParams.borrowThreshold).div(DENOMINATOR);

      const { minSingleNftCollateral } = await vaultContract.protocolParams();
      const positionWidth = position.tickUpper - position.tickLower;
      const isSupported =
        usdValue.gte(minSingleNftCollateral) &&
        poolParams.minWidth.lte(positionWidth) &&
        !poolParams.borrowThreshold.isZero();

      // eslint-disable-next-line consistent-return
      return {
        id,
        pair: [
          {
            symbol: token0Symbol,
            decimals: token0Decimals,
            value: token0Amount,
            price: price0,
          },
          {
            symbol: token1Symbol,
            decimals: token1Decimals,
            value: token1Amount,
            price: price1,
          },
        ],
        usdValue,
        collateral: usdValue,
        fee: position.fee as number,
        status,
        liquidationThreshold: poolParams.liquidationThreshold,
        nftBorrowLimit,
        isSupported,
      };
    },
    [
      vaultContract,
      oracleContract,
      oracleUniContact,
      provider,
      uniFactoryContract,
      uniPositionMangerContract,
    ],
  );
}
