import styled from 'styled-components';
import { BigNumber } from 'ethers';

import { useTheme, Theme } from '@/services/theme';
import { ProgressCondition } from '@/shared/types';
import { Typography } from '@/shared/components/Typography';
import { Tooltip } from '@/shared/components/Tooltip';
import { ProgressBar } from '@/shared/components/ProgressBar';
import { Skeleton } from '@/shared/components/Skeleton';
import { Spinner } from '@/shared/components/Spinner';
import { HealthFactorInfo } from '@/features/nft';

const Container = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.naviBlue5};
  border-radius: 0.8rem;
  padding: 1rem 1.6rem;
  position: relative;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.4rem;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: auto;
`;

const ValueRow = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${({ isInfinite }: { isInfinite: boolean }) => (isInfinite ? '2.4rem' : '1.6rem')};
`;

const StyledSkeleton = styled(Skeleton)`
  margin-top: 0.8rem;
`;

const Bar = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: ${({ value }: { value: number }) => (value < 100 ? '0' : '0.8rem')};
`;

interface Props {
  id: string;
  className?: string;
  title: string;
  value: string;
  progress?: {
    percentage: number;
    condition: ProgressCondition;
  };
  hint?: string;
  healthFactor?: boolean;
  isLoading?: boolean;
  isUpdating?: boolean;
  borrowLimit?: BigNumber;
  liquidationLimit?: BigNumber;
}

const NftVaultStateItem = ({
  id,
  className,
  title,
  value,
  progress,
  hint,
  healthFactor,
  isLoading,
  isUpdating,
  borrowLimit,
  liquidationLimit,
}: Props) => {
  const { theme } = useTheme();

  const rightTopSpot = (() => {
    if (isUpdating) {
      return <StyledSpinner size="1.6rem" />;
    }

    if (hint) {
      return <Tooltip name={title + id} innerText={hint} />;
    }

    if (healthFactor) {
      return (
        <HealthFactorInfo id={id} borrowLimit={borrowLimit} liquidationLimit={liquidationLimit} />
      );
    }

    return null;
  })();

  return (
    <Container className={className} theme={theme}>
      <TitleRow>
        <Typography textSize="sm">{title}</Typography>
        {rightTopSpot}
      </TitleRow>
      <ValueRow
        textSize="md"
        weight={600}
        color="primary"
        component="div"
        isInfinite={value === '∞'}
      >
        {isLoading ? <StyledSkeleton w={52} h={16} /> : value}
      </ValueRow>
      {!!progress?.percentage && (
        <Bar percentage={progress.percentage} condition={progress.condition} />
      )}
    </Container>
  );
};

export { NftVaultStateItem };
