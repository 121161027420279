import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { BigNumber, constants, utils } from 'ethers';

import { MintRepayForm } from './components/MintRepayForm';

import { NftList, NftVaultPageError, NftVaultCard, useFillNftInfo } from '@/features/nft';
import { useContract } from '@/shared/utils/useContract';
import { NftVault, NftVaultItem, ContractsMap, ProtocolParams } from '@/shared/types';
import { useEthers } from '@/services/web3';
import { fillInVault } from '@/shared/utils/nft';
import { isNotEmpty } from '@/shared/utils/guards';
import { useOpenSnackbar } from '@/features/Snackbar';
import { getErrorMessage } from '@/shared/utils/errors';
import { PageContainer } from '@/shared/components/PageContainer';
import { PageTitle } from '@/shared/components/PageTitle';
import { PageContent } from '@/shared/components/PageContent';
import { PageAside } from '@/shared/components/PageAside';
import { calcGasLimit } from '@/shared/utils/gas';

const Container = styled(PageContainer)`
  flex-direction: column;
`;

const Title = styled(PageTitle)`
  order: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  column-gap: 3.2rem;
`;

const NftVaultPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const vaultContract = useContract('NftVault');
  const vaultRegistryContact = useContract('NftVaultRegistry');
  const uniPositionManagerContract = useContract('UniV3NonfungiblePositionManager');
  const { signer, userAddress } = useEthers();
  const fillNftInfo = useFillNftInfo();
  const [openSnackBar] = useOpenSnackbar();

  const [vault, setVault] = React.useState<NftVault | null>(null);
  const [nfts, setNfts] = React.useState<Array<NftVaultItem>>([]);
  const [owner, setOwner] = React.useState('');
  const [isLoading, setLoading] = React.useState(true);
  const [isMinting, setMinting] = React.useState(false);
  const [protocolParams, setProtocolParams] = React.useState<ProtocolParams | null>(null);

  React.useEffect(() => {
    if (!vaultContract) return;

    vaultContract.protocolParams().then(setProtocolParams);
  }, [vaultContract]);

  const updateVault = React.useCallback(
    async (
      vaultContract: ContractsMap['NftVault'],
      vaultRegistryContact: ContractsMap['NftVaultRegistry'],
      id: string,
    ) => {
      if (!protocolParams) return;

      const owner = await vaultRegistryContact.ownerOf(id);
      const vaultNftIds: Array<BigNumber> = await vaultContract.vaultNftsById(id);
      const vaultNfts = (await Promise.all(vaultNftIds.slice().reverse().map(fillNftInfo))).filter(
        isNotEmpty,
      );

      const filledVault = await fillInVault(vaultContract, id, vaultNfts, protocolParams);
      setOwner(owner);
      setVault(filledVault);
      setNfts(vaultNfts);
    },
    [fillNftInfo, protocolParams],
  );
  React.useEffect(() => {
    (async () => {
      try {
        if (vaultContract && vaultRegistryContact && userAddress && id && protocolParams) {
          await updateVault(vaultContract, vaultRegistryContact, id);
          setLoading(false);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        openSnackBar({ type: 'error', text: message });
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillNftInfo, id, signer, vaultContract, vaultRegistryContact, protocolParams]);

  const depositNft = React.useCallback(
    async (nftId: BigNumber) => {
      if (uniPositionManagerContract && vaultContract && vault?.id && userAddress) {
        const vaultIdHex = BigNumber.from(vault.id).toHexString();
        const bytes32VaultId = utils.hexZeroPad(vaultIdHex, 32);
        const estimatedGas = await uniPositionManagerContract.estimateGas[
          'safeTransferFrom(address,address,uint256,bytes)'
        ](userAddress, vaultContract.address, nftId, bytes32VaultId);
        const tx = await uniPositionManagerContract[
          'safeTransferFrom(address,address,uint256,bytes)'
        ](userAddress, vaultContract.address, nftId, bytes32VaultId, {
          gasLimit: calcGasLimit(estimatedGas),
        });
        return tx.wait();
      }
      return null;
    },
    [vault?.id, uniPositionManagerContract, vaultContract, userAddress],
  );

  const handleNavigateBack = React.useCallback(() => {
    navigate('/nft-vaults');
  }, [navigate]);

  const handleNftDeposit = React.useCallback(
    async (selectedNfts: Array<NftVaultItem>) => {
      try {
        if (!vaultContract || !id) {
          throw new Error('Cannot obtain smart-contract instance');
        }

        if (!protocolParams) return;

        const depositingNfts: Array<NftVaultItem> = selectedNfts.map(nft => ({
          ...nft,
          status: 'depositing',
        }));
        setNfts(depositingNfts.concat(nfts));

        // eslint-disable-next-line no-restricted-syntax
        for await (const selectedNft of selectedNfts) {
          await depositNft(selectedNft.id);
        }

        const updatedVaultNfts = selectedNfts.concat(nfts);
        setNfts(updatedVaultNfts);
        const filledVault = await fillInVault(vaultContract, id, updatedVaultNfts, protocolParams);
        setVault(filledVault);
      } catch (error) {
        const message = getErrorMessage(error);
        openSnackBar({ type: 'error', text: message });
        setNfts(nfts);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vaultContract, id, nfts, depositNft],
  );

  const handleNftWithdraw = React.useCallback(
    async (selectedNft: BigNumber) => {
      try {
        if (!vaultContract || !id) {
          throw new Error('Cannot obtain smart-contract instance');
        }

        if (!protocolParams) return;

        const updatedNfts: Array<NftVaultItem> = nfts.map(nft => {
          if (nft.id.eq(selectedNft)) {
            return { ...nft, status: 'withdrawing' };
          }
          return nft;
        });
        setNfts(updatedNfts);

        const estimatedGas = await vaultContract.estimateGas.withdrawCollateral(selectedNft);
        const tx = await vaultContract.withdrawCollateral(selectedNft, {
          gasLimit: calcGasLimit(estimatedGas),
        });
        await tx.wait();

        const updatedVaultNfts = nfts.filter(({ id }) => !id.eq(selectedNft));
        setNfts(updatedVaultNfts);
        const filledVault = await fillInVault(vaultContract, id, updatedVaultNfts, protocolParams);
        setVault(filledVault);
      } catch (error) {
        const message = getErrorMessage(error);
        openSnackBar({ type: 'error', text: message });
        setNfts(nfts);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, nfts, vaultContract],
  );

  const handleMint = React.useCallback((isMinting: boolean) => setMinting(isMinting), []);

  const handleClaim = React.useCallback(async () => {
    if (
      !vaultContract ||
      !vaultRegistryContact ||
      !userAddress ||
      !vault?.unclaimed ||
      !vault?.id
    ) {
      throw new Error('Cannot obtain smart-contract instance');
    }

    try {
      const estimatedGas = await vaultContract.estimateGas.withdrawOwed(
        vault.id,
        userAddress,
        vault.unclaimed,
      );
      const tx = await vaultContract.withdrawOwed(vault.id, userAddress, vault.unclaimed, {
        gasLimit: calcGasLimit(estimatedGas),
      });
      await tx.wait();
      await updateVault(vaultContract, vaultRegistryContact, vault.id);
    } catch (error) {
      const message = getErrorMessage(error);
      openSnackBar({ type: 'error', text: message });
    }
  }, [vaultContract, vaultRegistryContact, userAddress, vault, updateVault, openSnackBar]);

  if (!isLoading && vault === null) {
    return (
      <Container>
        <Title showButtonBack size={3.2} onBackClick={handleNavigateBack}>
          Vault
        </Title>
        <NftVaultPageError onGoBackClick={handleNavigateBack} />
      </Container>
    );
  }

  const isReadOnly = owner.toLowerCase() !== userAddress?.toLowerCase();
  return (
    <Container>
      <Title showButtonBack size={3.2} onBackClick={handleNavigateBack}>
        Vault {vault?.id || id}
      </Title>
      <ContentWrapper>
        <PageContent>
          <NftVaultCard
            title="Info"
            {...vault}
            isMinting={isMinting}
            id={undefined}
            isLoading={isLoading}
            isVaultPage
            onClaim={handleClaim}
            isReadOnly={isReadOnly}
          />
          <NftList
            items={nfts}
            maxNfts={protocolParams?.maxNftsPerVault || 0}
            onDeposit={handleNftDeposit}
            onWithdraw={handleNftWithdraw}
            isLoading={isLoading}
            vaultState={vault?.state}
            isReadOnly={isReadOnly}
          />
        </PageContent>
        <PageAside>
          <MintRepayForm
            vaultId={vault?.id}
            onMint={handleMint}
            onVaultUpdate={updateVault}
            isLoading={isLoading}
            isMinting={isMinting}
            vaultState={vault?.state}
            nfts={nfts}
            maxDebt={protocolParams?.maxDebtPerVault || constants.Zero}
            isReadOnly={isReadOnly}
          />
        </PageAside>
      </ContentWrapper>
    </Container>
  );
};

export { NftVaultPage };
