import enUS from './en-US.json';
import ruRU from './ru-RU.json';

import { Locale } from '@/shared/types';

type Translations = {
  [LocaleKey in Locale]: {
    [message: string]: string;
  };
};

export const appTranslations: Translations = {
  'en-US': enUS,
  'ru-RU': ruRU,
};
