import styled from 'styled-components';

import { QuestionIcon } from '@/shared/components/QuestionIcon';
import { Tooltip } from '@/shared/components/Tooltip';
import { Typography } from '@/shared/components/Typography';
import { useTheme, Theme } from '@/services/theme';

export type Tile = {
  label: string;
  value: JSX.Element;
  tooltip?: string;
};

type Props = {
  data: Tile[];
  dense?: boolean;
};

const StyledRoot = styled.div`
  border: ${({ theme }: { theme: Theme }) => `1px dashed ${theme.colors.border.gray2}`};
  border-radius: 0.6rem;
  color: ${({ theme }: { theme: Theme }) => theme.colors.text.secondary};
  display: flex;
  flex-flow: column nowrap;
  gap: 1.2rem;
  padding: 1.6rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: ${({ dense }: { dense: boolean }) => (dense ? '0.7rem' : '1rem')};
`;

const StyledCell = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 0;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
`;

const StyledTitle = styled(Typography)`
  flex-grow: 1;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`;

const TileRow: React.FC<Props> = ({ data, dense = false }) => {
  const { theme } = useTheme();

  return (
    <StyledRoot theme={theme}>
      {data.map(({ label, value, tooltip }) => (
        <StyledRow key={label} dense={dense}>
          <StyledCell itemCount={data.length} theme={theme}>
            <>
              <StyledTitle>
                {label}
                {tooltip && (
                  <StyledTooltip name={label} iconElement={<QuestionIcon />} innerText={tooltip} />
                )}
              </StyledTitle>
              {value}
            </>
          </StyledCell>
        </StyledRow>
      ))}
    </StyledRoot>
  );
};

export { TileRow };
