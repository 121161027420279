import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { ProgressCondition } from '@/shared/types';

interface Props {
  className?: string;
  percentage: number;
  condition: ProgressCondition;
}

const Bar = styled.div`
  height: 0.4rem;
  width: ${({ percentage }: Props) => Math.min(percentage, 100)}%;
  background-color: ${({ bgColor }: { bgColor: string }) => bgColor};
  position: relative;

  &::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 0.1rem;
    height: 0.7rem;
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.black};
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='9' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0 0 4.5 4L9 0H0Z' fill='%23000'/%3E%3C/svg%3E");
    position: absolute;
    top: -1rem;
    right: -0.4rem;
  }
`;

const ProgressBar = ({ className, percentage, condition }: Props) => {
  const { theme } = useTheme();

  const statusColor: Record<ProgressCondition, string> = {
    warn: theme.colors.orange1,
    good: theme.colors.green1,
    critical: theme.colors.red1,
  };

  return (
    <Bar
      percentage={percentage}
      className={className}
      theme={theme}
      bgColor={statusColor[condition]}
    />
  );
};

export { ProgressBar };
