import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import dotenv from 'dotenv';
import SnackbarProvider from 'react-simple-snackbar/dist';

import '@/core/index.css';
import { App } from '@/core/App';
import { ModalProvider } from '@/services/modal';
import { ThemeProvider } from '@/services/theme';
import { EthersProvider } from '@/services/web3';
import { TranslationProvider } from '@/services/locale';
import { AppConfigProvider } from '@/core/AppConfig';

dotenv.config();

ReactDOM.render(
  <React.StrictMode>
    <AppConfigProvider>
      <EthersProvider>
        <ThemeProvider>
          <ModalProvider>
            <TranslationProvider>
              <SnackbarProvider>
                <Router>
                  <App />
                </Router>
              </SnackbarProvider>
            </TranslationProvider>
          </ModalProvider>
        </ThemeProvider>
      </EthersProvider>
    </AppConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
