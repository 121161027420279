import { createContext, useState, useContext } from 'react';

import { Locale } from '@/shared/types';

type SessionConfig = {
  appError: string;
  showBanner: boolean;
};

type PersistentConfig = {
  cachedProvider: string;
  locale: Locale;
};

type AppConfig = SessionConfig & PersistentConfig;

type AppConfigContextType = AppConfig & { updateAppConfig: (c: Partial<AppConfig>) => void };

const initialSessionConfig: SessionConfig = {
  appError: '',
  showBanner: true,
};

const initialPersistentConfig: PersistentConfig = {
  cachedProvider: '',
  locale: 'en-US',
};

const initialConfig: AppConfig = {
  ...initialPersistentConfig,
  ...initialSessionConfig,
};

const storageKey = 'mellow:view-config';

const getStoredConfig = (): AppConfig => {
  const sessionConfigString = sessionStorage.getItem(storageKey);
  const sessionConfig: SessionConfig = sessionConfigString
    ? { ...initialSessionConfig, ...JSON.parse(sessionConfigString) }
    : initialSessionConfig;
  const persistentConfigString = localStorage.getItem(storageKey);
  const persistentConfig: PersistentConfig = persistentConfigString
    ? { ...initialPersistentConfig, ...JSON.parse(persistentConfigString) }
    : initialPersistentConfig;

  return { ...sessionConfig, ...persistentConfig };
};

const storeConfig = (config: AppConfig) => {
  const { appError, cachedProvider, locale, showBanner } = config;
  const sessionConfig: SessionConfig = { appError, showBanner };
  const persistentConfig: PersistentConfig = { cachedProvider, locale };

  sessionStorage.setItem(storageKey, JSON.stringify(sessionConfig));
  localStorage.setItem(storageKey, JSON.stringify(persistentConfig));
};

export const AppConfigContext = createContext<AppConfigContextType>({
  ...initialConfig,
  updateAppConfig: () => null,
});

export const AppConfigProvider: React.FC = ({ children }) => {
  const storedConfig = getStoredConfig();
  const [config, setConfig] = useState<AppConfig>(storedConfig);

  const updateAppConfig = (updatedProps: Partial<AppConfig>) => {
    const mergedConfig = { ...getStoredConfig(), ...updatedProps };

    const changeCount = (Object.keys(config) as (keyof AppConfig)[]).reduce(
      (acc: number, key: keyof AppConfig) =>
        config[key] !== mergedConfig[key] ? acc + 1 : acc + 0,
      0,
    );

    if (changeCount > 0) {
      setConfig(mergedConfig);
      storeConfig(mergedConfig);
    }
  };

  return (
    <AppConfigContext.Provider value={{ ...config, updateAppConfig }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfigContext);
