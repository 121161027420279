import { useState, useCallback } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

import { SpriteIcon } from '@/shared/components/SpriteIcon';

interface Props {
  className?: string;
  content: string;
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 0.4rem;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const CopyButton = ({ content, className }: Props) => {
  const [wasCopied, setWasCopied] = useState(false);

  const handleCopy = useCallback(() => {
    copy(content);
    setWasCopied(true);
  }, [content]);

  return (
    <>
      <StyledButton
        className={className}
        onClick={handleCopy}
        onMouseLeave={() => setWasCopied(false)}
        data-for="copy-button"
        data-tip
      >
        <SpriteIcon icon="copy" size={2.4} color="none" />
      </StyledButton>
      <ReactTooltip id="copy-button" effect="solid" place="bottom">
        {wasCopied ? 'Copied!' : 'Copy'}
      </ReactTooltip>
    </>
  );
};

export { CopyButton };
