import React from 'react';
import styled from 'styled-components';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

import { localizeNumber } from '@/shared/utils/format';
import { NftVaultItem } from '@/shared/types';
import { Button } from '@/shared/components/Button';
import { Skeleton } from '@/shared/components/Skeleton';
import { Spinner } from '@/shared/components/Spinner';
import { SpriteIcon } from '@/shared/components/SpriteIcon';
import { Typography } from '@/shared/components/Typography';
import { ExternalAddress } from '@/shared/components/ExternalAddress';
import { NFT_VAULTS_NETWORK } from '@/shared/utils/env';

const Container = styled.li`
  display: flex;
  align-items: flex-start;
`;

const PairInfo = styled.div`
  flex-basis: 200px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  row-gap: ${({ isLoading }: { isLoading: boolean }) => (isLoading ? '0.8rem' : '0.4rem')};
  align-items: flex-start;
`;

const ValueInfo = styled.div`
  flex-basis: 170px;
  flex-shrink: 0;
  flex-grow: 1;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: ${({ isLoading }: { isLoading: boolean }) => (isLoading ? '0.8rem' : '0.4rem')};
`;

const MinusIcon = styled(SpriteIcon)`
  color: currentColor;
`;

interface Props extends NftVaultItem {
  className?: string;
  onWithdraw: (id: BigNumber) => void;
  isLoading?: boolean;
  isReadOnly: boolean;
}

const NftListItem = ({
  className,
  pair,
  collateral,
  id,
  onWithdraw,
  isLoading,
  status,
  isReadOnly,
}: Props) => {
  const handleWithdrawClick = React.useCallback(() => {
    onWithdraw?.(id);
  }, [id, onWithdraw]);

  const value = pair
    .map(
      ({ value, symbol, decimals }) =>
        `${localizeNumber(Number(formatUnits(value, decimals)), '0', 10)} ${symbol}`,
    )
    .join(' + ');

  const content = (() => {
    if (isLoading) {
      return (
        <>
          <PairInfo isLoading>
            <Skeleton w={67} h={16} />
            <Skeleton w={88} h={10} />
          </PairInfo>
          <ValueInfo isLoading>
            <Skeleton w={65} h={16} />
            <Skeleton w={112} h={10} />
          </ValueInfo>
          <Skeleton w={128} h={20} />
        </>
      );
    }

    const lastElement = (() => {
      if (status === 'depositing' || status === 'withdrawing') {
        const text = status === 'depositing' ? 'Depositing' : 'Withdrawing';
        return (
          <Button variant="primary-outline" size="sm" disabled>
            <Spinner size="1.6rem" />
            <Typography textSize="md" color="primary">
              {text}...
            </Typography>
          </Button>
        );
      }

      return (
        <Button
          variant="primary-outline"
          size="sm"
          aria-label="Add NFT"
          onClick={handleWithdrawClick}
          disabled={isReadOnly}
        >
          <MinusIcon color="none" icon="minus" size={1.6} />
          Withdraw
        </Button>
      );
    })();

    return (
      <>
        <PairInfo>
          <Typography textSize="md" weight={600} color="primary">
            {pair.map(({ symbol }) => symbol).join('-')}
          </Typography>
          <ExternalAddress
            url={`https://app.uniswap.org/#/pool/${id.toString()}?chain=${NFT_VAULTS_NETWORK}`}
            text={`ID${id.toString()}`}
          />
        </PairInfo>
        <ValueInfo>
          <Typography textSize="md" weight={600} color="primary">
            {localizeNumber(Number(formatUnits(collateral, 18)), '0', 0)} USD
          </Typography>
          <Typography textSize="sm">{value}</Typography>
        </ValueInfo>
        {lastElement}
      </>
    );
  })();

  return <Container className={className}>{content}</Container>;
};

export { NftListItem };
