import styled from 'styled-components';

import { breakpoints } from '@/shared/styles';

type Props = {
  className?: string;
};

const StyledAside = styled.aside`
  display: flex;
  flex: 1 0 352px;
  flex-flow: column nowrap;
  gap: 3.2rem;
  width: 100%;
  order: 2;

  @media (min-width: ${breakpoints.tabletXL}px) {
    max-width: 352px;
    order: 4;
  }
`;

const PageAside: React.FC<Props> = ({ children, className }) => (
  <StyledAside className={className}>{children}</StyledAside>
);

export { PageAside };
