import sprite from '@/shared/assets/sprite.svg';

type Props = {
  className?: string;
  color?: string;
  icon: string;
  size?: number | string;
};

const SpriteIcon: React.VFC<Props> = ({ className = '', color = '', icon, size = 3.2 }) => {
  if (icon === 'bob') {
    return (
      <svg
        className={className}
        viewBox="0 0 200 200"
        height={typeof size === 'number' ? `${size}rem` : size}
        width={typeof size === 'number' ? `${size}rem` : size}
        fill={color}
      >
        <circle cx="100" cy="100" r="100" fill="url(#A)" />
        <path
          fillRule="evenodd"
          d="M93.07 39.597h13.859 1.482v.032c18.038.776 32.424 15.645 32.424 33.874a33.77 33.77 0 0 1-8.236 22.151c10.25 6.05 17.127 17.208 17.127 29.971 0 19.178-15.524 34.731-34.691 34.778h0-51.948v-.349h0V57.03 39.597H93.07zm-.523 22.271c0-1.953 1.311-3.6 3.101-4.109a4.23 4.23 0 0 1 4.301 1.031l11.51 11.51a4.24 4.24 0 0 1 .28.257 4.23 4.23 0 0 1 0 5.98L100.019 88.26a4.22 4.22 0 0 1-3.095 1.237l-.106.001a4.27 4.27 0 0 1-4.271-4.271V61.869zm20.396 75.392c0 1.953-1.312 3.6-3.102 4.109-1.464.476-3.137.132-4.3-1.032l-11.509-11.508c-.097-.081-.191-.167-.282-.258a4.23 4.23 0 0 1 0-5.981l11.721-11.722a4.22 4.22 0 0 1 3.094-1.237c.022 0 .043-.001.065-.001h.042c2.358 0 4.271 1.912 4.271 4.271v23.359z"
          fill="#fff"
        />
        <defs>
          <linearGradient
            id="A"
            x1=".329"
            y1="22.824"
            x2="245.23"
            y2="141.405"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset=".054" stopColor="#6d5cff" />
            <stop offset=".559" stopColor="#e86eff" />
            <stop offset=".924" stopColor="#ffd66e" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  if (icon === 'matic') {
    return (
      <svg
        className={className}
        viewBox="0 0 500 500"
        height={typeof size === 'number' ? `${size}rem` : size}
        width={typeof size === 'number' ? `${size}rem` : size}
        fill={color}
      >
        <defs>
          <clipPath id="clippath">
            <circle fill="none" cx="250" cy="250" r="244.91" />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            x1="-116.09"
            y1="25.97"
            x2="437.45"
            y2="364.71"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#a229c5" />
            <stop offset="1" stopColor="#7b3fe4" />
          </linearGradient>
        </defs>
        <g clipPath="url(#clippath)">
          <rect fill="url(#linear-gradient)" x="-18.1" y="-18.1" width="536.2" height="536.2" />
        </g>
        <path
          fill="#fff"
          d="m320.83,302.85l69.29-40.01c3.67-2.12,5.94-6.06,5.94-10.3v-80.01c0-4.23-2.28-8.18-5.94-10.3l-69.29-40.01c-3.67-2.12-8.22-2.11-11.89,0l-69.29,40.01c-3.67,2.12-5.94,6.07-5.94,10.3v142.99l-48.59,28.05-48.59-28.05v-56.11l48.59-28.05,32.05,18.5v-37.64l-26.11-15.07c-1.8-1.04-3.86-1.59-5.95-1.59s-4.15.55-5.94,1.59l-69.29,40.01c-3.67,2.12-5.94,6.06-5.94,10.3v80.01c0,4.23,2.28,8.18,5.94,10.3l69.29,40.01c3.66,2.11,8.22,2.11,11.89,0l69.29-40c3.67-2.12,5.94-6.07,5.94-10.3v-142.99l.88-.5,47.71-27.55,48.59,28.05v56.11l-48.59,28.05-32-18.48v37.64l26.06,15.05c3.67,2.11,8.22,2.11,11.89,0Z"
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      href={sprite}
      viewBox="0 0 32 32"
      height={typeof size === 'number' ? `${size}rem` : size}
      width={typeof size === 'number' ? `${size}rem` : size}
      fill={color}
    >
      <use xlinkHref={`${sprite}#${icon.toLowerCase()}`} />
    </svg>
  );
};

export { SpriteIcon };
