import React from 'react';
import styled from 'styled-components';
import { BigNumber, constants } from 'ethers';

import { HealthFactorGraph } from './HealthFactorGraph';

import { Modal } from '@/services/modal';
import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';
import { IconButton } from '@/shared/components/IconButton';
import { getHealthFactorNumber } from '@/shared/utils/nft';

const Container = styled(Modal)`
  padding: 2rem 2.4rem 2.4rem;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 3.6rem;
`;

const Closer = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue2};

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colors.navyBlue1};
  }
`;

const Group = styled.div`
  padding: 2.4rem;
  border-radius: 1.6rem;
  border: 1px solid ${({ theme }: { theme: Theme }) => theme.colors.naviBlue4};
`;

interface Props {
  isOpen: boolean;
  borrowLimit?: BigNumber;
  liquidationLimit?: BigNumber;
  onClose: () => void;
}

const DEFAULT_MAX_BORROW_HEALTH_FACTOR = 130;

const HealthFactorModal: React.FC<Props> = ({ isOpen, borrowLimit, liquidationLimit, onClose }) => {
  const { theme } = useTheme();
  let maxBorrowHealthFactor = DEFAULT_MAX_BORROW_HEALTH_FACTOR;

  if (borrowLimit && !borrowLimit.isZero() && liquidationLimit && !liquidationLimit.isZero()) {
    const healthFactorLimit = liquidationLimit.mul(constants.WeiPerEther).div(borrowLimit);
    maxBorrowHealthFactor = Math.round(getHealthFactorNumber(healthFactorLimit));
  }

  return (
    <Container isOpen={isOpen} theme={theme} onExited={onClose}>
      <Header>
        <Typography variant="h1" textSize="lg">
          Vault Health Factor
        </Typography>
        <Closer
          theme={theme}
          icon="x-cross"
          size={3.6}
          iconSize={2.4}
          aria-label="Close"
          title="Close"
          color="none"
          onClick={onClose}
        />
      </Header>
      <Typography variant="body1">
        Vault health measures the amount of collateral you hold in LP positions relative to the
        amount of BOB you have minted. To increase health and avoid a liquidation, you can repay
        minted BOB, add liquidity to existing positions, or add new positions to a vault.
      </Typography>
      <Typography variant="body1">
        The ∞ sign means that your vault is 100% safe. If the price of your LP NFT decreases, or you
        mint BOB, the sign will change to a percentage value
      </Typography>
      <div>
        <Typography variant="h2" textSize="lg" mb={1.6}>
          Always Remember
        </Typography>
        <Typography variant="body1">
          There are 2 important situations you need to be aware of with your vault.
        </Typography>
      </div>
      <Group theme={theme}>
        <Typography variant="h3" textSize="md" mb={0.8}>
          Maximum BOB minted from collateral
        </Typography>
        <Typography variant="body1" mb={1.6}>
          {`The health factor can continue to fall below ${maxBorrowHealthFactor}% if the LP collateral value decreases.`}
        </Typography>
        <HealthFactorGraph
          stages={[
            { position: 5, value: '100%' },
            { position: 25, value: `${maxBorrowHealthFactor}%` },
          ]}
        />
      </Group>
      <Group theme={theme}>
        <Typography variant="h3" textSize="md" mb={0.8}>
          Your vault is approaching liquidation
        </Typography>
        <Typography variant="body1" mb={1.6}>
          If the health factor goes below 100%, your vault is instantly liquidated.
        </Typography>
        <HealthFactorGraph
          stages={[
            { position: 5, value: '100%' },
            { position: 15, value: '110%' },
          ]}
        />
      </Group>
    </Container>
  );
};

export { HealthFactorModal };
