import Web3Modal from 'web3modal';
import FortmaticProvider from 'fortmatic';
import PortisProvider from '@portis/web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import WalletLinkProvider from 'walletlink';

const providerOptions = {
  fortmatic: {
    package: FortmaticProvider,
    options: {
      key: process.env.REACT_APP_FORTMATIC_KEY,
      network: {
        rpcUrl: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
        chainId: 5,
      },
    },
  },
  portis: {
    package: PortisProvider,
    options: {
      id: process.env.REACT_APP_PORTIS_ID,
      network: 'goerli',
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_ID,
      rpc: {
        137: 'https://polygon-rpc.com',
        1: 'https://rpc.ankr.com/eth',
        // 5: 'https://goerli.infura.io/v3',
      },
    },
  },
  walletlink: {
    package: WalletLinkProvider,
    options: {
      appName: 'BOB | CDP',
      infuraId: process.env.REACT_APP_INFURA_ID,
      chainId: 5,
    },
  },
};

const w3m = new Web3Modal({
  theme: 'dark',
  cacheProvider: true,
  providerOptions,
});

const connect = async (wallet: string) => {
  const providerName = wallet === 'metamask' ? 'injected' : wallet;
  const provider = await w3m.connectTo(providerName);

  if (providerName === 'injected') {
    const metamaskProvider = window.ethereum.providers?.find(
      ({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask,
    );

    if (metamaskProvider) {
      window.ethereum.setSelectedProvider(metamaskProvider);
      return metamaskProvider;
    }
  }

  return provider;
};

const reconnect = async (providerName: string) => {
  const provider = await connect(providerName);
  return provider;
};

const disconnect = async () => {
  w3m.clearCachedProvider();
};

const getCachedProvider = () => w3m.cachedProvider;

export { connect, disconnect, reconnect, getCachedProvider };
