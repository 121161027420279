import React from 'react';
import styled from 'styled-components';

import { useTheme } from '@/services/theme';

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  display: none;
`;

export interface Props {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  onChange?: (flag: boolean, { value }: { value: string }) => void;
  value: string;
}

const CheckboxComponent = ({ className, children, disabled, onChange, value }: Props) => {
  const { theme } = useTheme();

  const [checked, setChecked] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (disabled) return;

    setChecked(flag => !flag);
    onChange?.(checked, { value });
  }, [onChange, disabled, value, checked]);

  const handleMouseEnter = React.useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setHovered(false);
  }, []);

  const borderColor = (() => {
    if (disabled) {
      return checked ? 'transparent' : theme.colors.naviBlue4;
    }

    if (checked) {
      return 'transparent';
    }

    return hovered ? theme.colors.navyBlue3 : theme.colors.naviBlue4;
  })();

  const bgColor = (() => {
    if (disabled) {
      return theme.colors.naviBlue5;
    }

    if (checked) {
      return hovered ? theme.colors.blue1 : theme.colors.blue2;
    }

    return 'transparent';
  })();

  const tickColor = checked ? theme.colors.white : 'none';

  return (
    <Label className={className} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Input type="checkbox" onClick={handleClick} value={value} />

      <svg width="20" height="20" aria-hidden="true" focusable="false">
        <rect
          className="checkbox__bg"
          width="18"
          height="18"
          x="1"
          y="1"
          stroke={borderColor}
          fill={bgColor}
          strokeWidth="2"
          rx="4"
          ry="4"
        />
        <path d="m6 9 3 3 6-6" stroke={tickColor} strokeWidth="2" fill="none" />
      </svg>

      {children}
    </Label>
  );
};

const Checkbox = CheckboxComponent;

export { Checkbox };
