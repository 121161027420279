import styled from 'styled-components';

import { useTheme, Theme } from '@/services/theme';
import { Typography } from '@/shared/components/Typography';
import { Button } from '@/shared/components/Button';
import { ReactComponent as MascotBroken } from '@/shared/assets/mascot-broken.svg';

const Container = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.white};
  border-radius: 2.4rem;
  padding: 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled(Typography)`
  margin-top: 1.6rem;
`;

const GoBackButton = styled(Button)`
  margin-top: 2.4rem;
`;

interface Props {
  className?: string;
  onGoBackClick: () => void;
}

const NftVaultPageError = ({ className, onGoBackClick }: Props) => {
  const { theme } = useTheme();

  return (
    <Container className={className} theme={theme}>
      <MascotBroken width={100} height={80} />
      <Typography variant="h2" mt={1}>
        Something went wrong
      </Typography>
      <Text variant="body1">Vault not found. Change wallet or go to your vaults list.</Text>
      <GoBackButton variant="secondary" onClick={onGoBackClick}>
        Go Back
      </GoBackButton>
    </Container>
  );
};

export { NftVaultPageError };
